@charset "UTF-8";

/***** mixin *****/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.mb-1 {
  margin-bottom: 1px;
}

.mt-1 {
  margin-top: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.pb-1 {
  padding-bottom: 1px;
}

.pt-1 {
  padding-top: 1px;
}

.pl-1 {
  padding-left: 1px;
}

.pr-1 {
  padding-right: 1px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mt-2 {
  margin-top: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.mr-2 {
  margin-right: 2px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pt-2 {
  padding-top: 2px;
}

.pl-2 {
  padding-left: 2px;
}

.pr-2 {
  padding-right: 2px;
}

.mb-3 {
  margin-bottom: 3px;
}

.mt-3 {
  margin-top: 3px;
}

.ml-3 {
  margin-left: 3px;
}

.mr-3 {
  margin-right: 3px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pt-3 {
  padding-top: 3px;
}

.pl-3 {
  padding-left: 3px;
}

.pr-3 {
  padding-right: 3px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mt-4 {
  margin-top: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pt-4 {
  padding-top: 4px;
}

.pl-4 {
  padding-left: 4px;
}

.pr-4 {
  padding-right: 4px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mt-6 {
  margin-top: 6px;
}

.ml-6 {
  margin-left: 6px;
}

.mr-6 {
  margin-right: 6px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pt-6 {
  padding-top: 6px;
}

.pl-6 {
  padding-left: 6px;
}

.pr-6 {
  padding-right: 6px;
}

.mb-7 {
  margin-bottom: 7px;
}

.mt-7 {
  margin-top: 7px;
}

.ml-7 {
  margin-left: 7px;
}

.mr-7 {
  margin-right: 7px;
}

.pb-7 {
  padding-bottom: 7px;
}

.pt-7 {
  padding-top: 7px;
}

.pl-7 {
  padding-left: 7px;
}

.pr-7 {
  padding-right: 7px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.mb-9 {
  margin-bottom: 9px;
}

.mt-9 {
  margin-top: 9px;
}

.ml-9 {
  margin-left: 9px;
}

.mr-9 {
  margin-right: 9px;
}

.pb-9 {
  padding-bottom: 9px;
}

.pt-9 {
  padding-top: 9px;
}

.pl-9 {
  padding-left: 9px;
}

.pr-9 {
  padding-right: 9px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.mb-11 {
  margin-bottom: 11px;
}

.mt-11 {
  margin-top: 11px;
}

.ml-11 {
  margin-left: 11px;
}

.mr-11 {
  margin-right: 11px;
}

.pb-11 {
  padding-bottom: 11px;
}

.pt-11 {
  padding-top: 11px;
}

.pl-11 {
  padding-left: 11px;
}

.pr-11 {
  padding-right: 11px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.mb-13 {
  margin-bottom: 13px;
}

.mt-13 {
  margin-top: 13px;
}

.ml-13 {
  margin-left: 13px;
}

.mr-13 {
  margin-right: 13px;
}

.pb-13 {
  padding-bottom: 13px;
}

.pt-13 {
  padding-top: 13px;
}

.pl-13 {
  padding-left: 13px;
}

.pr-13 {
  padding-right: 13px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mt-14 {
  margin-top: 14px;
}

.ml-14 {
  margin-left: 14px;
}

.mr-14 {
  margin-right: 14px;
}

.pb-14 {
  padding-bottom: 14px;
}

.pt-14 {
  padding-top: 14px;
}

.pl-14 {
  padding-left: 14px;
}

.pr-14 {
  padding-right: 14px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.mb-17 {
  margin-bottom: 17px;
}

.mt-17 {
  margin-top: 17px;
}

.ml-17 {
  margin-left: 17px;
}

.mr-17 {
  margin-right: 17px;
}

.pb-17 {
  padding-bottom: 17px;
}

.pt-17 {
  padding-top: 17px;
}

.pl-17 {
  padding-left: 17px;
}

.pr-17 {
  padding-right: 17px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mt-18 {
  margin-top: 18px;
}

.ml-18 {
  margin-left: 18px;
}

.mr-18 {
  margin-right: 18px;
}

.pb-18 {
  padding-bottom: 18px;
}

.pt-18 {
  padding-top: 18px;
}

.pl-18 {
  padding-left: 18px;
}

.pr-18 {
  padding-right: 18px;
}

.mb-19 {
  margin-bottom: 19px;
}

.mt-19 {
  margin-top: 19px;
}

.ml-19 {
  margin-left: 19px;
}

.mr-19 {
  margin-right: 19px;
}

.pb-19 {
  padding-bottom: 19px;
}

.pt-19 {
  padding-top: 19px;
}

.pl-19 {
  padding-left: 19px;
}

.pr-19 {
  padding-right: 19px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.mb-21 {
  margin-bottom: 21px;
}

.mt-21 {
  margin-top: 21px;
}

.ml-21 {
  margin-left: 21px;
}

.mr-21 {
  margin-right: 21px;
}

.pb-21 {
  padding-bottom: 21px;
}

.pt-21 {
  padding-top: 21px;
}

.pl-21 {
  padding-left: 21px;
}

.pr-21 {
  padding-right: 21px;
}

.mb-22 {
  margin-bottom: 22px;
}

.mt-22 {
  margin-top: 22px;
}

.ml-22 {
  margin-left: 22px;
}

.mr-22 {
  margin-right: 22px;
}

.pb-22 {
  padding-bottom: 22px;
}

.pt-22 {
  padding-top: 22px;
}

.pl-22 {
  padding-left: 22px;
}

.pr-22 {
  padding-right: 22px;
}

.mb-23 {
  margin-bottom: 23px;
}

.mt-23 {
  margin-top: 23px;
}

.ml-23 {
  margin-left: 23px;
}

.mr-23 {
  margin-right: 23px;
}

.pb-23 {
  padding-bottom: 23px;
}

.pt-23 {
  padding-top: 23px;
}

.pl-23 {
  padding-left: 23px;
}

.pr-23 {
  padding-right: 23px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.mb-26 {
  margin-bottom: 26px;
}

.mt-26 {
  margin-top: 26px;
}

.ml-26 {
  margin-left: 26px;
}

.mr-26 {
  margin-right: 26px;
}

.pb-26 {
  padding-bottom: 26px;
}

.pt-26 {
  padding-top: 26px;
}

.pl-26 {
  padding-left: 26px;
}

.pr-26 {
  padding-right: 26px;
}

.mb-27 {
  margin-bottom: 27px;
}

.mt-27 {
  margin-top: 27px;
}

.ml-27 {
  margin-left: 27px;
}

.mr-27 {
  margin-right: 27px;
}

.pb-27 {
  padding-bottom: 27px;
}

.pt-27 {
  padding-top: 27px;
}

.pl-27 {
  padding-left: 27px;
}

.pr-27 {
  padding-right: 27px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mt-28 {
  margin-top: 28px;
}

.ml-28 {
  margin-left: 28px;
}

.mr-28 {
  margin-right: 28px;
}

.pb-28 {
  padding-bottom: 28px;
}

.pt-28 {
  padding-top: 28px;
}

.pl-28 {
  padding-left: 28px;
}

.pr-28 {
  padding-right: 28px;
}

.mb-29 {
  margin-bottom: 29px;
}

.mt-29 {
  margin-top: 29px;
}

.ml-29 {
  margin-left: 29px;
}

.mr-29 {
  margin-right: 29px;
}

.pb-29 {
  padding-bottom: 29px;
}

.pt-29 {
  padding-top: 29px;
}

.pl-29 {
  padding-left: 29px;
}

.pr-29 {
  padding-right: 29px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.mb-31 {
  margin-bottom: 31px;
}

.mt-31 {
  margin-top: 31px;
}

.ml-31 {
  margin-left: 31px;
}

.mr-31 {
  margin-right: 31px;
}

.pb-31 {
  padding-bottom: 31px;
}

.pt-31 {
  padding-top: 31px;
}

.pl-31 {
  padding-left: 31px;
}

.pr-31 {
  padding-right: 31px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pt-32 {
  padding-top: 32px;
}

.pl-32 {
  padding-left: 32px;
}

.pr-32 {
  padding-right: 32px;
}

.mb-33 {
  margin-bottom: 33px;
}

.mt-33 {
  margin-top: 33px;
}

.ml-33 {
  margin-left: 33px;
}

.mr-33 {
  margin-right: 33px;
}

.pb-33 {
  padding-bottom: 33px;
}

.pt-33 {
  padding-top: 33px;
}

.pl-33 {
  padding-left: 33px;
}

.pr-33 {
  padding-right: 33px;
}

.mb-34 {
  margin-bottom: 34px;
}

.mt-34 {
  margin-top: 34px;
}

.ml-34 {
  margin-left: 34px;
}

.mr-34 {
  margin-right: 34px;
}

.pb-34 {
  padding-bottom: 34px;
}

.pt-34 {
  padding-top: 34px;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mt-36 {
  margin-top: 36px;
}

.ml-36 {
  margin-left: 36px;
}

.mr-36 {
  margin-right: 36px;
}

.pb-36 {
  padding-bottom: 36px;
}

.pt-36 {
  padding-top: 36px;
}

.pl-36 {
  padding-left: 36px;
}

.pr-36 {
  padding-right: 36px;
}

.mb-37 {
  margin-bottom: 37px;
}

.mt-37 {
  margin-top: 37px;
}

.ml-37 {
  margin-left: 37px;
}

.mr-37 {
  margin-right: 37px;
}

.pb-37 {
  padding-bottom: 37px;
}

.pt-37 {
  padding-top: 37px;
}

.pl-37 {
  padding-left: 37px;
}

.pr-37 {
  padding-right: 37px;
}

.mb-38 {
  margin-bottom: 38px;
}

.mt-38 {
  margin-top: 38px;
}

.ml-38 {
  margin-left: 38px;
}

.mr-38 {
  margin-right: 38px;
}

.pb-38 {
  padding-bottom: 38px;
}

.pt-38 {
  padding-top: 38px;
}

.pl-38 {
  padding-left: 38px;
}

.pr-38 {
  padding-right: 38px;
}

.mb-39 {
  margin-bottom: 39px;
}

.mt-39 {
  margin-top: 39px;
}

.ml-39 {
  margin-left: 39px;
}

.mr-39 {
  margin-right: 39px;
}

.pb-39 {
  padding-bottom: 39px;
}

.pt-39 {
  padding-top: 39px;
}

.pl-39 {
  padding-left: 39px;
}

.pr-39 {
  padding-right: 39px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.mb-41 {
  margin-bottom: 41px;
}

.mt-41 {
  margin-top: 41px;
}

.ml-41 {
  margin-left: 41px;
}

.mr-41 {
  margin-right: 41px;
}

.pb-41 {
  padding-bottom: 41px;
}

.pt-41 {
  padding-top: 41px;
}

.pl-41 {
  padding-left: 41px;
}

.pr-41 {
  padding-right: 41px;
}

.mb-42 {
  margin-bottom: 42px;
}

.mt-42 {
  margin-top: 42px;
}

.ml-42 {
  margin-left: 42px;
}

.mr-42 {
  margin-right: 42px;
}

.pb-42 {
  padding-bottom: 42px;
}

.pt-42 {
  padding-top: 42px;
}

.pl-42 {
  padding-left: 42px;
}

.pr-42 {
  padding-right: 42px;
}

.mb-43 {
  margin-bottom: 43px;
}

.mt-43 {
  margin-top: 43px;
}

.ml-43 {
  margin-left: 43px;
}

.mr-43 {
  margin-right: 43px;
}

.pb-43 {
  padding-bottom: 43px;
}

.pt-43 {
  padding-top: 43px;
}

.pl-43 {
  padding-left: 43px;
}

.pr-43 {
  padding-right: 43px;
}

.mb-44 {
  margin-bottom: 44px;
}

.mt-44 {
  margin-top: 44px;
}

.ml-44 {
  margin-left: 44px;
}

.mr-44 {
  margin-right: 44px;
}

.pb-44 {
  padding-bottom: 44px;
}

.pt-44 {
  padding-top: 44px;
}

.pl-44 {
  padding-left: 44px;
}

.pr-44 {
  padding-right: 44px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.mb-46 {
  margin-bottom: 46px;
}

.mt-46 {
  margin-top: 46px;
}

.ml-46 {
  margin-left: 46px;
}

.mr-46 {
  margin-right: 46px;
}

.pb-46 {
  padding-bottom: 46px;
}

.pt-46 {
  padding-top: 46px;
}

.pl-46 {
  padding-left: 46px;
}

.pr-46 {
  padding-right: 46px;
}

.mb-47 {
  margin-bottom: 47px;
}

.mt-47 {
  margin-top: 47px;
}

.ml-47 {
  margin-left: 47px;
}

.mr-47 {
  margin-right: 47px;
}

.pb-47 {
  padding-bottom: 47px;
}

.pt-47 {
  padding-top: 47px;
}

.pl-47 {
  padding-left: 47px;
}

.pr-47 {
  padding-right: 47px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mt-48 {
  margin-top: 48px;
}

.ml-48 {
  margin-left: 48px;
}

.mr-48 {
  margin-right: 48px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pt-48 {
  padding-top: 48px;
}

.pl-48 {
  padding-left: 48px;
}

.pr-48 {
  padding-right: 48px;
}

.mb-49 {
  margin-bottom: 49px;
}

.mt-49 {
  margin-top: 49px;
}

.ml-49 {
  margin-left: 49px;
}

.mr-49 {
  margin-right: 49px;
}

.pb-49 {
  padding-bottom: 49px;
}

.pt-49 {
  padding-top: 49px;
}

.pl-49 {
  padding-left: 49px;
}

.pr-49 {
  padding-right: 49px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.mb-51 {
  margin-bottom: 51px;
}

.mt-51 {
  margin-top: 51px;
}

.ml-51 {
  margin-left: 51px;
}

.mr-51 {
  margin-right: 51px;
}

.pb-51 {
  padding-bottom: 51px;
}

.pt-51 {
  padding-top: 51px;
}

.pl-51 {
  padding-left: 51px;
}

.pr-51 {
  padding-right: 51px;
}

.mb-52 {
  margin-bottom: 52px;
}

.mt-52 {
  margin-top: 52px;
}

.ml-52 {
  margin-left: 52px;
}

.mr-52 {
  margin-right: 52px;
}

.pb-52 {
  padding-bottom: 52px;
}

.pt-52 {
  padding-top: 52px;
}

.pl-52 {
  padding-left: 52px;
}

.pr-52 {
  padding-right: 52px;
}

.mb-53 {
  margin-bottom: 53px;
}

.mt-53 {
  margin-top: 53px;
}

.ml-53 {
  margin-left: 53px;
}

.mr-53 {
  margin-right: 53px;
}

.pb-53 {
  padding-bottom: 53px;
}

.pt-53 {
  padding-top: 53px;
}

.pl-53 {
  padding-left: 53px;
}

.pr-53 {
  padding-right: 53px;
}

.mb-54 {
  margin-bottom: 54px;
}

.mt-54 {
  margin-top: 54px;
}

.ml-54 {
  margin-left: 54px;
}

.mr-54 {
  margin-right: 54px;
}

.pb-54 {
  padding-bottom: 54px;
}

.pt-54 {
  padding-top: 54px;
}

.pl-54 {
  padding-left: 54px;
}

.pr-54 {
  padding-right: 54px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.mb-56 {
  margin-bottom: 56px;
}

.mt-56 {
  margin-top: 56px;
}

.ml-56 {
  margin-left: 56px;
}

.mr-56 {
  margin-right: 56px;
}

.pb-56 {
  padding-bottom: 56px;
}

.pt-56 {
  padding-top: 56px;
}

.pl-56 {
  padding-left: 56px;
}

.pr-56 {
  padding-right: 56px;
}

.mb-57 {
  margin-bottom: 57px;
}

.mt-57 {
  margin-top: 57px;
}

.ml-57 {
  margin-left: 57px;
}

.mr-57 {
  margin-right: 57px;
}

.pb-57 {
  padding-bottom: 57px;
}

.pt-57 {
  padding-top: 57px;
}

.pl-57 {
  padding-left: 57px;
}

.pr-57 {
  padding-right: 57px;
}

.mb-58 {
  margin-bottom: 58px;
}

.mt-58 {
  margin-top: 58px;
}

.ml-58 {
  margin-left: 58px;
}

.mr-58 {
  margin-right: 58px;
}

.pb-58 {
  padding-bottom: 58px;
}

.pt-58 {
  padding-top: 58px;
}

.pl-58 {
  padding-left: 58px;
}

.pr-58 {
  padding-right: 58px;
}

.mb-59 {
  margin-bottom: 59px;
}

.mt-59 {
  margin-top: 59px;
}

.ml-59 {
  margin-left: 59px;
}

.mr-59 {
  margin-right: 59px;
}

.pb-59 {
  padding-bottom: 59px;
}

.pt-59 {
  padding-top: 59px;
}

.pl-59 {
  padding-left: 59px;
}

.pr-59 {
  padding-right: 59px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.mb-61 {
  margin-bottom: 61px;
}

.mt-61 {
  margin-top: 61px;
}

.ml-61 {
  margin-left: 61px;
}

.mr-61 {
  margin-right: 61px;
}

.pb-61 {
  padding-bottom: 61px;
}

.pt-61 {
  padding-top: 61px;
}

.pl-61 {
  padding-left: 61px;
}

.pr-61 {
  padding-right: 61px;
}

.mb-62 {
  margin-bottom: 62px;
}

.mt-62 {
  margin-top: 62px;
}

.ml-62 {
  margin-left: 62px;
}

.mr-62 {
  margin-right: 62px;
}

.pb-62 {
  padding-bottom: 62px;
}

.pt-62 {
  padding-top: 62px;
}

.pl-62 {
  padding-left: 62px;
}

.pr-62 {
  padding-right: 62px;
}

.mb-63 {
  margin-bottom: 63px;
}

.mt-63 {
  margin-top: 63px;
}

.ml-63 {
  margin-left: 63px;
}

.mr-63 {
  margin-right: 63px;
}

.pb-63 {
  padding-bottom: 63px;
}

.pt-63 {
  padding-top: 63px;
}

.pl-63 {
  padding-left: 63px;
}

.pr-63 {
  padding-right: 63px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mt-64 {
  margin-top: 64px;
}

.ml-64 {
  margin-left: 64px;
}

.mr-64 {
  margin-right: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pt-64 {
  padding-top: 64px;
}

.pl-64 {
  padding-left: 64px;
}

.pr-64 {
  padding-right: 64px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.mb-66 {
  margin-bottom: 66px;
}

.mt-66 {
  margin-top: 66px;
}

.ml-66 {
  margin-left: 66px;
}

.mr-66 {
  margin-right: 66px;
}

.pb-66 {
  padding-bottom: 66px;
}

.pt-66 {
  padding-top: 66px;
}

.pl-66 {
  padding-left: 66px;
}

.pr-66 {
  padding-right: 66px;
}

.mb-67 {
  margin-bottom: 67px;
}

.mt-67 {
  margin-top: 67px;
}

.ml-67 {
  margin-left: 67px;
}

.mr-67 {
  margin-right: 67px;
}

.pb-67 {
  padding-bottom: 67px;
}

.pt-67 {
  padding-top: 67px;
}

.pl-67 {
  padding-left: 67px;
}

.pr-67 {
  padding-right: 67px;
}

.mb-68 {
  margin-bottom: 68px;
}

.mt-68 {
  margin-top: 68px;
}

.ml-68 {
  margin-left: 68px;
}

.mr-68 {
  margin-right: 68px;
}

.pb-68 {
  padding-bottom: 68px;
}

.pt-68 {
  padding-top: 68px;
}

.pl-68 {
  padding-left: 68px;
}

.pr-68 {
  padding-right: 68px;
}

.mb-69 {
  margin-bottom: 69px;
}

.mt-69 {
  margin-top: 69px;
}

.ml-69 {
  margin-left: 69px;
}

.mr-69 {
  margin-right: 69px;
}

.pb-69 {
  padding-bottom: 69px;
}

.pt-69 {
  padding-top: 69px;
}

.pl-69 {
  padding-left: 69px;
}

.pr-69 {
  padding-right: 69px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.mb-71 {
  margin-bottom: 71px;
}

.mt-71 {
  margin-top: 71px;
}

.ml-71 {
  margin-left: 71px;
}

.mr-71 {
  margin-right: 71px;
}

.pb-71 {
  padding-bottom: 71px;
}

.pt-71 {
  padding-top: 71px;
}

.pl-71 {
  padding-left: 71px;
}

.pr-71 {
  padding-right: 71px;
}

.mb-72 {
  margin-bottom: 72px;
}

.mt-72 {
  margin-top: 72px;
}

.ml-72 {
  margin-left: 72px;
}

.mr-72 {
  margin-right: 72px;
}

.pb-72 {
  padding-bottom: 72px;
}

.pt-72 {
  padding-top: 72px;
}

.pl-72 {
  padding-left: 72px;
}

.pr-72 {
  padding-right: 72px;
}

.mb-73 {
  margin-bottom: 73px;
}

.mt-73 {
  margin-top: 73px;
}

.ml-73 {
  margin-left: 73px;
}

.mr-73 {
  margin-right: 73px;
}

.pb-73 {
  padding-bottom: 73px;
}

.pt-73 {
  padding-top: 73px;
}

.pl-73 {
  padding-left: 73px;
}

.pr-73 {
  padding-right: 73px;
}

.mb-74 {
  margin-bottom: 74px;
}

.mt-74 {
  margin-top: 74px;
}

.ml-74 {
  margin-left: 74px;
}

.mr-74 {
  margin-right: 74px;
}

.pb-74 {
  padding-bottom: 74px;
}

.pt-74 {
  padding-top: 74px;
}

.pl-74 {
  padding-left: 74px;
}

.pr-74 {
  padding-right: 74px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.mb-76 {
  margin-bottom: 76px;
}

.mt-76 {
  margin-top: 76px;
}

.ml-76 {
  margin-left: 76px;
}

.mr-76 {
  margin-right: 76px;
}

.pb-76 {
  padding-bottom: 76px;
}

.pt-76 {
  padding-top: 76px;
}

.pl-76 {
  padding-left: 76px;
}

.pr-76 {
  padding-right: 76px;
}

.mb-77 {
  margin-bottom: 77px;
}

.mt-77 {
  margin-top: 77px;
}

.ml-77 {
  margin-left: 77px;
}

.mr-77 {
  margin-right: 77px;
}

.pb-77 {
  padding-bottom: 77px;
}

.pt-77 {
  padding-top: 77px;
}

.pl-77 {
  padding-left: 77px;
}

.pr-77 {
  padding-right: 77px;
}

.mb-78 {
  margin-bottom: 78px;
}

.mt-78 {
  margin-top: 78px;
}

.ml-78 {
  margin-left: 78px;
}

.mr-78 {
  margin-right: 78px;
}

.pb-78 {
  padding-bottom: 78px;
}

.pt-78 {
  padding-top: 78px;
}

.pl-78 {
  padding-left: 78px;
}

.pr-78 {
  padding-right: 78px;
}

.mb-79 {
  margin-bottom: 79px;
}

.mt-79 {
  margin-top: 79px;
}

.ml-79 {
  margin-left: 79px;
}

.mr-79 {
  margin-right: 79px;
}

.pb-79 {
  padding-bottom: 79px;
}

.pt-79 {
  padding-top: 79px;
}

.pl-79 {
  padding-left: 79px;
}

.pr-79 {
  padding-right: 79px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.mb-81 {
  margin-bottom: 81px;
}

.mt-81 {
  margin-top: 81px;
}

.ml-81 {
  margin-left: 81px;
}

.mr-81 {
  margin-right: 81px;
}

.pb-81 {
  padding-bottom: 81px;
}

.pt-81 {
  padding-top: 81px;
}

.pl-81 {
  padding-left: 81px;
}

.pr-81 {
  padding-right: 81px;
}

.mb-82 {
  margin-bottom: 82px;
}

.mt-82 {
  margin-top: 82px;
}

.ml-82 {
  margin-left: 82px;
}

.mr-82 {
  margin-right: 82px;
}

.pb-82 {
  padding-bottom: 82px;
}

.pt-82 {
  padding-top: 82px;
}

.pl-82 {
  padding-left: 82px;
}

.pr-82 {
  padding-right: 82px;
}

.mb-83 {
  margin-bottom: 83px;
}

.mt-83 {
  margin-top: 83px;
}

.ml-83 {
  margin-left: 83px;
}

.mr-83 {
  margin-right: 83px;
}

.pb-83 {
  padding-bottom: 83px;
}

.pt-83 {
  padding-top: 83px;
}

.pl-83 {
  padding-left: 83px;
}

.pr-83 {
  padding-right: 83px;
}

.mb-84 {
  margin-bottom: 84px;
}

.mt-84 {
  margin-top: 84px;
}

.ml-84 {
  margin-left: 84px;
}

.mr-84 {
  margin-right: 84px;
}

.pb-84 {
  padding-bottom: 84px;
}

.pt-84 {
  padding-top: 84px;
}

.pl-84 {
  padding-left: 84px;
}

.pr-84 {
  padding-right: 84px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.mb-86 {
  margin-bottom: 86px;
}

.mt-86 {
  margin-top: 86px;
}

.ml-86 {
  margin-left: 86px;
}

.mr-86 {
  margin-right: 86px;
}

.pb-86 {
  padding-bottom: 86px;
}

.pt-86 {
  padding-top: 86px;
}

.pl-86 {
  padding-left: 86px;
}

.pr-86 {
  padding-right: 86px;
}

.mb-87 {
  margin-bottom: 87px;
}

.mt-87 {
  margin-top: 87px;
}

.ml-87 {
  margin-left: 87px;
}

.mr-87 {
  margin-right: 87px;
}

.pb-87 {
  padding-bottom: 87px;
}

.pt-87 {
  padding-top: 87px;
}

.pl-87 {
  padding-left: 87px;
}

.pr-87 {
  padding-right: 87px;
}

.mb-88 {
  margin-bottom: 88px;
}

.mt-88 {
  margin-top: 88px;
}

.ml-88 {
  margin-left: 88px;
}

.mr-88 {
  margin-right: 88px;
}

.pb-88 {
  padding-bottom: 88px;
}

.pt-88 {
  padding-top: 88px;
}

.pl-88 {
  padding-left: 88px;
}

.pr-88 {
  padding-right: 88px;
}

.mb-89 {
  margin-bottom: 89px;
}

.mt-89 {
  margin-top: 89px;
}

.ml-89 {
  margin-left: 89px;
}

.mr-89 {
  margin-right: 89px;
}

.pb-89 {
  padding-bottom: 89px;
}

.pt-89 {
  padding-top: 89px;
}

.pl-89 {
  padding-left: 89px;
}

.pr-89 {
  padding-right: 89px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.mb-91 {
  margin-bottom: 91px;
}

.mt-91 {
  margin-top: 91px;
}

.ml-91 {
  margin-left: 91px;
}

.mr-91 {
  margin-right: 91px;
}

.pb-91 {
  padding-bottom: 91px;
}

.pt-91 {
  padding-top: 91px;
}

.pl-91 {
  padding-left: 91px;
}

.pr-91 {
  padding-right: 91px;
}

.mb-92 {
  margin-bottom: 92px;
}

.mt-92 {
  margin-top: 92px;
}

.ml-92 {
  margin-left: 92px;
}

.mr-92 {
  margin-right: 92px;
}

.pb-92 {
  padding-bottom: 92px;
}

.pt-92 {
  padding-top: 92px;
}

.pl-92 {
  padding-left: 92px;
}

.pr-92 {
  padding-right: 92px;
}

.mb-93 {
  margin-bottom: 93px;
}

.mt-93 {
  margin-top: 93px;
}

.ml-93 {
  margin-left: 93px;
}

.mr-93 {
  margin-right: 93px;
}

.pb-93 {
  padding-bottom: 93px;
}

.pt-93 {
  padding-top: 93px;
}

.pl-93 {
  padding-left: 93px;
}

.pr-93 {
  padding-right: 93px;
}

.mb-94 {
  margin-bottom: 94px;
}

.mt-94 {
  margin-top: 94px;
}

.ml-94 {
  margin-left: 94px;
}

.mr-94 {
  margin-right: 94px;
}

.pb-94 {
  padding-bottom: 94px;
}

.pt-94 {
  padding-top: 94px;
}

.pl-94 {
  padding-left: 94px;
}

.pr-94 {
  padding-right: 94px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.mb-96 {
  margin-bottom: 96px;
}

.mt-96 {
  margin-top: 96px;
}

.ml-96 {
  margin-left: 96px;
}

.mr-96 {
  margin-right: 96px;
}

.pb-96 {
  padding-bottom: 96px;
}

.pt-96 {
  padding-top: 96px;
}

.pl-96 {
  padding-left: 96px;
}

.pr-96 {
  padding-right: 96px;
}

.mb-97 {
  margin-bottom: 97px;
}

.mt-97 {
  margin-top: 97px;
}

.ml-97 {
  margin-left: 97px;
}

.mr-97 {
  margin-right: 97px;
}

.pb-97 {
  padding-bottom: 97px;
}

.pt-97 {
  padding-top: 97px;
}

.pl-97 {
  padding-left: 97px;
}

.pr-97 {
  padding-right: 97px;
}

.mb-98 {
  margin-bottom: 98px;
}

.mt-98 {
  margin-top: 98px;
}

.ml-98 {
  margin-left: 98px;
}

.mr-98 {
  margin-right: 98px;
}

.pb-98 {
  padding-bottom: 98px;
}

.pt-98 {
  padding-top: 98px;
}

.pl-98 {
  padding-left: 98px;
}

.pr-98 {
  padding-right: 98px;
}

.mb-99 {
  margin-bottom: 99px;
}

.mt-99 {
  margin-top: 99px;
}

.ml-99 {
  margin-left: 99px;
}

.mr-99 {
  margin-right: 99px;
}

.pb-99 {
  padding-bottom: 99px;
}

.pt-99 {
  padding-top: 99px;
}

.pl-99 {
  padding-left: 99px;
}

.pr-99 {
  padding-right: 99px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.pr-100 {
  padding-right: 100px;
}

/***** base *****/

body,
html {
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #434343;
  max-width: 100%;
  overflow-x: hidden;
}

.r-treasure-slider {
  overflow: hidden;
}

.container {
  position: relative;
}

.row {
  margin-left: -7px;
  margin-right: -7px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 7px;
  padding-right: 7px;
}

a {
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

svg,
path,
button {
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

#header .header-desktop {
  padding: 15px 0 16px 0;
  display: block;
}

html {
  scroll-behavior: smooth;
}

#footer .main .customMarginTop {
  margin-top: 80px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #header .header-desktop {
    display: none;
  }

  #footer .main .customMR-42 {
    margin-right: 0;
  }

  #footer .main .customRight {
    margin-right: 0;
  }
}

#header .header-mobile {
  display: none;
  background: #fff;
  padding: 19px 0 14px;
}

#header .header-mobile .js-open-canvas path {
  fill: #000;
}

#home #header .header-mobile,
#offshore #header .header-mobile {
  background: transparent;
}

.header-mobile {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}

.sticky .header-mobile {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

#home .r-big-banner .container-fluid img.customHeight {
  height: 102vh;
}

#home #header .header-mobile .js-open-canvas path,
#offshore #header .header-mobile .js-open-canvas path {
  fill: #fff;
}

#home #header.sticky .header-mobile .js-open-canvas path,
#offshore #header.sticky .header-mobile .js-open-canvas path {
  fill: #000;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #header .header-mobile {
    display: block;
  }
}

#header .header-mobile .canvas-menu {
  padding: 39px 16px;
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 100%;
  background-color: #fff;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
  z-index: 11;
}

#header .header-mobile .canvas-menu.open {
  left: 0;
}

#header .header-mobile .canvas-menu .top-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#header .header-mobile .canvas-menu .top-menu .js-close-canvas {
  padding: 0 14px;
}

#header .header-mobile .canvas-menu .megamenu {
  text-align: center;
}

#header .header-mobile .canvas-menu .megamenu ul li {
  margin-bottom: 15px;
}

#header .header-mobile .canvas-menu .megamenu ul li.active a {
  color: #0063aa;
}

#header .header-mobile .canvas-menu .megamenu ul li a {
  font-size: 16px;
  color: #a6a6a6;
  font-weight: 500;
}

#header .header-mobile .canvas-menu .language {
  text-align: center;
}

#header .header-mobile .canvas-menu .language .dropdown-menu {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#header .col-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#header .col-right .megamenu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#header .col-right .megamenu ul li {
  padding: 0 12px;
}

#header .col-right .megamenu ul li.active a::after {
  opacity: 1;
  visibility: visible;
}

#header .col-right .megamenu ul li a {
  color: #121212;
  font-weight: 500;
  position: relative;
  font-size: 16px;
}

#header .col-right .megamenu ul li a:hover::after {
  opacity: 1;
  visibility: visible;
}

#header .col-right .megamenu ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -27px;
  width: 100%;
  height: 4px;
  background-color: #0063aa;
  border-radius: 5px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

#header .col-right .language {
  margin-left: 14px;
  margin-right: 7px;
}

#header .col-right .language button {
  border: none;
  background: transparent;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

#header .col-right .language button span {
  margin-right: 4px;
  margin-left: 4px;
  font-weight: 500;
  font-size: 16px;
}

#header .col-right .language .dropdown-menu {
  background: #ffffff;
  -webkit-box-shadow: 0px 12px 14px rgba(189, 189, 189, 0.25);
  box-shadow: 0px 12px 14px rgba(189, 189, 189, 0.25);
  border-radius: 5px;
  border: none;
  width: 106px;
  min-width: 106px;
  left: -27px;
  top: calc(100% + 7px);
  padding: 4px 0;
}

#header .col-right .language .dropdown-menu a {
  font-size: 14px;
  font-weight: 500;
  color: #121212;
  padding: 0 15px;
  position: relative;
  cursor: pointer;
}

#header .col-right .language .dropdown-menu a:active {
  background-color: transparent;
}

#header .col-right .language .dropdown-menu a+a::before {
  content: '';
  width: calc(100% - 30px);
  top: -1px;
  left: 15px;
  right: 15px;
  position: absolute;
  height: 1px;
  background-color: #e2e2e2;
  z-index: 1;
}

#header .col-right .language .dropdown-menu a img {
  margin-right: 5px;
}

#header .language {
  margin-left: 14px;
  margin-right: 7px;
}

#header .language button {
  border: none;
  background: transparent;
  padding: 0;
}

#header .language button span {
  margin-right: 4px;
  margin-left: 4px;
}

#header .language .dropdown-menu {
  background: #ffffff;
  -webkit-box-shadow: 0px 12px 14px rgba(189, 189, 189, 0.25);
  box-shadow: 0px 12px 14px rgba(189, 189, 189, 0.25);
  border-radius: 5px;
  border: none;
  width: 106px;
  min-width: 106px;
  left: -27px;
  top: calc(100% + 7px);
  padding: 4px 0;
}

#header .language .dropdown-menu a {
  font-size: 12px;
  font-weight: 500;
  color: #121212;
  padding: 0 15px;
  position: relative;
}

#header .language .dropdown-menu a:active {
  background-color: transparent;
}

#header .language .dropdown-menu a+a::before {
  content: '';
  width: calc(100% - 30px);
  top: -1px;
  left: 15px;
  right: 15px;
  position: absolute;
  height: 1px;
  background-color: #e2e2e2;
  z-index: 1;
}

#header .language .dropdown-menu a img {
  margin-right: 5px;
}

.r-contact {
  margin-bottom: -123px;
}

.r-contact .container {
  position: static;
}

.r-contact .title-left {
  max-width: 682px;
  margin-bottom: 0 !important;
}

.r-contact .title-left h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  width: 174px;
  height: 56px;
  text-transform: uppercase;
  margin-bottom: 28px !important;
  position: relative;
}

@media (max-width: 1199px) {
  .r-contact .title-left h3 {
    height: unset;
  }
}

.r-contact .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

.r-contact .title-left .desc {
  font-size: 20px !important;
  font-weight: 600;
  color: #676767 !important;
  line-height: 32px;
}

.r-contact .contact-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 51px 56px 29px;
  background: url('/assets/images/contactbox-bg.png') no-repeat center;
  background-size: cover;
  -webkit-box-shadow: 0px 4px 25px rgba(189, 189, 189, 0.25);
  box-shadow: 0px 4px 25px rgba(189, 189, 189, 0.25);
  border-radius: 25px;
  position: relative;
  z-index: 12;
}

.r-contact .contact-box .btn-contact {
  border-radius: 5px;
  color: #fff;
  background-color: #0063aa;
  padding: 15px 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 262px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
}

.r-contact .contact-box .btn-contact:hover {
  background-color: #06436f;
}

.r-contact .contact-box .btn-contact svg {
  margin-right: 10px;
}

#footer {
  color: #fff;
  background: url('/assets/images/footer-tcom.webp') no-repeat center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

#footer .container-fluid {
  width: 1740px;
  max-width: 100%;
}

#footer .main {
  padding: 219px 0 15px;
}

#footer .main .col-social {
  padding-left: 88px;
}

#footer .main .col-service {
  padding-left: 18px;
  padding-right: 31px;
}

#footer .main .col-service .social {
  margin-top: 65px;
}

#footer .main .col-service .social h3.title {
  margin-bottom: 24px;
}

#footer .main .col-menu {
  padding-left: 24px;
}

#footer .main .policy {
  margin-top: 31px;
  white-space: nowrap;
}

#footer .main .customMR-42 {
  margin-right: 42px;
}

#footer .main .policy a {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 0.01em;
  text-decoration: underline;
}

#footer .main .policy a+a {
  margin-left: 48px;
}

#footer .main .social-link ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#footer .main .social-link ul li {
  margin-right: 16px;
  margin-bottom: 16px;
}

#footer .main .social-link ul li a {
  position: relative;
}

#footer .main .social-link ul li a:hover:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 51, 51, 0.3);
  z-index: 10;
  top: 0;
  left: 0;
  border-radius: 50%;
}

#footer .main .logo {
  display: block;
  margin-bottom: 68px;
  position: relative;
}

#footer .main .logo::after {
  content: '';
  background: #fff;
  position: absolute;
  height: 1px;
  width: 158px;
  bottom: -24px;
  left: -2px;
}

#footer .main h3.title {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.02em;
  margin-bottom: 43px;
  display: flex;
  align-items: center;
}

#footer .main h3.title svg {
  margin-left: 8px;
}

#footer .main .customRowFooter2 {
  margin-top: 21px;
  margin-bottom: 23px;
  position: relative;
  margin-top: 21px;
  margin-bottom: 23px;
}

#footer .main .customRight {
  margin-right: 29px;
}

#footer .main .customMR {
  margin-right: 40px;
}

/* #footer .main .customRowFooter2::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: #fff;
  opacity: 0.6;
  top: 0;
} */

#footer .copyright p::before {
  content: '';
  background: #ffff;
  position: absolute;
  top: 6px;
  width: 100%;
  height: 1px;
  left: 0;
  opacity: 0.6;
}

#footer .main .customContent {
  margin-top: 30px;
}

#footer .main .textLogo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  margin-top: -12px;
}

#footer .main .iconLogo ul {
  display: flex;
  gap: 16px;
}

#footer .main ul.link-list {
  margin-top: -6px;
  min-height: 386px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #footer .main ul.link-list {
    min-height: auto;
  }
}

#footer .main ul.link-list li {
  margin-bottom: 15px;
}

#footer .main ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.68);
  line-height: 25px;
  margin-bottom: 17px;
}

#footer .customTokyo span {
  position: relative;
}

.customTokyo {
  margin-bottom: 13px;
  margin-left: 10px;
}

.mLeft {
  margin-left: 9px;
}

#footer .customTokyo span::after {
  content: '';
  background: #fff;
  height: 1px;
  width: 30px;
  position: absolute;
  bottom: 62px;
  left: 53px;
  border-radius: 1px;
}

#footer .main ul li a {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.68);
  line-height: 25px;
  white-space: nowrap;
}

/* #footer .main .customMarginTop {
    margin-top: 38px;
} */

#footer .copyright p::before {
  content: '';
  background: #ffff;
  position: absolute;
  top: 6px;
  width: 100%;
  height: 1px;
  left: 0;
  opacity: 0.6;
}

/* #footer .main .customMT86 {
    margin-top: 55px;
} */

#footer .main .customMarginTop .content-margin {
  margin-left: 6px;
}

#footer .main .customPaddingUl {
  padding-top: 58px;
}

#footer .main ul li a:hover {
  color: #fff;
}

#footer .main .customMarginBottom {
  margin-bottom: 18px;
  position: relative;
}

#footer .main .customMarginBottom svg {
  margin-right: 11px;
  margin-left: 8px;
}

#footer .customRowFooter2 .customContent ul li {
  white-space: nowrap;
}

/* dung_dev start */

#footer .main .customMarginBottom span::after {
  content: '';
  background: #fff;
  height: 1px;
  width: 30px;
  position: absolute;
  bottom: 0px;
  left: 0;
  top: 22px;
  border-radius: 1px;
}

/* dung_dev  end*/

#footer .main .TcomJapanPC {
  margin-top: 72px !important;
}

#footer .main .customTokyo {
  position: relative;
  padding-bottom: 9px;
  margin-left: 5px;
}

/* dung_dev start */
#footer .main .customTokyo span::after {
  content: '';
  background: #fff;
  height: 1px;
  width: 30px;
  position: absolute;
  /* bottom: 8px; */
  left: 0;
  top: 19px;
  border-radius: 1px;
}

/* dung_dev end */

#footer .main .customContentTCJP {
  margin-top: 4px;
  margin-left: 5px;
}

#footer .main ul li .icon {
  margin-right: 11px;
}

#footer .copyright {
  text-align: center;
}

.mainMenuFooter .title {
  margin-left: 13px;
}

.menuFooterMB .content ul {
  margin-left: 10px;
  margin-top: 16px;
}

#footer .copyright p {
  font-size: 16px;
  padding: 24px;
  margin-bottom: 0 !important;
  position: relative;
}

#footer .content .rules a {
  font-size: 16px;
}

#footer .content .rules a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  text-decoration: underline;
}

.customRowFooter2-MB .rules {
  padding: 0 3px;
  margin-top: 23px;
}

#footer .main .copyright {
  margin-top: -15px;
}

.col-md-12.customImgMb {
  text-align: center;
}

@media (max-width: 1440px) {
  #footer .logo img {
    max-width: 100%;
  }

  #footer .main .col-social,
  #footer .main .col-service,
  #footer .main .col-menu {
    padding-left: 7px;
  }

  #footer .main .logo {
    margin-bottom: 66px;
    height: 80px;
  }

  #footer .main .block-address+.block-address {
    margin-top: 20px;
    margin-bottom: 35px;
  }
}

@media (min-width: 1200px) {
  #footer .main .block-address {
    max-width: 365px;
  }

  #footer .main .block-address+.block-address {
    margin-top: 31px;
  }

  #footer .main .block-address h3.title {
    margin-bottom: 29px;
    margin-left: 6px;
  }

  #footer .main .block-address ul li {
    margin-bottom: 24px;
  }
}

@media (max-width: 1199px) {
  #footer .main .logo {
    height: auto;
  }

  #footer .main .customRowFooter2 {
    margin-top: 19px;
  }
}

@media (max-width: 991px) {
  #footer .logo img {
    width: 119px;
  }
}

@media (max-width: 767px) {
  #header .header-mobile .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  #footer .main .customContent {
    margin-top: 0;
  }

  .r-contact {
    margin-bottom: -67px;
  }

  .r-contact .title-left .desc {
    font-size: 14px;
    line-height: 24px;
  }

  .r-contact .title-left h3::after {
    bottom: -4px !important;
    width: 14px !important;
    height: 1px !important;
  }

  .r-contact .title-left .desc {
    font-size: 14px !important;
    line-height: 24px;
  }

  #footer .main .col-menu {
    padding-left: 18px;
  }

  .r-contact .contact-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 16px 16px;
    border-radius: 7px;
  }

  .r-contact .title-left h3 {
    font-size: 14px !important;
  }

  .r-contact .title-left .desc {
    padding-right: 0 !important;
  }

  .r-contact .title-left h3 {
    margin-bottom: 8px !important;
  }

  .r-contact .contact-box .btn-contact {
    font-size: 12px;
    min-width: auto;
    padding: 7.5px 17px;
  }

  .r-contact .contact-box .btn-contact svg {
    width: 16px;
  }

  #footer .main {
    padding: 86px 0 36px;
  }

  #footer .main h3.title {
    font-size: 14px;
    margin-bottom: 13px;
  }

  #footer .main .social-link ul li {
    margin-right: 11px;
    margin-bottom: 11px;
  }

  #footer .main ul li a svg {
    width: 20px;
  }

  #footer .main .logo {
    margin-bottom: 38px;
  }

  #footer .main h3.title svg {
    margin-left: 5px;
    width: 22px;
  }

  #footer .main ul li {
    font-size: 14px;
    line-height: 24px;
  }

  #footer .main ul li a {
    font-size: 13px;
    line-height: 24px;
  }

  #footer .main ul li .icon svg {
    width: 16px;
  }

  #footer .social-mobile h3.title {
    margin-bottom: 0;
  }

  #footer .logo img {
    width: 119px;
  }

  #footer .container-fluid {
    padding: 0 25px;
  }

  #footer .main ul li {
    margin-bottom: 4px;
  }

  #footer .main .col-social {
    margin-top: 17px;
    margin-bottom: 20px;
  }

  #footer .main ul.link-list li {
    margin-bottom: 3px;
  }

  #footer .main .col-service .title,
  #footer .main .col-menu .title {
    margin-bottom: 20px;
  }

  #footer .main .policy {
    margin-top: 29px;
  }

  #footer .main .policy a {
    font-size: 16px;
    display: block;
  }

  #footer .main .policy a+a {
    margin-left: 0;
    margin-top: 16px;
  }
}

/***** fonts *****/

/***** pages *****/

#home #header .header-desktop {
  position: relative;
  z-index: 2;
  /* background: rgba(0, 0, 0, 0.75); */
}

#home #header.sticky .header-mobile,
#offshore #header.sticky .header-mobile,
#home #header.sticky .header-desktop {
  background: #fff;
}

#offshore #header.sticky .header-desktop .language button span,
#offshore #header.sticky .header-desktop .megamenu ul li a,
#home #header.sticky .header-desktop .language button span,
#home #header.sticky .header-desktop .megamenu ul li a {
  color: #000;
}

#offshore #header.sticky .header-desktop .language button span+svg path,
#home #header.sticky .header-desktop .language button span+svg path {
  fill: #000;

}

#home #header .col-right .megamenu ul li a {
  color: #fff;
}

#home #header .col-right .language button span {
  color: #fff;
}

#home #header .col-right .language button span+svg path {
  fill: #fff;
}

#home .title-left {
  margin-bottom: 58px;
}

#home .title-left h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 35px;
}

#home .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#home .title-left .desc {
  font-size: 18px;
  color: #686868;
}

#home .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#home .title-center h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
}

#home .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#home .title-center .desc {
  font-size: 20px;
  color: #686868;
}

#home .read-more {
  font-size: 20px;
  font-weight: 700;
  color: #0063aa;
  position: absolute;
  top: 101px;
  right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  #home .read-more {
    top: 144px;
  }
}

#home .read-more svg {
  margin-left: 22px;
}

#home .r-big-banner {
  margin-top: -75px;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 1;
}

#home .r-big-banner .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

#home .r-big-banner .container-fluid .slider-desktop .slick-track img {
  height: 100vh;
  object-fit: cover;
}

@media (min-width: 768px) and (max-width: 991px) {
  #home .r-big-banner .container-fluid .slider-desktop .slick-track img {
    height: 50vh;
  }
}

#home .r-big-banner>img {
  width: 100%;
}

#home .r-big-banner .content {
  text-align: left;
  width: 1100px;
  /* max-width: 100%; */
  /*padding-left: 30px;*/
  /*padding-right: 30px;*/
  /*padding-top: 16px;*/
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 200px) and (max-width: 1440px) {
  #home .r-big-banner .content {
    padding-top: 0;
  }
}

#home .r-big-banner .content .title {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 32px;
  letter-spacing: -0.02em;
  font-weight: 500;
  margin-bottom: 23px;
  line-height: 32px;
  display: inline-block;
  border-bottom: 3px solid #ffffff;
  padding-bottom: 15px;
  padding-top: 10px;
}

#home .r-big-banner .content .main-content .desc {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 60px;
  /*letter-spacing: -0.02em;*/
  line-height: 65px;
  font-weight: 700;
  color: #ffffff;
  max-width: 600px;
}

#home .r-big-banner .content .main-content .sortContent {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  /*letter-spacing: -0.02em;*/
  line-height: 28px;
  font-weight: 400;
  color: #ffffff;
  max-width: 500px;
  margin: 10px 0px 15px 0px;
}

#home .r-big-banner .content .main-content .readMore {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  padding: 15px 0px 0px 0px;
  margin-bottom: 0px;
  cursor: pointer;
}

#home .r-big-banner .content .main-content .readMore span {
  position: relative;
  font-weight: 700;

  bottom: 0px;
  left: 10px;
}

#home .r-big-banner .content .paginator-content {
  display: flex;
}

#home .r-big-banner .content .paginator-content ul {
  display: flex;
}

#home .pagi-slick .LiSlick {
  margin: 0px 6px;
  height: 13px;
  width: 13px;
  border: 1px solid white;
  border-radius: 12px;
  padding: 5px 5px;
  background-color: initial;
}

#home .pagi-slick .slick-active button {
  height: 22px;
  width: 22px;
  background-color: white;
  transform: translateY(20%);
}

#home .r-big-banner .content .service {
  width: 700px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 131px;
  margin-top: 6.8vw;
}

@media screen and (min-width: 200px) and (max-width: 1440px) {
  #home .r-big-banner .content .service {
    margin-top: 30px;
  }
}

#home .r-big-banner .content .service h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 24px;
}

#home .r-big-banner .scroll-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 105px;
  bottom: 5.46875vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#home .r-big-banner .follow-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 8vw;
  top: calc(50% - 55px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
}

#home .r-big-banner .follow-link label {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  bottom: 25px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

#home .r-big-banner .follow-link ul {
  padding: 0;
  margin-top: 10px;
}

#home .r-big-banner .follow-link ul li.telegram {
  /* margin-top: 36px; */
}

#home .r-big-banner .follow-link ul li+li {
  margin-top: 17px;
}

#home .r-slider {
  margin-top: -56px;
  margin-left: calc((100% - 1140px) / 2);
  /* margin-right: 136px; */
  position: relative;
  z-index: 3;
}

#footer .main .info .content {
  padding-left: 3px;
}

#footer .main .customService .content {
  padding-left: 3px;
}

#footer .main .customContent {
  padding-left: 3px;
}

.imgBgMB {
  display: none;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #home .r-slider {
    margin: 0;
  }

  #footer .main .customMarginTop .content-margin {
    margin-left: 0;
    font-size: 13px;
  }

  #footer .main .customMR-42 {
    margin-right: 0;
  }

  #footer .main .customRight {
    margin-right: 0;
  }

  #footer .main .customMarginBottom svg {
    margin-left: 0;
  }

  #footer .main .col-service {
    padding-right: 0;
    padding-bottom: 13px;
  }

  .TcomJapanPC {
    display: none;
  }

  .tcomJapanMB {
    display: block !important;
    margin-top: 48px;
  }

  #footer .main .customTokyo {
    margin-left: 2px;
    margin-top: -5px;
  }

  .customFz13 {
    font-size: 13px;
    margin-top: -18px;
  }

  #footer .main .customPaddingUl {
    padding-top: 0;
  }

  #footer .main .block-address+.block-address {
    margin-top: 0;
  }

  #footer .main .customMarginTop {
    margin-top: 0;
  }

  #footer .pt-5 {
    padding-top: 0 !important;
  }

  #footer .py-5 {
    padding-top: 0 !important;
  }

  #footer .main .customMT86 {
    margin-top: 0px;
  }

  #footer .main .tcomJapanMB .title {
    margin-bottom: 20px;
  }

  .iconLogo {
    padding-bottom: 47px;
  }

  #footer .main .customMarginBottom {
    margin-bottom: 7px;
  }

  #footer .main .logo {
    margin-bottom: 21px;
    margin-top: 56px;
  }

  #footer .main .logo::after {
    display: none;
  }

  /* #footer .main .customMarginBottom span:after {
        left: 36px;
    } */

  #footer .main .textLogo {
    font-size: 16px;
    line-height: 24px;
  }

  #footer .main .iconLogo ul {
    gap: 10px;
    margin-top: -6px;
  }

  #footer .main .block-address {
    margin-bottom: 20px;
    margin-top: -10px;
  }

  #footer .main .customService {
    margin-top: 15px;
  }

  /* #footer .main .customTokyo span::after {
        left: 31px;
    } */

  #footer .copyright p {
    padding: 24px 63px;
    font-size: 14px;
  }

  #footer .main .customRowFooter2-MB {
    margin-top: 54px;
  }

  #home .r-slider .container-fluid {
    padding: 0;
  }
}

#home .r-slider .owl-stage-outer {
  overflow: visible;
}

#home .r-slider .featured-news {
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 35.8%;
  height: 100%;
  z-index: 10;
}

#home .r-slider .featured-news h3 {
  font-size: 48px;
  font-weight: 900;
  padding-left: 58px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#home .r-slider .item {
  position: relative;
}

#home .r-slider .item::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 90%;
  width: 100%;
  background: linear-gradient(179.06deg, rgba(0, 0, 0, 0) 51.1%, #000 109.63%);
  z-index: 1;
}

#home .r-slider .item.item-1 .title {
  left: calc(50% + 13px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#home .r-slider .item.item-1 svg {
  margin-left: 41px;
}

#home .r-slider .item.item-2 .shape {
  position: absolute;
  left: -270px;
  z-index: 1;
  width: auto;
  max-height: 100%;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #home .r-slider .item.item-2 .shape {
    display: none;
  }
}

#home .r-slider .item.item-2 .title {
  left: -184px;
  z-index: 2;
  bottom: 77px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #home .r-slider .item.item-2 .title {
    left: 100px;
  }
}

#home .r-slider .item.item-2::after {
  left: -189px;
  -webkit-transform: skewX(-18deg);
  transform: skewX(-18deg);
  background: linear-gradient(179.06deg,
      rgba(0, 0, 0, 0) 51.1%,
      #000000 109.63%);
}

@media screen and (min-width: 200px) and (max-width: 1440px) {
  #home .r-slider .item.item-2::after {
    left: -202px;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #home .r-slider .item.item-2::after {
    left: 0;
    -webkit-transform: none;
    transform: none;
  }
}

#home .r-slider .item .title {
  position: absolute;
  bottom: 41px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 900;
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
  z-index: 2;
}

#home .r-slider img {
  width: 100%;
}

#home .r-slider .owl-nav {
  position: absolute;
  right: calc(100% - 16px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

#home .r-slider .owl-nav button {
  background-color: #0063aa;
  width: 72px;
  height: 72px;
  margin: 1px 0;
}

#home .r-featured-news {
  margin-top: 57px;
  margin-bottom: 179px;
}

#home .r-featured-news .slick-track {
  display: flex;
}

#home .r-featured-news .slick-slide {
  height: auto;
}

#home .r-featured-news .slick-slide>div,
#home .r-featured-news .slick-slide .item,
#home .r-featured-news .slick-slide a,
#home .r-featured-news .slick-slide .image,
#home .r-featured-news .slick-slide img {
  height: 100%;
}

#home .r-featured-news .title-left {
  margin-bottom: 46px;
}

#home .r-featured-news .title-left h3 {
  margin-bottom: 27px;
}

#home .r-featured-news .container-custom {
  margin-left: calc((100% - 1110px) / 2);
  margin-right: 178px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #home .r-featured-news .container-custom {
    margin-left: calc((100% - 930px) / 2);
  }
}

#home .r-featured-news .container-custom:hover .slick-arrow {
  opacity: 1;
}

#home .r-featured-news .container-custom .slick-arrow {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  opacity: 0;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
  z-index: 10;
}

#home .r-featured-news .container-custom .slick-arrow.slick-disabled {
  opacity: 0 !important;
}

#home .r-featured-news .container-custom .slick-arrow.slick-next {
  right: -141px;
}

#home .r-featured-news .container-custom .slick-arrow.slick-prev {
  display: none !important;
  left: -141px;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

#home .r-featured-news .container-custom .slick-list {
  overflow: visible;
}

#home .r-featured-news .container-custom .slick-list::before {
  content: '';
  width: 10000%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  right: 100%;
  z-index: 10;
}

#home .r-featured-news .container-custom .slick-track {
  margin: 0 -15px;
}

#home .r-featured-news .container-custom .item {
  padding: 0 15px;
}

#home .r-featured-news .container-custom .item a {
  display: block;
  position: relative;
}

#home .r-featured-news .container-custom .item a:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#home .r-featured-news .container-custom .item a .content {
  position: absolute;
  bottom: 36px;
  left: 25px;
  width: calc(100% - 40px);
  z-index: 11;
}

#home .r-featured-news .container-custom .item a .content h3 {
  color: #fff;
  font-weight: 900;
  font-size: 28px;
  line-height: 36px;
}

#home .r-featured-news .container-custom .image {
  border-radius: 15px;
  overflow: hidden;
  max-height: 397px;
  position: relative;
}

#home .r-featured-news .container-custom .image::before {
  content: '';
  background: linear-gradient(179.06deg,
      rgba(0, 0, 0, 0) 30.1%,
      #000000 109.63%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

#home .r-featured-news .container-custom .image img {
  max-height: 400px;
  object-fit: cover;
  width: 100%;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

#home .r-featured-news .container.bottom .read-more {
  display: none;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #home .r-big-banner .content .main-content > div > img{
    max-width: 100%;
  }
  #home .r-featured-news .container.bottom .read-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /* #footer .main .customMarginBottom span:after {
        left: 31px;
    } */

  .imgBgMB {
    display: block;
  }

  .imgBgMB img {
    width: 100%;
    object-fit: cover;
    margin: auto;
  }

  #web-development .r-big-banner h1 {
    text-align: center;
  }

  #web-development .r-big-banner h1::after {
    display: none;
  }

  #web-development .r-big-banner .desc {
    text-align: center;
    width: auto !important;
  }

  .customImgMb {
    display: none;
  }
}

#home .r-our-service {
  margin-top: 76px;
}

#home .r-our-service .title-left {
  margin-bottom: 31px;
}

#home .r-our-service .title-left h3 {
  margin-bottom: 27px;
}

#home .r-our-service .service-box {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

#home .r-our-service .service-box:hover .hover-content {
  opacity: 1;
  visibility: visible;
}

#home .r-our-service .service-box .inner {
  padding-top: 116px;
  padding-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

#home .r-our-service .service-box .inner .image {
  text-align: center;
  margin-bottom: 38px;
}

#home .r-our-service .service-box .inner .image img {
  width: 110px;
}

#home .r-our-service .service-box .inner h3 {
  font-size: 44px;
  font-weight: 900;
  color: #f0f9ff;
  line-height: 1.68;
  padding: 0 43px;
}

#home .r-our-service .service-box .hover-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #fff;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(100% 100% at 0% 0%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0) 100%);
  -webkit-box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  border-radius: 8px;
  z-index: 2;
}

#home .r-our-service .service-box .hover-content ul {
  padding: 33px 0;
  max-height: calc(100% - 119px);
  /* overflow-y: scroll; */
}

#home .r-our-service .service-box .hover-content ul li {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  padding: 0 30px;
  position: relative;
}

#home .r-our-service .service-box .hover-content ul li::before {
  content: '';
  position: absolute;
  left: 15px;
  top: calc(50% - 3px);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 1;
}

#home .r-our-service .service-box .hover-content h3 {
  background-color: #0063aa;
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
  padding: 23px 20px 16px;
  margin-bottom: 0;
}

#home .r-solution {
  margin-top: 82px;
  padding-top: 41px;
  padding-bottom: 45px;
  background: url('/assets/images/home/solution-bg.webp') no-repeat center;
  background-size: cover;
}

#home .r-solution .label {
  position: absolute;
  right: calc(100% + 41px);
  top: calc(50% + 61px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#home .r-solution .title-left {
  margin-bottom: 22px;
}

#home .r-solution .title-left h3 {
  color: #fff;
  margin-bottom: 20px;
}

#home .r-solution .title-left h3::after {
  background-color: #fff;
  border-radius: 30px;
}

#home .r-solution .title-left .desc {
  color: #fff;
}

#home .r-solution .read-more {
  color: #fff;
  top: 53px;
}

#home .r-solution .box {
  position: relative;
  margin-bottom: 15px;
}

#home .r-solution .box>img {
  width: 100%;
}

#home .r-solution .box.livestream .inner {
  background-color: #0063aa;
}

#home .r-solution .box.travel .inner {
  background-color: #fde002;
}

#home .r-solution .box.travel .inner h5,
#home .r-solution .box.travel .inner p,
#home .r-solution .box.travel .inner .btn-readmore {
  color: #00183e;
}

#home .r-solution .box.digital .inner {
  background-color: #eb2725;
}

#home .r-solution .box.school .inner {
  background-color: #0bbee5;
}

#home .r-solution .box .inner {
  position: absolute;
  top: 15px;
  left: 24px;
  bottom: 15px;
  width: calc(50% - 22px);
  color: #fff;
  border-radius: 4px;
  padding: 10px 16px;
}

#home .r-solution .box .inner .image {
  margin-bottom: 17px;
}

#home .r-solution .box .inner h5 {
  font-size: 16px;
  font-weight: 700;
}

#home .r-solution .box .inner p {
  line-height: 18px;
  margin-bottom: 9px;
}

#home .r-solution .box .inner p::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#home .r-solution .box .inner p::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

#home .r-solution .box .inner p::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

#home .r-solution .box .inner .btn-readmore {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: block;
  position: absolute;
  bottom: 7px;
  right: 30px;
}

#home .r-fields {
  margin-top: 116px;
  position: relative;
}

/* #home .r-fields::after {
  content: url("/assets/images/home/fields-img-2.png");
  position: absolute;
  right: 100px;
  top: -92px;
  z-index: 1;
} */

/* #home .r-fields .container::before {
  content: url("/assets/images/home/fields-img-4.png");
  position: absolute;
  right: calc(100% + 31px);
  top: 74px;
  z-index: 2;
} */

#home .r-fields::after {
  content: url('/assets/images/home/fields-img-2.png');
  position: absolute;
  right: 100px;
  top: -92px;
  z-index: 1;
}

#home .r-fields .label {
  position: absolute;
  left: calc(100% + 51px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

/* #home .r-fields::before {
  content: url("/assets/images/home/fields-img-3.png");
  position: absolute;
  right: 269px;
  top: 74px;
  z-index: 2;
} */

#home .r-fields .col-left .title-left h3 {
  margin-bottom: 19px;
}

#home .r-fields .col-left .title-left .desc {
  margin-right: -1px;
}

#home .r-fields .col-left img {
  display: block;
  margin: -4px auto 0;
}

#home .r-fields .col-right {
  margin-top: 11px;
}

#home .r-fields .field-item {
  margin-bottom: 19px;
  padding-left: 62px;
}

#home .r-fields .field-item .icon {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#home .r-fields .field-item h3 {
  color: #686868;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  min-height: 50px;
}

#home .r-featured-project {
  margin-top: 20px;
  padding: 33px 0 38px;
  background-color: #f5f5f5;
}

#home .r-featured-project .image {
  max-height: 446px;
  height: 446px;
}

#home .r-featured-project .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home .r-featured-project .label {
  position: absolute;
  right: calc(100% + 41px);
  top: calc(50% + 14px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#home .r-featured-project .title-left {
  margin-bottom: -2px;
}

#home .r-featured-project .title-left h3 {
  margin-bottom: 27px;
}

#home .r-featured-project .owl-stage-outer {
  padding: 20px;
  margin: 0 -20px;
}

#home .r-featured-project .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: space-between; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#home .r-featured-project .col-left {
  width: 417px;
  max-width: 100%;
}

#home .r-featured-project .col-right {
  width: calc(100% - 437px);
}

#home .r-featured-project .col-left img {
  width: auto;
  margin-bottom: 52px;
  margin-top: 20px;
  max-width: 100%;
}

#home .r-featured-project .col-left h3 {
  font-size: 40px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 31px;
}

#home .r-featured-project .col-left p {
  font-size: 18px;
  font-weight: 400;
  color: #686868;
  line-height: 32px;
  padding-right: 82px;
}

#home .r-featured-project .col-right .image {
  /* -webkit-box-shadow: 0 4px 11px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.25); */
}

#home .r-featured-project .owl-nav {
  position: absolute;
  top: 25px;
  right: 80px;
}

#home .r-featured-project .owl-nav .owl-next {
  margin-left: 23px;
}

#home .r-featured-project .owl-nav svg {
  width: 32px;
  height: 32px;
}

#home .r-featured-project #counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 72px;
  right: 15px;
  z-index: 1;
}

#home .r-featured-project #counter .current {
  font-size: 24px;
  color: #0063aa;
  position: relative;
  bottom: 15px;
}

#home .r-featured-project #counter .separate {
  background-color: #0063aa;
  -webkit-transform: rotate(45deg);
  transform: rotate(27deg);
  width: 1px;
  height: 36px;
  display: block;
  position: absolute;
  left: 16px;
  top: -12px;
}

#home .r-featured-project #counter .total {
  font-size: 16px;
  color: #0063aa;
  margin-left: 7px;
}

#home .r-member {
  padding-top: 61px;
  padding-bottom: 223px;
  background: url('/assets/images/home/member-bg.png') no-repeat center;
}

#home .r-member .label {
  position: absolute;
  left: calc(100% + 51px);
  top: calc(50% + 155px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#home .r-member .row {
  margin-left: -8px;
  margin-right: -8px;
}

#home .r-member .row .col-4 {
  padding-left: 8px;
  padding-right: 8px;
}

#home .r-member .image-logo {
  margin-top: 82px;
  margin-bottom: 52px;
}

#home .r-member .box {
  border: 1px solid #e2e2e2;
  box-shadow: 0px 11px 12px rgb(189 189 189 / 25%);
  border-radius: 15px;
  height: 100%;
  padding: 10px 15px;
  display: flex;
  background-color: #fff;
  align-content: space-around;
  flex-direction: column;
  justify-content: space-around;
}

#contact .r-member .box {
  border: 1px solid #e2e2e2;
  box-shadow: 0px 11px 12px rgb(189 189 189 / 25%);
  border-radius: 15px;
  height: 100%;
  padding: 10px 15px;
  display: flex;
  background-color: #fff;
  align-content: space-around;
  flex-direction: column;
  justify-content: space-around;
}

#home .r-member .box .logo {
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 19px;
}

#home .r-member .box .logo img {
  width: auto;
}

#home .r-member .box ul li {
  font-size: 16px;
  color: #656565;
  position: relative;
  padding-left: 13px;
  line-height: 20px;
}

#home .r-member .box ul li::before {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #656565;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 8px;
}

#home .r-achievement {
  padding: 53px 0 0;
  background: url('/assets/images/home/achievement-bg.webp') no-repeat center;
  background-size: cover;
}

#home .r-achievement .label {
  position: absolute;
  right: calc(100% + 52px);
  top: calc(50% + -25px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#home .r-achievement .title-left h3 {
  color: #fff;
  margin-bottom: 27px;
}

#home .r-achievement .title-left h3::after {
  background-color: #fff;
  border-radius: 8px;
}

#home .r-achievement .title-left .desc {
  color: #fff;
  line-height: 32px;
  padding-right: 110px;
}

#home .r-achievement .col-left .title-left {
  margin-top: 57px;
  margin-bottom: 0;
}

#home .r-achievement .col-left .image {
  margin-top: -16px;
  margin-left: -18px;
  margin-bottom: -9px;
}

#home .r-achievement .col-right .row {
  margin: 0 -12px;
}

#home .r-achievement .col-right .col-6 {
  margin-bottom: 25px;
  padding: 0 12px;
}

#home .r-achievement .col-right .box {
  -webkit-box-shadow: inset 0px 0px 7.66799px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 7.66799px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 24px 30px 15px;
  height: 226px;
  position: relative;
}

#home .r-achievement .col-right .box .number {
  color: #fff;
  font-size: 40px;
  font-weight: 900;
  line-height: 1;
  margin-top: 27px;
  margin-bottom: 6px;
}

#home .r-achievement .col-right .box h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}

#home .r-achievement .col-right .box.box-1 {
  background-color: #0063aa;
}

#home .r-achievement .col-right .box.box-1 .number {
  margin-top: 36px;
}

#home .r-achievement .col-right .box.box-2 {
  -webkit-transform: translateY(23px);
  transform: translateY(23px);
  background-color: #fde002;
}

#home .r-achievement .col-right .box.box-2::before {
  content: '';
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: -18px;
}

#home .r-achievement .col-right .box.box-2 .icon {
  margin-top: 18px;
}

#home .r-achievement .col-right .box.box-2 .number {
  color: #0063aa;
}

#home .r-achievement .col-right .box.box-2 h3 {
  color: #0063aa;
}

#home .r-achievement .col-right .box.box-3 {
  background-color: #eb2725;
}

#home .r-achievement .col-right .box.box-4 {
  background-color: #00be90;
  -webkit-transform: translateY(23px);
  transform: translateY(23px);
}

#home .r-customer {
  padding: 66px 0 0;
}

#home .r-customer .label {
  position: absolute;
  left: calc(100% + 50px);
  top: -50px;
  z-index: -1;
}

#home .r-customer .title-center {
  margin-bottom: 87px;
}

#home .r-customer .title-center .desc {
  font-size: 18px;
}

#home .r-customer .container-fluid {
  width: 1435px;
  max-width: 100%;
}

#home .r-customer .container-fluid .brands-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#home .r-customer .container-fluid .brands-list li {
  margin: 0 53px 64px 53px;
}

#home .r-customer .map {
  margin-top: -7px;
}

#home .r-recruit {
  position: relative;
  margin-top: 3px;
  padding: 80px 0 0;
  background: url('/assets/images/home/recruit-bg.webp') no-repeat center;
  background-size: cover;
}

#home .r-recruit .title-left {
  margin-bottom: 35px;
}

#home .r-recruit .title-left h3 {
  color: #fff;
  margin-bottom: 27px;
}

#home .r-recruit .title-left h3::after {
  background-color: #fff;
  border-radius: 8px;
}

#home .r-recruit .title-left .desc {
  color: #fff;
}

#home .r-recruit .owl-stage-outer {
  overflow: visible;
}

#home .r-recruit .owl-stage-outer .owl-item {
  opacity: 0;
}

#home .r-recruit .owl-stage-outer .owl-item.active {
  opacity: 1;
}

#home .r-recruit .job {
  color: #fff;
  /* padding: 0 0 0 80px; */
  margin-right: 80px;
  margin-top: 40px;
  position: relative;
}

#home .r-recruit .job .under {
  font-size: 80px;
  font-weight: 900;
  width: 100%;
  top: -55px;
  text-align: left;
  position: absolute;
  color: rgba(255, 255, 255, 0.25);
  line-height: 117px;
}

#home .r-recruit .job h3 {
  font-size: 32px;
  font-weight: 700;
  height: 76.78px;
}

#home .r-recruit .job ul {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding-top: 26px;
  margin-top: 23px;
  margin-bottom: 23px;
}

#home .r-recruit .job ul li {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 24px;
  margin-top: -7px;
  /* font-weight: 900; */
}

#home .r-recruit .job ul li+li {
  margin-top: 20px;
}

#home .r-recruit .job ul li .name {
  margin-top: 7px;
  /* margin-right: 15px; */
}

#home .r-recruit .job ul li .name:first-child {
  font-weight: 100;
  margin-bottom: 8px;
}

#home .r-recruit .job ul li .customNameMT:first-child {
  margin-bottom: 5px;
  margin-top: 19px;
}

#home .r-recruit .job .view-details {
  font-size: 24px;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 17px;
}

#home .r-recruit .job .view-details:hover svg {
  margin-left: 26px;
}

#home .r-recruit .job .view-details svg {
  margin-left: 16px;
}

#home .r-recruit .owl-nav button {
  position: absolute;
  top: calc(50% - 24px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#home .r-recruit .owl-nav button.owl-prev {
  left: -80px;
}

#home .r-recruit .owl-nav button.owl-next {
  right: -80px;
}

#home .r-recruit .careers {
  background: url('/assets/images/home/careers-bg.webp') no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  color: #fff;
  text-align: center;
  padding: 46px 40px;
  margin-top: 40px;
  position: relative;
}

#home .r-recruit .careers h3 {
  font-size: 40px;
  font-weight: 900;
}

#home .r-recruit .careers p {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 40px;
  margin-bottom: 65px;
}

#home .r-recruit .careers h1 {
  font-size: 60px;
  font-weight: 900;
  margin-bottom: 42px;
}

#home .r-recruit .careers .extend {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fee200;
  padding: 21.5px 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 0 0 8px 8px;
}

#home .r-recruit .careers .extend .btn-readmore {
  font-size: 20px;
  font-weight: 700;
  color: #0063aa;
  border: 1px solid #0063aa;
  border-radius: 4px;
  padding: 6.5px 66px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#home .r-recruit .careers .extend .btn-readmore:hover {
  background-color: #0063aa;
  color: #fff;
}

#home .r-recruit .careers .extend .btn-readmore svg {
  margin-left: 23px;
}

#home .r-news {
  margin-top: 193px;
  margin-bottom: 126px;
  background: url('/assets/images/home/news-bg.png') no-repeat center 77px;
  background-size: contain;
}

#home .r-news .label {
  position: absolute;
  right: calc(100% + 41px);
  top: calc(50% + 59px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

#home .r-news .title-center {
  margin-bottom: 72px;
}

#home .r-news .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

#home .r-news .top h5 {
  font-size: 24px;
  font-weight: 700;
  color: #0063aa;
}

#home .r-news .top .btn-readmore {
  font-size: 20px;
  font-weight: 700;
  color: #0063aa;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#home .r-news .top .btn-readmore svg {
  margin-left: 23px;
}

#home .r-news .news-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 23px;
}

#home .r-news .news-item .content {
  margin-right: 95px;
  width: calc(100% - 541px);
}

#home .r-news .news-item .content .title {
  display: block;
  font-size: 24px;
  font-weight: 900;
  line-height: 35px;
  color: #464646;
  margin-top: 1px;
  margin-bottom: 11px;
  white-space: break-spaces;
}

#home .r-news .news-item .content .title:hover {
  color: #0063aa;
}

#home .r-news .news-item .content .date {
  font-size: 16px;
  color: #353535;
  margin-bottom: 8px;
}

#home .r-news .news-item .content .date img {
  margin-right: 5px;
}

#home .r-news .news-item .content p {
  font-size: 16px;
  color: #313131;
  margin-bottom: 6px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

#home .r-news .news-item .content .readmore {
  font-size: 16px;
  font-weight: 500;
  color: #0063aa;
}

#home .r-news .news-item .content .readmore:hover {
  text-decoration: underline;
}

#home .r-news .news-item .image {
  width: 446px;
  overflow: hidden;
  max-height: 206px;
}

#home .r-news .news-item .image img {
  transition: 0.4s all;
  max-height: 206px;
  min-height: 206px;
  width: 100%;
  object-fit: cover;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

#home .r-news .news-item .image:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (min-width: 1440px) and (max-width: 1600px) {
  #home .r-big-banner .follow-link {
    left: 5vw;
  }
}

@media (min-width: 1300px) and (max-width: 1440px) {
  #home .r-big-banner .follow-link {
    left: 2vw;
  }
}

@media (min-width: 1250px) and (max-width: 1300px) {
  #home .r-big-banner .follow-link {
    left: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  #home .r-big-banner .follow-link {
    left: -5px;
  }
}

@media (min-width: 1200px) {
  #home .r-member .row .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  #home .r-fields .col-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 27%;
    flex: 0 0 27%;
    max-width: 27%;
  }

  #home .r-fields .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 73%;
    flex: 0 0 73%;
    max-width: 73%;
  }

  #home .r-achievement .col-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 56.32%;
    flex: 0 0 56.32%;
    max-width: 56.32%;
  }

  #home .r-achievement .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 43.68%;
    flex: 0 0 43.68%;
    max-width: 43.68%;
  }
}

@media (max-width: 1199px) {
  #home .r-featured-news .container-custom .item a .content {
    bottom: 15px;
    left: 20px;
  }

  #home .r-featured-news .container-custom .item a .content h3 {
    font-size: 18px;
    line-height: 28px;
  }

  #home .r-big-banner .container-fluid>img {
    height: 93vh;
    -o-object-fit: cover;
    object-fit: cover;
  }

  #home .r-big-banner .follow-link,
  #home .r-big-banner .scroll-link {
    display: none;
  }

  #home .r-big-banner {
    margin-top: 0;
  }

  #home .title-left h3,
  #home .title-center h3 {
    font-size: 30px;
  }

  #home .title-left .desc,
  #home .title-center .desc {
    font-size: 14px;
  }

  #home .r-slider .featured-news h3 {
    font-size: 30px;
    padding-left: 15px;
  }

  #home .r-slider .item.item-1 .title {
    left: calc(50% + 50px);
    font-size: 17px;
    line-height: 28px;
  }

  #home .r-solution .box.livestream .inner h5 {
    font-size: 18px;
  }

  #home .r-solution .box .inner p {
    max-height: 48px;
    /* overflow-y: scroll; */
  }

  #home .r-our-service .service-box .inner {
    padding-top: 70px;
  }

  #home .r-our-service .service-box .inner .image img {
    width: 68px;
  }

  #home .r-our-service .service-box .inner h3 {
    font-size: 24px;
  }

  #home .r-fields::before,
  #home .r-fields::after {
    display: none;
  }

  #home .r-fields .field-item {
    padding-left: 0;
  }

  #home .r-fields .col-left img {
    display: none;
  }

  /* #home .r-featured-project .owl-nav {
    top: 0;
  } */
  #home .r-recruit .job h3 {
    font-size: 25px;
    height: 60px;
  }

  #home .r-recruit .job ul li {
    font-size: 18px;
  }

  #home .r-recruit .job .view-details {
    font-size: 18px;
  }

  #home .r-recruit .job .under {
    font-size: 50px;
    top: -25px;
    left: 0;
  }

  #home .r-recruit .careers h3 {
    font-size: 28px;
  }

  #home .r-recruit .careers p {
    font-size: 16px;
    margin-bottom: 40px;
  }

  #home .r-recruit .careers h1 {
    font-size: 40px;
    margin-bottom: 42px;
  }

  #home .r-recruit .careers .extend .image img {
    width: 200px;
  }

  #home .r-news .news-item .image {
    width: 40%;
  }

  #home .r-news .news-item .content {
    width: calc(60% - 40px);
    margin-right: 0;
  }

  #home .r-news .news-item .content p {
    font-size: 14px;
  }

  #home .r-member .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  #home .r-member .row .col-4 {
    margin-bottom: 16px;
  }

  #home .r-member {
    padding-bottom: 100px;
  }

  #home .r-achievement .col-right .box {
    padding: 15px;
    height: auto;
  }

  #home .r-achievement .col-right .box .number {
    font-size: 32px;
  }

  #home .r-achievement .col-right .box h3 {
    font-size: 16px;
  }

  #home .r-customer .container-fluid .brands-list li {
    margin: 0 30px 64px 30px;
  }
}

@media (max-width: 991px) {
  #home .r-our-service .service-box .hover-content h3 {
    font-size: 20px;
    line-height: 30px;
  }

  #home .r-featured-project .inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: baseline;
  }

  #home .r-featured-project .col-left {
    width: 100%;
  }

  #home .r-featured-project .col-right {
    width: 100%;
  }

  #home .r-solution .box .inner p {
    max-height: initial;
    /* overflow-y: scroll; */
  }

  #home .r-featured-news .container-custom {
    margin-left: calc((100% - 690px) / 2);
    margin-right: 78px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #home .r-big-banner .content .title {
    font-size: 24px;
    padding: 0 12px;
  }

  #home .r-big-banner .content .main-content .desc {
    font-size: 48px;
    line-height: 44px;
  }

  #home .r-big-banner .content .main-content .sortContent {
    max-width: 420px;
  }
}

@media (min-width: 768px) {
  #home .r-big-banner .slider-mobile {
    display: none;
  }

  .slide-content-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .slide-content-desktop {
    display: none;
  }

  #home .r-big-banner .slider-desktop {
    display: none;
  }

  #home .r-featured-project .image {
    max-height: 215px;
    height: 215px;
  }

  #home .r-our-service .service-box .hover-content ul {
    padding: 15px 0;
    max-height: calc(100% - 50px);
  }

  #home .r-our-service .service-box .hover-content ul li {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 10px;
    padding: 0 20px;
  }

  #home .r-our-service .service-box .hover-content ul li:before {
    height: 4px;
    left: 12px;
    top: 4px;
    width: 4px;
  }

  #home .r-our-service .service-box .hover-content h3 {
    font-size: 14px;
    line-height: 20px;
    padding: 9px 20px 5px;
  }

  #home .r-featured-news {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  #home .r-featured-news .title-left h3 {
    margin-bottom: 15px;
  }

  #home .r-featured-news .title-left {
    margin-bottom: 30px;
  }

  #home .r-featured-news .title-left .read-more {
    display: none;
  }

  #home .r-featured-news .container-custom {
    margin-left: calc((100% - 492px) / 2);
  }

  .container {
    padding-right: 24px;
    padding-left: 24px;
  }

  #home .title-left {
    margin-bottom: 30px;
  }

  #home .title-left h3,
  #home .title-center h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  #home .title-left .desc,
  #home .title-center .desc {
    font-size: 14px;
    line-height: 24px;
    color: #434343;
    padding-right: 40px;
  }

  #home .title-left .desc br,
  #home .title-center .desc br {
    display: none;
  }

  #home .title-left h3::after {
    bottom: -4px;
    width: 14px;
    height: 1px;
  }

  #home .r-our-service {
    margin-top: 41px;
  }

  #home .r-big-banner .content .title {
    font-size: 16px;
    line-height: 20px;
    margin-top: 60px;
    padding: 5px;
  }

  #home .r-big-banner .container-fluid>img {
    height: 430px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  #home .r-big-banner .content .scroll-link {
    display: none;
  }

  #home .r-big-banner .content .main-content .desc {
    font-size: 36px;
    line-height: 32px;
  }

  #home .r-big-banner .content .main-content .readMore,
  #home .r-big-banner .content .main-content .sortContent {
    font-size: 16px;
    line-height: 24px;
  }

  #home .r-big-banner .content .desc br {
    display: none;
  }

  #home .r-big-banner .content .service img {
    width: 75px;
  }

  #home .r-big-banner .content .service h3 {
    font-size: 16px;
    line-height: 24px;
    margin-top: 17px;
  }

  #home .r-big-banner .content {
    text-align: center;
    width: 100%;
    top: 40%;
    -webkit-transform: none;
    transform: none;
    left: 0;
  }

  #home .r-big-banner .content .service {
    margin-top: 41px;
  }

  #home .r-slider .item.item-1 .title {
    font-size: 14px;
    line-height: 24px;
    width: 60%;
    left: calc(50% + 60px);
    bottom: 32px;
  }

  #home .r-slider .item.item-1 .title br {
    display: none;
  }

  #home .r-slider .item.item-1 .title svg {
    position: absolute;
    margin-left: 0;
    right: 10px;
    bottom: -21px;
    width: 32px;
  }

  #home .r-slider .item.item-2 .title {
    left: 32px;
    bottom: 32px;
  }

  #home .r-slider .item .title {
    font-size: 14px;
    line-height: 24px;
  }

  #home .r-slider .featured-news {
    width: 37.8%;
  }

  #home .r-slider .featured-news h3 {
    font-size: 16px;
    padding-left: 24px;
    top: 24px;
    -webkit-transform: none;
    transform: none;
  }

  #home .r-slider .featured-news h3::after {
    content: '';
    width: 14px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 23px;
    left: 24px;
  }

  #home .r-slider .featured-news img {
    height: 100%;
  }

  #home .r-our-service .service {
    width: calc(100% + 24px);
    overflow-x: scroll;
  }

  #home .r-our-service .service .row {
    min-width: 670px;
  }

  #home .r-our-service .service-box .inner h3 {
    padding: 0 20px;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
  }

  #home .r-our-service .service-box .inner {
    padding-bottom: 30px;
  }

  #home .read-more {
    position: static;
    font-size: 14px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 32px;
  }

  #home .read-more svg {
    margin-left: 15px;
  }

  #home .read-more svg circle {
    fill: transparent;
  }

  #home .read-more svg path {
    fill: #0063aa;
  }

  #home .r-solution {
    margin-top: 38px;
    padding-top: 29px;
    padding-bottom: 27px;
  }

  #home .r-solution .title-left h3 {
    margin-bottom: 14px;
  }

  #home .r-solution .title-left .desc {
    padding-right: 60px;
  }

  #home .r-solution .title-left {
    margin-bottom: 30px;
  }

  #home .r-solution .box .inner {
    top: 8px;
    left: 14px;
    bottom: 13px;
    width: calc(50% - 8px);
    border-radius: 4px;
    padding: 13px 10px;
  }

  #home .r-solution .box .inner .image {
    margin-bottom: 9px;
  }

  #home .r-solution .box .inner .image img {
    width: 32px;
  }

  #home .r-solution .box.livestream .inner h5,
  #home .r-solution .box .inner h5 {
    font-size: 14px;
    margin-bottom: 6px;
  }

  #home .r-solution .box .inner p {
    font-size: 12px;
    max-height: calc(100% - 102px);
    line-height: 16px;
    overflow-y: auto;
  }

  #home .r-recruit .slick-slider .slick-list {
    margin: 0 -10px;
  }

  #home .r-recruit .job {
    padding: 0 0 0 10px;
  }

  #home .r-solution .box .inner .btn-readmore {
    font-size: 11px;
    bottom: 10px;
    right: 20px;
  }

  #home .r-solution .box {
    margin-bottom: 16px;
  }

  #home .r-solution .read-more {
    margin-top: 6px;
  }

  #home .r-solution .read-more svg path {
    fill: #fff;
  }

  #home .r-fields {
    margin-top: 54px;
  }

  #home .r-fields .col-left .title-left h3 {
    margin-bottom: 13px;
  }

  #home .r-fields .col-left .title-left .desc {
    padding-right: 0;
  }

  #home .r-fields .field-item {
    margin-bottom: 3px;
  }

  #home .r-fields .field-item>svg {
    width: 79px;
    margin-top: -30px;
  }

  #home .r-fields .field-item>svg rect {
    height: 1.62px;
  }

  #home .r-fields .field-item .icon {
    margin-bottom: 3px;
    height: auto;
  }

  #home .r-fields .field-item .icon svg {
    width: 40px;
  }

  #home .r-fields .field-item h3 {
    font-size: 14px;
  }

  #home .r-fields .title-left {
    margin-bottom: -8px;
  }

  #home .r-fields .read-more {
    margin-top: 3px;
    margin-right: -6px;
  }

  #home .r-featured-project .col-left {
    width: 100%;
    min-height: 222px;
  }

  #home .r-featured-project {
    margin-top: 28px;
    padding: 27px 0 9px;
  }

  #home .r-featured-project .title-left h3 {
    margin-bottom: 18px;
  }

  #home .r-featured-project .col-left img {
    margin-bottom: 17px;
    margin-top: 3px;
  }

  #home .r-featured-project .col-left h3 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  #home .r-featured-project .col-left p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 13px;
    padding-right: 70px;
  }

  #home .r-featured-project .owl-nav {
    position: static;
    text-align: center;
    margin-top: 23px;
  }

  #home .r-featured-project .owl-nav .owl-next {
    margin-left: 39px;
  }

  #home .r-featured-project #counter {
    top: 167px;
    right: 195px;
  }

  #home .r-featured-project #counter .current {
    font-weight: 900;
  }

  #home .r-featured-project #counter .total {
    font-size: 14px;
    margin-left: 5px;
  }

  #home .r-featured-project .read-more {
    margin-top: 17px;
  }

  #home .title-center h3 {
    line-height: 24px;
  }

  #home .title-center h3::after {
    bottom: -9px;
    width: 14px;
    height: 1px;
  }

  #home .r-member .title-center {
    margin-bottom: 31px;
  }

  #home .r-member .title-center h3 {
    width: 177px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 26px;
  }

  #home .title-center .desc {
    padding-right: 10px;
    padding-left: 10px;
  }

  #home .r-member {
    padding-top: 23px;
    padding-bottom: 35px;
  }

  #home .r-member .image-logo {
    margin-top: 0;
    margin-bottom: 22px;
  }

  #home .r-member .image-logo img {
    width: 126.5px;
  }

  #home .r-member .box .logo {
    height: 33px;
    margin-bottom: 7px;
  }

  #home .r-member .box .logo.tsoft img {
    width: 59px;
  }

  #home .r-member .box .logo.t-japan img {
    width: 73px;
  }

  #home .r-member .box .logo.eyefire img {
    width: 77px;
  }

  #home .r-member .box .logo.sky-live img {
    width: 43px;
  }

  #home .r-member .box .logo.blockchain img {
    width: 75px;
  }

  #home .r-member .box ul li {
    font-size: 8px;
    padding-left: 5px;
    line-height: 16px;
  }

  #home .r-member .row {
    margin-left: -4px;
    margin-right: -4px;
  }

  #home .r-member .row .col-4 {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 7px;
  }

  #home .r-member .box ul li::before {
    width: 2px;
    height: 2px;
  }

  #home .r-member .box {
    padding: 6px 7px 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  #home .r-achievement {
    padding-top: 31px;
    padding-bottom: 26px;
  }

  #home .r-achievement .col-left .title-left {
    margin-top: 0;
    text-align: center;
  }

  #home .r-achievement .col-left .title-left h3 {
    margin-bottom: 29px;
  }

  #home .r-achievement .col-left .title-left h3::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -12px;
  }

  #home .r-achievement .title-left .desc {
    line-height: 24px;
    padding: 0 13px;
    margin-bottom: 32px;
  }

  #home .r-achievement .col-left .image {
    display: none;
  }

  #home .r-achievement .col-right .box.box-2,
  #home .r-achievement .col-right .box.box-4 {
    -webkit-transform: none;
    transform: none;
  }

  #home .r-achievement .col-right .box.box-2::before,
  #home .r-achievement .col-right .box.box-4::before {
    display: none;
  }

  #home .r-achievement .col-right .box.box-1 img {
    width: 40px;
  }

  #home .r-achievement .col-right .box.box-2 img {
    width: 54px;
  }

  #home .r-achievement .col-right .box.box-3 img {
    width: 51px;
  }

  #home .r-achievement .col-right .box.box-4 img {
    width: 48px;
  }

  #home .r-achievement .col-right .row {
    margin: 0 -8px;
  }

  #home .r-achievement .col-right .col-6 {
    margin-bottom: 17px;
    padding: 0 8px;
  }

  #home .r-achievement .col-right .box {
    padding: 16px 19px 12px;
  }

  #home .r-achievement .col-right .box .number {
    margin-top: 17px;
  }

  #home .r-achievement .col-right .box.box-1 .number {
    margin-top: 21px;
    margin-bottom: 4px;
  }

  #home .r-achievement .col-right .box.box-2 .icon {
    margin-top: 12px;
  }

  #home .r-customer .title-center .desc {
    font-size: 14px;
    line-height: 24px;
  }

  #home .r-customer {
    padding: 33px 0 0;
  }

  #home .r-customer .title-center h3::after {
    bottom: -5px;
  }

  #home .r-customer .title-center h3 {
    margin-bottom: 22px;
  }

  #home .r-customer .container-fluid .brands-list li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin: 8px 0px 11px 0px;
    text-align: center;
  }

  #home .r-customer .container-fluid .brands-list li img {
    width: 50%;
  }

  #home .r-customer .container-fluid .brands-list li:nth-child(13) img {
    width: 100%;
  }

  #home .r-customer .title-center {
    margin-bottom: 31px;
  }

  #home .r-customer .map {
    margin-top: -4px;
  }

  #home .r-customer .map img {
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  #home .r-recruit {
    margin-top: 0;
    padding: 36px 0 0;
  }

  #home .r-recruit .title-left h3 {
    margin-bottom: 18px;
  }

  #home .r-recruit .title-left .desc {
    padding-right: 0px;
  }

  #home .r-recruit .job h3 {
    font-size: 14px;
    height: 32px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 3px;
  }

  #home .r-recruit .job .under {
    font-size: 30px;
    top: -15px;
    line-height: 35px;
    font-weight: 900;
    left: unset;
  }

  #home .r-recruit .job ul li {
    font-size: 12px;
    font-weight: 500;
  }

  #home .r-recruit .job ul li:first-child {
    font-size: 12px;
    font-weight: 100;
    margin-bottom: 20px;
  }

  #home .r-recruit .job ul li .name {
    min-width: 47px;
    margin-right: 6px;
  }

  #home .r-recruit .job ul li+li {
    margin-top: -6px;
  }

  #home .r-recruit .job .view-details {
    font-size: 12px;
  }

  #home .r-recruit .job .view-details svg {
    margin-left: 6px;
    width: 24px;
  }

  #home .r-recruit .job ul {
    padding-top: 1px;
    margin-top: 0;
    margin-bottom: -9px;
  }

  #home .r-recruit .title-left {
    margin-bottom: 15px;
  }

  #home .r-recruit .jobs-carousel {
    margin-right: 50px;
    width: calc(100% - 85px);
  }

  #home .r-recruit .owl-stage-outer .owl-item {
    opacity: 1;
  }

  #home .r-recruit .owl-nav {
    display: none;
  }

  #home .r-recruit .careers h3 {
    font-size: 14px;
  }

  #home .r-recruit .careers p {
    font-size: 14px;
    line-height: 24px;
    margin-top: -2px;
    margin-bottom: 2px;
  }

  #home .r-recruit .careers p br {
    display: none;
  }

  #home .r-recruit .careers h1 {
    font-size: 17.5px;
    margin-bottom: -3px;
  }

  #home .r-recruit .careers {
    padding: 12px 15px;
    margin-top: 16px;
  }

  #home .r-recruit .careers .extend .image img {
    width: 115px;
  }

  #home .r-recruit .careers .extend .btn-readmore {
    font-size: 6px;
    padding: 0px 11px;
    line-height: 14px;
    width: 89px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  #home .r-recruit .careers .extend .btn-readmore svg {
    width: 9px;
    height: 9px;
    margin-left: 7px;
  }

  #home .r-recruit .careers .extend {
    padding: 5px 23px;
  }

  #home .r-news {
    margin-top: 67px;
    margin-bottom: 40px;
  }

  #home .r-news .top .btn-readmore {
    display: none;
  }

  #home .r-news .top h5 {
    font-size: 16px;
  }

  #home .r-news .title-center {
    margin-bottom: 20px;
  }

  #home .r-news .news-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  #home .r-news .news-item .content,
  #home .r-news .news-item .image {
    width: 100%;
  }

  #home .r-news .news-item .content .title {
    font-size: 14px;
    margin-bottom: -2px;
    line-height: 24px;
  }

  #home .r-news .news-item .content .date {
    font-size: 10px;
    margin-bottom: 6px;
  }

  #home .r-news .top {
    margin-bottom: 4px;
  }

  #home .r-news .news-item .content .date img {
    margin-right: 5px;
    width: 16px;
  }

  #home .r-news .news-item .content .readmore {
    font-size: 14px;
    margin-top: 10px;
    display: block;
  }

  #home .r-news .news-item .content {
    margin-bottom: 18px;
  }

  #home .r-news .news-item {
    margin-bottom: 35px;
  }

  #home .r-featured-news .container-custom .item a .content h3 {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 540px) {
  #home .r-featured-news .container-custom {
    margin-left: 15px;
    margin-right: 50px;
  }
}

@media (max-width: 375px) {
  #home .r-slider .item>img {
    height: 213px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

#about #header .header-desktop {
  position: relative;
  z-index: 2;
}

#about .title-left {
  margin-bottom: 58px;
}

#about .title-left h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 35px;
}

#about .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#about .title-left .desc {
  font-size: 18px;
  color: #686868;
}

#about .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#about .title-center h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
}

#about .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#about .title-center .desc {
  font-size: 20px;
  color: #676767 !important;
}

#about .read-more {
  font-size: 20px;
  font-weight: 700;
  color: #0063aa;
  position: absolute;
  top: 101px;
  right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#about .read-more svg {
  margin-left: 22px;
}

#about .r-big-banner {
  padding: 142px 0 110px;
  margin-top: -75px;
  position: relative;
  z-index: 1;
  background: url('/assets/images/about/big_banner_bg_about.jpg') no-repeat center;
  background-size: cover;
}

#about .r-big-banner .col-left h1 {
  font-size: 48px;
  line-height: 56.25px;
  font-weight: 700;
  color: #121212;
  position: relative;
  margin-bottom: 80px;
}

#about .r-big-banner .col-left h1::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -32px;
  width: 114px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#about .r-big-banner .col-left .block {
  margin-bottom: 40px;
  width: 417px;
  max-width: 100%;
}

#about .r-big-banner .col-left .block h3 {
  font-size: 24px;
  font-weight: 700;
  color: #0063aa;
}

#about .r-big-banner .col-left .block p {
  font-size: 16px;
  line-height: 20px;
  color: #434343;
}

#about .r-big-banner .col-right .block-image {
  width: 698px;
  height: 675px;
  position: relative;
  top: 24px;
  right: 99px;
}

#about .r-big-banner .col-right .block-image .block {
  display: inline-block;
  position: absolute;
  z-index: 1;
}

#about .r-big-banner .col-right .block-image .block .image {
  position: relative;
}

#about .r-big-banner .col-right .block-image .block .image .circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 8px;
  left: 8px;
  z-index: -1;
}

#about .r-big-banner .col-right .block-image .block.block-1 {
  top: 0;
  right: 50px;
}

#about .r-big-banner .col-right .block-image .block.block-1 .image .circle {
  background-color: #fde002;
}

#about .r-big-banner .col-right .block-image .block.block-2 {
  left: 0;
  bottom: 157px;
}

#about .r-big-banner .col-right .block-image .block.block-3 {
  right: 76px;
  bottom: 73px;
}

#about .r-big-banner .col-right .block-image .block.block-4 {
  bottom: 4px;
  left: 265px;
}

#about .r-big-banner .col-right .block-image .block.block-4 .image .circle {
  background-color: #0063aa;
  top: 4px;
  left: 4px;
}

#about .r-progress {
  color: #fff;
  padding: 114px 0 54px;
  background: url('/assets/images/about/progress-bg-2.png') no-repeat center;
  background-size: cover;
}

#about .r-progress .slick-next {
  border: none;
  background: transparent;
  position: absolute;
}

#about .r-progress .title-left {
  margin-bottom: 50px;
}

#about .r-progress .title-left h3 {
  color: #0063aa;
  font-size: 48px;
  text-transform: uppercase;
}

#about .r-progress .title-left h3::after {
  background-color: #0063aa;
}

#about .r-progress .block-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-flow: row-reverse;
  flex-flow: row-reverse;
  margin-left: calc((100% - 1110px) / 2);
  margin-right: 20px;
}

#about .r-progress .block-progress .slick-list {
  padding-bottom: 39px;
  padding-right: 20px;
  margin-right: -20px;
}

#about .r-progress .block-progress .item .inner {
  margin: 0 15px;
  border-radius: 25px;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(#1170ff),
      to(rgba(0, 148, 255, 0)));
  background: linear-gradient(to bottom, #1170ff, rgba(0, 148, 255, 0));
  padding: 4px;
  min-height: 354px;
}

#about .r-progress .block-progress .item .inner .content-box {
  border-radius: 25px;
  background: #f6f6f6;
  padding: 25px 29px 25px 36px;
  border-radius: 25px;
  min-height: 354px;
  height: 100%;
  color: #454545;
}

#about .r-progress .block-progress .item .year {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: -5px;
}

#about .r-progress .block-progress .item h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

#about .r-progress .block-progress .item p {
  font-size: 16px;
  line-height: 24px;
}

#about .r-progress .block-progress .item ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#about .r-progress .block-progress .item ul li .content {
  width: 57%;
}

#about .r-progress .block-progress .item ul li .content h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 3px;
}

#about .r-progress .block-progress .item ul li .content p {
  font-size: 16px;
  line-height: 24px;
  padding-right: 8px;
}

#about .r-progress .block-progress .item ul li .logo {
  width: 32%;
  margin-top: 5px;
}

#about .r-progress .block-progress .item ul li .logo.tcom-jp img {
  position: relative;
  top: 18px;
  left: -10px;
}

#about .r-progress .block-progress .item ul li .logo.eyefire img {
  position: relative;
  top: 13px;
  left: -10px;
}

#about .r-progress .block-progress .item ul li .logo img {
  margin-left: auto;
  margin-right: auto;
}

#about .r-progress .block-progress .slick-dots {
  background: url('/assets/images/about/coltree.png') no-repeat center;
  background-size: contain;
  margin-right: 36px;
  padding: 54px 0 76px;
  max-height: 651px;
}

#about .r-progress .block-progress .slick-dots li+li {
  margin-top: 18px;
}

#about .r-progress .block-progress .slick-dots li.slick-active button,
#about .r-progress .block-progress .slick-dots li:hover button {
  background-color: #fff;
  color: #0063aa;
}

#about .r-progress .block-progress .slick-dots li button {
  background: #2b5595;
  border-radius: 45px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  padding: 17.5px 22px;
  border: none;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

#about .r-member {
  padding-top: 97px;
  padding-bottom: 223px;
  background: url('assets/images/home/member-bg.png') no-repeat center;
}

#about .r-member .label {
  position: absolute;
  left: calc(100% + 51px);
  top: calc(50% + 155px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#about .r-member .row {
  margin-left: -8px;
  margin-right: -8px;
}

#about .r-member .row .col-4 {
  padding-left: 8px;
  padding-right: 8px;
}

#about .r-member .title-center {
  margin-bottom: 50px;
}

#about .r-member .image-logo {
  margin-top: 0;
  margin-bottom: 52px;
}

#about .r-member .box {
  border: 1px solid #e2e2e2;
  -webkit-box-shadow: 0px 11px 12px rgba(189, 189, 189, 0.25);
  box-shadow: 0px 11px 12px rgba(189, 189, 189, 0.25);
  border-radius: 15px;
  height: 100%;
  padding: 10px 15px;
  background-color: #fff;
}

#about .r-member .box .logo {
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 19px;
}

#about .r-member .box .logo img {
  width: auto;
}

#about .r-member .box ul li {
  font-size: 16px;
  color: #656565;
  position: relative;
  padding-left: 13px;
  line-height: 20px;
}

#about .r-member .box ul li::before {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #656565;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 8px;
}

#about .r-member .container {
  position: relative;
}

#about .r-member .image-background {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 150px;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  #about .r-member .image-background {
    top: 200px;
  }
}

#about .r-member .image-background img {
  max-width: 100%;
}

#about .r-achievement {
  padding: 53px 0 0;
  background: url('/assets/images/home/achievement-bg.webp') no-repeat center;
  background-size: cover;
}

#about .r-achievement .label {
  position: absolute;
  right: calc(100% + 52px);
  top: calc(50% + -25px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#about .r-achievement .title-left h3 {
  color: #fff;
  margin-bottom: 27px;
}

#about .r-achievement .title-left h3::after {
  background-color: #fff;
  border-radius: 8px;
}

#about .r-achievement .title-left .desc {
  color: #fff;
  line-height: 32px;
  padding-right: 110px;
}

#about .r-achievement .col-left .title-left {
  margin-top: 57px;
  margin-bottom: 0;
}

#about .r-achievement .col-left .image {
  margin-top: -16px;
  margin-left: -18px;
  margin-bottom: -9px;
}

#about .r-achievement .col-right .row {
  margin: 0 -12px;
}

#about .r-achievement .col-right .col-6 {
  margin-bottom: 25px;
  padding: 0 12px;
}

#about .r-achievement .col-right .box {
  -webkit-box-shadow: inset 0px 0px 7.66799px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 7.66799px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 24px 30px 15px;
  height: 226px;
  position: relative;
}

#about .r-achievement .col-right .box .number {
  color: #fff;
  font-size: 38px;
  font-weight: 900;
  line-height: 1;
  margin-top: 27px;
  margin-bottom: 6px;
}

#about .r-achievement .col-right .box h3 {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0;
}

#about .r-achievement .col-right .box.box-1 {
  background-color: #0063aa;
}

#about .r-achievement .col-right .box.box-1 .number {
  margin-top: 36px;
}

#about .r-achievement .col-right .box.box-2 {
  -webkit-transform: translateY(23px);
  transform: translateY(23px);
  background-color: #fde002;
}

#about .r-achievement .col-right .box.box-2::before {
  content: '';
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: -18px;
}

#about .r-achievement .col-right .box.box-2 .icon {
  margin-top: 18px;
}

#about .r-achievement .col-right .box.box-2 .number {
  color: #0063aa;
}

#about .r-achievement .col-right .box.box-2 h3 {
  color: #0063aa;
}

#about .r-achievement .col-right .box.box-3 {
  background-color: #eb2725;
}

#about .r-achievement .col-right .box.box-4 {
  background-color: #00be90;
  -webkit-transform: translateY(23px);
  transform: translateY(23px);
}

#about .r-feedback {
  text-align: center;
  padding: 145px 0 85px;
}

#about .r-feedback .title-center {
  margin-bottom: 52px;
}

#about .r-feedback .title-center h3 {
  text-transform: uppercase;
}

#about .r-feedback .title-center h3::after {
  display: none;
}

#about .r-feedback .owl-stage-outer {
  padding-top: 35px;
}

#about .r-feedback .item {
  width: 675px !important;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#about .r-feedback .item p {
  font-size: 20px;
  line-height: 23px;
  position: relative;
  margin-bottom: 66px;
  color: #121212;
}

#about .r-feedback .item p::before {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #0063aa;
  display: block;
  position: absolute;
  top: -28px;
  left: -29px;
  z-index: 1;
}

#about .r-feedback .item p::after {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #0063aa;
  display: block;
  position: absolute;
  bottom: -30px;
  right: -16px;
  z-index: 1;
}

#about .r-feedback .item .brand {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 6px;
}

#about .r-feedback .item .brand svg {
  margin-right: 8px;
}

#about .r-feedback .slick-dots {
  display: flex !important;
  position: absolute;
  bottom: 91px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 10;
}

#about .r-feedback .slick-dots button {
  width: 6px;
  height: 6px;
  background-color: #a7a7a7;
  border-radius: 34px;
  margin: 0 1.5px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

#about .r-feedback .slick-dots button.active {
  background-color: #0063aa;
  width: 29px;
}

#about .r-customer {
  padding: 66px 0 0;
  position: relative;
}

#about .r-customer .label {
  position: absolute;
  left: calc(100% + 50px);
  top: -50px;
  z-index: -1;
}

#about .r-customer .title-center {
  margin-bottom: 87px;
}

#about .r-customer .title-center p {
  font-size: 20px;
  color: #676767;
}

#about .r-customer .container-fluid {
  width: 1435px;
  max-width: 100%;
}

#about .r-customer .container-fluid .brands-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#about .r-customer .container-fluid .brands-list li {
  margin: 0 53px 64px 53px;
}

#about .r-customer .map {
  margin-top: -16px;
}

#about .r-moment {
  padding: 33px 0 191px;
  background: url('/assets/images/about/moment-bg.jpg') no-repeat center;
  background-size: cover;
}

#about .r-moment .container-fluid {
  padding-left: 32px;
  padding-right: 32px;
}

#about .r-moment .row {
  margin-left: -2px;
  margin-right: -2px;
}

#about .r-moment .row .column {
  padding-left: 2px;
  padding-right: 2px;
}

#about .r-moment .col-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#about .r-moment .title {
  padding: 130px 52px 0;
}

#about .r-moment .title h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  line-height: 1.3;
  margin-bottom: 35px;
}

#about .r-moment .title h3 span {
  color: #e2e2e2;
  margin-left: 5px;
}

#about .r-moment img {
  width: 100%;
  max-width: 100%;
}

#about .r-moment img+img {
  margin-top: 4px;
}

#about .r-office-tcom {
  margin-top: -35px;
  margin-bottom: 182px;
}

#about .r-office-tcom .circle {
  position: absolute;
  z-index: 0;
}

#about .r-office-tcom .circle.circle-1 {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background-color: #0063aa;
  top: 70px;
  right: 85px;
}

#about .r-office-tcom .circle.circle-2 {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #eb2725;
  bottom: 219px;
  right: 102px;
}

#about .r-office-tcom .circle.circle-3 {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #fde002;
  bottom: 101px;
  left: 120px;
}

#about .r-office-tcom .col-left {
  z-index: 1;
}

#about .r-office-tcom .col-left .inner {
  padding-left: 112px;
  padding-top: 141px;
}

#about .r-office-tcom .col-left p {
  font-size: 24px;
  color: #0063aa;
  margin-bottom: 1px;
}

#about .r-office-tcom .col-left h3 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #0063aa;
  margin-bottom: 46px;
  position: relative;
}

#about .r-office-tcom .col-left h3::before {
  content: '';
  width: 123px;
  height: 5px;
  background-color: #0063aa;
  border-radius: 43px;
  position: absolute;
  left: 0;
  bottom: -26px;
}

#about .r-office-tcom .col-left .btn-start {
  border: 2px solid #0063aa;
  -webkit-filter: drop-shadow(0px 4px 41px rgba(189, 189, 189, 0.25));
  filter: drop-shadow(0px 4px 41px rgba(189, 189, 189, 0.25));
  border-radius: 54px;
  padding: 18px 43px 18px 59px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0063aa;
  cursor: pointer;
  background-color: #fff;
}

#about .r-office-tcom .col-left .btn-start svg {
  margin-left: 20px;
}

#about .r-office-tcom .col-right:hover .tcom-image .slick-arrow {
  opacity: 1;
}

#about .r-office-tcom .col-right .tcom-image {
  margin-right: 352px;
  margin-left: 17px;
}

#about .r-office-tcom .col-right .tcom-image .slick-dots {
  position: absolute;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  margin-top: 44px;
}

#about .r-office-tcom .col-right .tcom-image .slick-dots li {
  margin-right: 16px;
}

#about .r-office-tcom .col-right .tcom-image .slick-dots li.slick-active button {
  width: 37px;
  background-color: #0063aa;
  border-radius: 27px;
}

#about .r-office-tcom .col-right .tcom-image .slick-dots button {
  width: 10px;
  height: 10px;
  background-color: #d9d9d9;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  font-size: 0;
  padding: 0;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

#about .r-office-tcom .col-right .tcom-image .slick-arrow {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  opacity: 0;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
  z-index: 10;
}

#about .r-office-tcom .col-right .tcom-image .slick-arrow.slick-disabled {
  opacity: 0 !important;
}

#about .r-office-tcom .col-right .tcom-image .slick-arrow.slick-next {
  right: -226px;
}

#about .r-office-tcom .col-right .tcom-image .slick-arrow.slick-prev {
  display: none !important;
  left: -141px;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

#about .r-office-tcom .col-right .tcom-image .slick-list {
  overflow: visible;
}

#about .r-office-tcom .col-right .tcom-image .slick-list::before {
  content: '';
  width: 10000%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  right: 100%;
  z-index: 10;
}

#about .r-office-tcom .col-right .tcom-image .slick-track {
  margin: 0 -20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#about .r-office-tcom .col-right .tcom-image .slick-slide {
  padding: 0 20px;
  -webkit-transform: scale(0.823);
  transform: scale(0.823);
  /* max-width: 994px; */
}

#about .r-office-tcom .col-right .tcom-image .slick-slide .item {
  border: none;
  outline: none;
}

#about .r-office-tcom .col-right .tcom-image .slick-active {
  -webkit-transform: scale(1);
  transform: scale(1) !important;
  margin-right: -82px;
}

#about .r-office-tcom .col-right .tcom-image .item img {
  width: 100%;
}

#about .r-office-tcom .col-right .tcom-image .item a {
  display: block;
  position: relative;
}

#about .r-office-tcom .col-right .tcom-image .item a:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#about .r-office-tcom .col-right .tcom-image .item a .content {
  position: absolute;
  bottom: 36px;
  left: 25px;
  width: calc(100% - 40px);
}

#about .r-office-tcom .col-right .tcom-image .item a .content h3 {
  color: #fff;
  font-weight: 900;
  font-size: 28px;
  line-height: 36px;
}

#about .r-office-tcom .col-right .tcom-image .image {
  border-radius: 15px;
  overflow: hidden;
}

#about .r-office-tcom .col-right .tcom-image .image img {
  width: 100%;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

#about .r-progress .block-progress .item ul li .logo.tsoft img {
  width: 105px;
}

@media (min-width: 1200px) {
  #about .r-office-tcom .col-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 27%;
    flex: 0 0 27%;
    max-width: 27%;
  }

  #about .r-office-tcom .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 73%;
    flex: 0 0 73%;
    max-width: 73%;
  }
}

@media (min-width: 1200px) {
  #about .r-moment .col-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30.27%;
    flex: 0 0 30.27%;
    max-width: 30.27%;
  }

  #about .r-moment .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 69.73%;
    flex: 0 0 69.73%;
    max-width: 69.73%;
  }

  #about .r-member .row .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  #about .r-achievement .col-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 56.32%;
    flex: 0 0 56.32%;
    max-width: 56.32%;
  }

  #about .r-achievement .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 43.68%;
    flex: 0 0 43.68%;
    max-width: 43.68%;
  }
}

@media (max-width: 1600px) {
  #about .r-office-tcom .col-left .inner {
    padding-left: 30px;
  }

  #about .r-office-tcom .col-left .btn-start {
    padding: 18px 40px 18px 40px;
  }

  #about .r-office-tcom .col-left .inner {
    padding-top: 0;
  }
}

@media (max-width: 1440px) {
  #about .r-moment .title {
    padding: 0;
  }

  #about .r-office-tcom .circle {
    display: none;
  }

  #about .r-office-tcom .col-right .tcom-image .item.slick-active {
    margin-right: -45px;
  }
}

@media (max-width: 1199px) {
  #about .r-office-tcom .col-left .btn-start {
    padding: 10px 30px 10px 30px;
    font-size: 24px;
  }

  #about .r-office-tcom .col-left .inner {
    padding-top: 0;
    padding-left: 0;
    margin-bottom: 40px;
  }

  #about .r-office-tcom .col-left p {
    font-size: 14px;
  }

  #about .r-office-tcom .col-left h3 {
    font-size: 28px;
    line-height: 38px;
  }

  #about .r-office-tcom .col-left h3::before {
    bottom: -7px;
  }

  #about .r-office-tcom .circle {
    display: none;
  }

  #about .r-feedback .item p::before {
    left: -4px;
  }

  #about .r-feedback .item p::after {
    right: -5px;
  }

  #about .r-moment .title h3 {
    font-size: 30px;
  }

  #about .r-progress .title-left h3 {
    font-size: 30px;
  }

  #about .r-progress .block-progress {
    margin-left: calc((100% - 930px) / 2);
    margin-right: 0;
  }

  #about .r-big-banner .col-left .block {
    width: 100%;
  }

  #about .r-big-banner {
    margin-top: 0;
    padding: 50px 0;
  }

  #about .r-big-banner .col-left h1 {
    font-size: 38px;
    line-height: 44.25px;
  }

  #about .r-big-banner .col-right .block-image {
    position: static;
    width: 100%;
  }

  #about .title-left h3,
  #about .title-center h3 {
    font-size: 30px;
  }

  #about .title-left .desc,
  #about .title-center .desc {
    font-size: 14px;
  }

  #about .r-member .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  #about .r-member .row .col-4 {
    margin-bottom: 16px;
  }

  #about .r-member {
    padding-bottom: 100px;
  }

  #about .r-achievement .col-right .box {
    padding: 15px;
    height: auto;
  }

  #about .r-achievement .col-right .box .number {
    font-size: 32px;
  }

  #about .r-achievement .col-right .box h3 {
    font-size: 16px;
  }

  #about .r-customer .container-fluid .brands-list li {
    margin: 0 30px 64px 30px;
  }
}

@media (max-width: 991px) {
  #about .r-progress .block-progress {
    margin-left: calc((100% - 690px) / 2);
  }
}

#about .r-big-banner .col-left .about_img-mobile {
  display: none;
}

@media (max-width: 767px) {
  #about .r-office-tcom .col-left .inner {
    text-align: center;
  }

  #about .r-office-tcom .col-left h3 {
    font-size: 18px;
    line-height: 28px;
  }

  #about .r-customer .title-center p {
    font-size: 14px;
  }

  #about .r-office-tcom .col-right:hover .tcom-image .slick-arrow {
    opacity: 1;
  }

  #about .r-office-tcom .col-left h3::before {
    width: 45px;
    height: 1px;
    bottom: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  #footer .main .customRowFooter2 {
    display: none;
  }

  #footer .main .customRowFooter2-MB {
    display: block !important;
  }

  #about .r-office-tcom .col-left .btn-start {
    padding: 0px 20px 0px 20px;
    font-size: 14px;
  }

  #about .r-office-tcom .col-right .tcom-image {
    margin-right: 100px;
  }

  .container {
    padding-right: 24px;
    padding-left: 24px;
  }

  #about .r-office-tcom {
    margin-top: 0;
    margin-bottom: 100px;
  }

  #about .r-office-tcom .col-left .btn-start svg {
    width: 20px;
  }

  #about .r-office-tcom .col-right .tcom-image .slick-dots {
    margin-top: 20px;
  }

  #about .r-moment {
    padding: 0px 0 19px;
  }

  #about .r-moment .container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }

  #about .r-moment .title h3 {
    font-size: 14px;
    text-align: center;
    margin-bottom: 6px;
  }

  #about .r-moment .title h3 span {
    color: #0063aa;
    margin-left: 0;
  }

  #about .r-moment .col-left .title img {
    display: none;
  }

  #about .r-moment .col-left img {
    margin-bottom: 4px;
  }

  #about .r-feedback {
    padding: 26px 0 6px;
  }

  #about .r-feedback .owl-dots {
    bottom: 38px;
  }

  #about .r-feedback .item .brand svg {
    margin-right: 3px;
    width: 9px;
  }

  #about .r-feedback .item .brand {
    font-size: 9px;
    margin-top: -4px;
  }

  #about .r-feedback .item .author {
    font-size: 8px;
  }

  #about .r-feedback .owl-dots button.active {
    width: 16px;
  }

  #about .r-feedback .owl-dots button {
    width: 4px;
    height: 4px;
  }

  #about .r-feedback .item p::before {
    left: 80px;
    top: 76px;
    width: 7px;
    height: 7px;
  }

  #about .r-feedback .item p::after {
    right: 75px;
    bottom: -26px;
    width: 4px;
    height: 4px;
  }

  #about .r-feedback .owl-stage-outer {
    padding-top: 0;
  }

  #about .r-feedback .title-center {
    margin-bottom: 0;
  }

  #about .r-feedback .item p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
    padding: 0 15px;
  }

  #about .r-feedback .title-center h3 {
    font-size: 14px;
    margin-bottom: 4px;
  }

  #about .r-progress .block-progress .item ul li .logo.tsoft img {
    width: 75px;
  }

  #about .r-progress .block-progress .item ul li .logo.rice-wallet img {
    width: 29px;
  }

  #about .r-progress .block-progress .slick-dots {
    margin-right: 36px;
    padding: 41px 0 58px;
  }

  #about .r-progress .title-left {
    margin-bottom: 43px;
  }

  #about .r-progress .block-progress .slick-dots li+li {
    margin-top: 13px;
  }

  #about .r-progress .block-progress .item ul li .content p {
    font-size: 8px;
    line-height: 9px;
    padding-right: 8px;
    margin-bottom: 5px;
  }

  #about .r-progress .block-progress .item ul li .content h5 {
    font-size: 10px;
    margin-bottom: 6px;
  }

  #about .r-progress .block-progress .item h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  #about .r-progress .block-progress .item .year {
    font-size: 8px;
    margin-bottom: 1px;
  }

  #about .r-progress .block-progress .item .inner {
    min-height: 220px;
    margin: 0;
    padding: 2px;
    border-radius: 11px;
  }

  #about .r-progress .block-progress .item .inner .content-box {
    padding: 25px 29px 25px 36px;
    min-height: 220px;
    border-radius: 11px;
  }

  #about .r-progress .block-progress .item .inner .box-content {
    padding: 12px 13px 25px 29px;
    margin: 0;
    margin-bottom: 10px;
    border-width: 2px;
    border-radius: 11px;
    min-height: auto;
  }

  #about .r-progress .block-progress .item ul li .logo.tcom-jp img {
    width: 72px;
  }

  #about .r-progress .block-progress .item ul li .logo.eyefire img {
    width: 72px;
  }

  #about .r-progress .block-progress .slick-dots li button {
    font-size: 16px;
    padding: 10px 21px;
  }

  #about .r-progress .block-progress .slick-list {
    padding-right: 0;
    margin-right: 0;
    padding-bottom: 0;
  }

  #about .r-progress .block-progress .item p {
    font-size: 10px;
    line-height: 18px;
  }

  #about .r-progress .block-progress {
    margin-left: 0;
    padding-left: 24px;
    padding-right: 24px;
  }

  #about .r-progress {
    padding: 52px 0 54px;
  }

  #about .r-progress .title-left h3 {
    font-size: 18px;
  }

  #about .r-big-banner {
    padding: 31px 0 3px;
  }

  #about .r-big-banner .col-left h1::after {
    bottom: -18px;
    width: 57px;
    height: 2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  #about .r-big-banner .col-left .block h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  #about .r-big-banner .col-left .block p {
    line-height: 24px;
    font-size: 14px;
  }

  #about .r-big-banner .col-left .block p br {
    display: none;
  }

  #about .r-big-banner .col-left .block {
    margin-bottom: 25px;
    width: 100%;
  }

  #about .r-big-banner .col-right .block-image .block .image .circle {
    top: 3px;
    left: 3px;
  }

  #about .r-big-banner .col-right .block-image .block.block-4 .image .circle {
    top: 2px;
    left: 2px;
  }

  #about .r-big-banner .col-right .block-image .block.block-1 {
    width: 146px;
    top: -33px;
    right: 20px;
  }

  #about .r-big-banner .col-right .block-image .block.block-2 {
    width: 103px;
    left: 64px;
    bottom: 90px;
  }

  #about .r-big-banner .col-right .block-image .block.block-3 {
    width: 66px;
    right: 46px;
    bottom: 59px;
  }

  #about .r-big-banner .col-right .block-image .block.block-4 {
    width: 50px;
    bottom: 33px;
    left: 163px;
  }

  #about .r-big-banner .col-right .block-image {
    height: 250px;
    display: none;
  }

  #about .r-big-banner .col-left .about_img-mobile {
    width: 100%;
    display: block;
  }

  #about .r-big-banner .col-left .about_img-mobile img {
    width: 100%;
    margin-bottom: 25px;
  }

  #about .r-big-banner .col-left h1 {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 45px;
  }

  #about .r-big-banner .col-right .block-image .block .image img {
    width: 100%;
  }

  #about .title-left {
    margin-bottom: 30px;
  }

  #about .title-left h3,
  #about .title-center h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  #about .title-left .desc,
  #about .title-center .desc {
    font-size: 14px;
    line-height: 24px;
    color: #676767;
    padding-right: 40px;
  }

  #about .title-left .desc br,
  #about .title-center .desc br {
    display: none;
  }

  #about .title-left h3::after {
    bottom: -4px;
    width: 14px;
    height: 1px;
  }

  #about .read-more {
    position: static;
    font-size: 14px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 32px;
  }

  #about .read-more svg {
    margin-left: 15px;
  }

  #about .read-more svg circle {
    fill: transparent;
  }

  #about .read-more svg path {
    fill: #0063aa;
  }

  #about .title-center h3 {
    line-height: 24px;
  }

  #about .title-center h3::after {
    bottom: -9px;
    width: 14px;
    height: 1px;
  }

  #about .r-member .title-center {
    margin-bottom: 31px;
  }

  #about .r-member .title-center h3 {
    width: 205px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 26px;
    font-size: 18px;
  }

  #about .title-center .desc {
    padding-right: 10px;
    padding-left: 10px;
  }

  #about .r-member {
    padding-top: 23px;
    padding-bottom: 35px;
  }

  #about .r-member .image-logo {
    margin-top: 0;
    margin-bottom: 22px;
  }

  #about .r-member .image-logo img {
    width: 126.5px;
  }

  #about .r-member .box .logo {
    height: 33px;
    margin-bottom: 7px;
  }

  #about .r-member .box .logo.tsoft img {
    width: 59px;
  }

  #about .r-member .box .logo.t-japan img {
    width: 73px;
  }

  #about .r-member .box .logo.eyefire img {
    width: 77px;
  }

  #about .r-member .box .logo.sky-live img {
    width: 43px;
  }

  #about .r-member .box .logo.blockchain img {
    width: 75px;
  }

  #about .r-member .box ul li {
    font-size: 8px;
    padding-left: 5px;
    line-height: 16px;
  }

  #about .r-member .row {
    margin-left: -4px;
    margin-right: -4px;
  }

  #about .r-member .row .col-4 {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 7px;
  }

  #about .r-member .box ul li::before {
    width: 2px;
    height: 2px;
  }

  #about .r-member .box {
    padding: 6px 7px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #about .r-achievement {
    padding-top: 31px;
    padding-bottom: 26px;
  }

  #about .r-achievement .col-left .title-left {
    margin-top: 0;
    text-align: center;
  }

  #about .r-achievement .col-left .title-left h3 {
    margin-bottom: 29px;
  }

  #about .r-achievement .col-left .title-left h3::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -12px;
  }

  #about .r-achievement .title-left .desc {
    line-height: 24px;
    padding: 0 13px;
    margin-bottom: 32px;
  }

  #about .r-achievement .col-left .image {
    display: none;
  }

  #about .r-achievement .col-right .box.box-2,
  #about .r-achievement .col-right .box.box-4 {
    -webkit-transform: none;
    transform: none;
  }

  #about .r-achievement .col-right .box.box-2::before,
  #about .r-achievement .col-right .box.box-4::before {
    display: none;
  }

  #about .r-achievement .col-right .box.box-1 img {
    width: 40px;
  }

  #about .r-achievement .col-right .box.box-2 img {
    width: 54px;
  }

  #about .r-achievement .col-right .box.box-3 img {
    width: 51px;
  }

  #about .r-achievement .col-right .box.box-4 img {
    width: 48px;
  }

  #about .r-achievement .col-right .row {
    margin: 0 -8px;
  }

  #about .r-achievement .col-right .col-6 {
    margin-bottom: 17px;
    padding: 0 8px;
  }

  #about .r-achievement .col-right .box {
    padding: 16px 19px 12px;
  }

  #about .r-achievement .col-right .box .number {
    margin-top: 17px;
  }

  #about .r-achievement .col-right .box.box-1 .number {
    margin-top: 21px;
    margin-bottom: 4px;
  }

  #about .r-achievement .col-right .box.box-2 .icon {
    margin-top: 12px;
  }

  #about .r-customer .title-center .desc {
    font-size: 14px;
    line-height: 24px;
  }

  #about .r-customer {
    padding: 33px 0 0;
  }

  #about .r-customer .title-center h3::after {
    bottom: -5px;
  }

  #about .r-customer .title-center h3 {
    margin-bottom: 22px;
  }

  #about .r-customer .container-fluid .brands-list li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin: 8px 0px 11px 0px;
    text-align: center;
  }

  #about .r-customer .container-fluid .brands-list li img {
    width: 50%;
  }

  #about .r-customer .container-fluid .brands-list li:nth-child(13) img {
    width: 100%;
  }

  #about .r-customer .title-center {
    margin-bottom: 31px;
  }

  #about .r-customer .map {
    margin-top: -4px;
  }

  #about .r-customer .map img {
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

#project-details .r-treasure-slider {
  margin-top: 36px;
  margin-bottom: 69px;
  position: relative;
}

#project-details .main-content .handleBack {
  margin-top: 50px;
}

#project-details .main-content .handleBack a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #3e3c3c;
  /* padding: 9px; */
}

#project-details .main-content .handleBack a:hover svg {
  transition: 0.4s all;
  margin-left: -10px;
}

#project-details .main-content .handleBack a svg {
  margin-right: 0px;
  position: relative;
  right: 7px;
}

#project-details .r-treasure-slider .slick-arrow {
  display: none !important;
}

#project-details .r-treasure-slider .slick-track {
  margin: 0 -23px;
}

#project-details .r-treasure-slider .slick-list::before {
  content: '';
  width: 1000%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: -4px;
  right: 100%;
  z-index: 10;
}

#project-details .r-treasure-slider .slick-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 18px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(255, 255, 255, 0)),
      color-stop(0.01%, rgba(255, 255, 255, 0)),
      color-stop(0.02%, rgba(255, 255, 255, 0)),
      color-stop(3.12%, rgba(255, 255, 255, 0)),
      color-stop(28.3%, rgba(255, 255, 255, 0)),
      color-stop(100%, #ffffff),
      to(#ffffff));
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 0.01%,
      rgba(255, 255, 255, 0) 0.02%,
      rgba(255, 255, 255, 0) 3.12%,
      rgba(255, 255, 255, 0) 28.3%,
      #ffffff 100%,
      #ffffff 100%);
  z-index: 10;
}

#project-details .r-treasure-slider .slick-slide.slick-active::after {
  display: none;
  max-height: 465px;
  object-fit: contain;
}

#project-details .r-treasure-slider .slick-slide {
  padding: 0 23px;
  height: 465px;
  /* width: 815px; */
  display: flex;
  align-items: center;
  object-fit: contain;
  position: relative;
}

#project-details .r-treasure-slider .slick-slide img {
  max-width: 80%;
  object-fit: cover;
}

#project-details .r-treasure-slider .slick-active img {
  max-width: 100%;
}

#project-details .r-treasure-slider .slick-slide img {
  width: 100%;
  object-fit: cover;
  transition: 0.4s all;
}

#project-details .r-treasure-slider .slick-slider {
  margin-right: 285px;
}

#project-details .r-treasure-slider .slick-slide div:focus-visible {
  outline: none;
}

#project-details .r-treasure-slider .slick-slide div {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
}

#project-details .r-treasure-slider .slider-project_detail .slick-slide div {
  display: block !important;
}

#project-details .r-treasure-slider .slick-list {
  overflow: visible;
}

#project-details .r-treasure-slider .treasure-carousel {
  padding-right: 283px;
}

#project-details .r-treasure-slider .treasure-carousel::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #0063aa;
  position: absolute;
  left: 0;
  bottom: 17px;
  z-index: 1;
}

#project-details .r-treasure-slider .owl-stage-outer {
  overflow: visible;
}

#project-details .r-treasure-slider .owl-stage-outer::before {
  content: '';
  width: 1000%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 100%;
  z-index: 10;
}

#project-details .r-treasure-slider .item-2 a {
  display: block;
  position: relative;
}

#project-details .r-treasure-slider .item-2 a::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 18px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(255, 255, 255, 0)),
      color-stop(0.01%, rgba(255, 255, 255, 0)),
      color-stop(0.02%, rgba(255, 255, 255, 0)),
      color-stop(3.12%, rgba(255, 255, 255, 0)),
      color-stop(28.3%, rgba(255, 255, 255, 0)),
      color-stop(100%, #ffffff),
      to(#ffffff));
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 0.01%,
      rgba(255, 255, 255, 0) 0.02%,
      rgba(255, 255, 255, 0) 3.12%,
      rgba(255, 255, 255, 0) 28.3%,
      #ffffff 100%,
      #ffffff 100%);
  z-index: 10;
}

#project-details .r-treasure-slider .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#project-details .r-treasure-slider .owl-nav {
  display: inline-block;
  margin-top: 18px;
  position: relative;
  background-color: #fff;
  padding-right: 9px;
  z-index: 1;
}

#project-details .r-treasure-slider .owl-nav button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #0063aa;
  background-color: transparent;
  border-radius: 50%;
  margin-right: 24px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

@media (min-width: 767.5px) {
  #project-details .r-treasure-slider .owl-nav button:hover {
    background-color: #0063aa;
  }

  #project-details .r-treasure-slider .owl-nav button:hover path {
    fill: #fff;
  }
}

#project-details .r-treasure-slider #counter {
  position: absolute;
  right: 7px;
  bottom: -5px;
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #0063aa;
  z-index: 1;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 30px;
}

#project-details .r-treatsure {
  margin-bottom: 67px;
}

#project-details .r-treatsure .col-left h3 {
  font-size: 40px;
  font-weight: 900;
  color: #121212;
  margin-bottom: 12px;
}

#project-details .r-treatsure .col-left p {
  font-size: 16px;
  color: #434343;
  padding-right: 65px;
}

#project-details .r-treatsure .col-right .inner {
  padding-top: 63px;
  margin-left: -4px;
}

#project-details .r-treatsure .col-right .block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 26px;
}

#project-details .r-treatsure .col-right .block.field {
  margin-top: -10px;
  margin-bottom: 16px;
}

#project-details .r-treatsure .col-right .block.field .content h5 {
  margin-bottom: 8px;
}

#project-details .r-treatsure .col-right .block .icon {
  margin-right: 16px;
  margin-top: -4px;
}

#project-details .r-treatsure .col-right .block .content h5 {
  margin-bottom: 16px;
}

#project-details .r-treatsure .col-right .block .content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#project-details .r-treatsure .col-right .block .content ul li {
  margin-right: 8px;
  margin-bottom: 8px;
}

#project-details .r-treatsure .col-right .block .content ul li.active a,
#project-details .r-treatsure .col-right .block .content ul li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#project-details .r-treatsure .col-right .block .content ul li a {
  font-size: 16px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 5px 24px 3px;
  display: inline-block;
}

@media (max-width: 767px) {
  #project-details .r-treatsure .col-right .block .content ul li a {
    font-size: 14px;
  }
}

#project-details .r-treatsure .col-right .block .content p {
  /* margin-bottom: 0; */
  margin-top: -11px;
}

.custom-block {
  margin-bottom: 1rem;
}

.customTextP p {
  font-size: 16px;
}

.custom-block .content p {
  font-size: 16px;
}

@media (max-width: 767px) {
  .custom-block .content p {
    font-size: 14px;
  }
}

#custom-project-details .r-treatsure .col-right .block .content p {
  margin-bottom: 1rem;
}

@media (max-width: 1199px) {
  .r-treasure-slider .item {
    width: 100% !important;
  }

  #project-details .r-treasure-slider .treasure-carousel {
    padding-right: 0;
  }

  #project-details .r-treasure-slider .owl-stage-outer {
    overflow: hidden;
  }

  #project-details .r-treasure-slider .item-2 a::after {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  #project-details .r-treasure-slider {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #project-details .main-content .handleBack a {
    /* padding: 5px 12px; */
    /* font-size: 14px; */
  }

  #project-details .r-treasure-slider #counter {
    right: 0;
    bottom: -8px;
  }

  #project-details .r-treatsure .col-left h3 {
    font-size: 25px;
  }

  #project-details .r-treatsure .col-left p {
    padding-right: 0;
    font-size: 14px;
  }

  #project-details .r-treatsure .col-right .block .content h5 {
    font-size: 16px;
  }
}

#offshore #header .header-desktop {
  position: relative;
  z-index: 2;
}

#offshore #header .col-right .megamenu ul li a {
  color: #fff;
}

#offshore #header .col-right .language button span {
  color: #fff;
}

#offshore #header .col-right .language button span+svg path {
  fill: #fff;
}

#offshore .title-left {
  margin-bottom: 58px;
}

#offshore .title-left h3 {
  font-size: 48px;
  font-weight: 900;
  color: #0063aa;
  position: relative;
  margin-bottom: 35px;
  /* text-transform: uppercase; */
}

#offshore .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#offshore .title-left .desc {
  font-size: 18px;
  color: #686868;
}

#offshore .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#offshore .title-center h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
  text-transform: uppercase;
}

#offshore .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#offshore .title-center .desc {
  font-size: 20px;
  color: #686868;
}

#offshore .r-big-banner {
  margin-top: -75px;
  padding: 154px 0 121px;
  background: url('/assets/images/offshore/big-banner-bg.webp') no-repeat center;
  background-size: cover;
}

#offshore .r-big-banner .title-left {
  margin-bottom: 79px;
}

#offshore .r-big-banner .title-left h3 {
  color: #fff;
  margin-bottom: 47px;
}

#offshore .r-big-banner .title-left h3::after {
  bottom: -18px;
}

#offshore .r-big-banner .title-left .desc {
  color: #fff;
  width: 544px;
  max-width: 100%;
}

#offshore .r-big-banner .box .row {
  margin: 0 -15px;
}

#offshore .r-big-banner .box .row>div {
  padding-left: 15px;
  padding-right: 15px;
}

#offshore .r-big-banner .box .inner {
  height: 100%;
  padding: 27px 33px 69px;
  border: 1px solid #ffffff;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.2)),
      to(rgba(0, 0, 0, 0.2))),
    rgba(255, 255, 255, 0.45);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(255, 255, 255, 0.45);
  border-radius: 8px;
}

#offshore .r-big-banner .box .inner .icon {
  margin-bottom: 16px;
  height: 82px;
}

#offshore .r-big-banner .box .inner h3 {
  color: #fff;
  font-size: 32px;
  line-height: 48px;
  font-weight: 900;
  padding-right: 61px;
}

#offshore .r-service {
  padding: 134px 0 45px;
  position: relative;
}

#offshore .r-service::before {
  content: url('/assets/images/offshore/service-bg.png');
  position: absolute;
  left: -8px;
  top: 92px;
  z-index: -1;
}

#offshore .r-service .row {
  margin: 0 -11px;
}

#offshore .r-service .row>div {
  padding: 0 11px;
  margin-bottom: 46px;
}

#offshore .r-service .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid transparent;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

#offshore .r-service .box:hover {
  border-color: #d9d9d9;
  -webkit-box-shadow: 0px 4px 17px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 17px 4px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
}

#offshore .r-service .box:hover .icon {
  border: none;
}

#offshore .r-service .box:hover .arrow {
  opacity: 1;
}

#offshore .r-service .box .icon {
  margin-right: 15px;
  width: 94px;
  height: 94px;
  border: 1px solid #d9d9d9;
  border-radius: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#offshore .r-service .box h3 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
  padding-top: 2px;
}

#offshore .r-service .box .arrow {
  margin-left: auto;
  margin-right: 12px;
  opacity: 0;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

#offshore .r-technology {
  padding: 69px 0;
}

#offshore .r-technology .title-left {
  margin-bottom: 53px;
}

#offshore .r-technology .col-12 {
  margin-bottom: 19px;
}

#offshore .r-technology .box {
  padding: 18px 20px 33px;
  border: 1px solid #c7c7c7;
  border-radius: 25px;
  height: 100%;
  position: relative;
}

#offshore .r-technology .box::after {
  position: absolute;
  bottom: -4px;
  right: 19px;
  font-weight: 900;
  font-size: 107px;
  letter-spacing: -0.02em;
  color: rgba(196, 196, 196, 0.15);
  line-height: 1;
}

@media screen and (max-width: 991px) {
  #offshore .r-technology .box::after {
    font-size: 65px;
  }
}

#offshore .r-technology .box.backend::after {
  content: 'Backend';
}

#offshore .r-technology .box.frontend::after {
  content: 'Frontend';
}

#offshore .r-technology .box.mobility::after {
  content: 'Mobility';
}

#offshore .r-technology .box.blockchain::after {
  content: 'Blockchain';
}

#offshore .r-technology .box.database::after {
  content: 'DataBase';
}

#offshore .r-technology .box.ai::after {
  content: 'A.I';
}

#offshore .r-technology .box.ai h3 {
  margin-bottom: 5px;
}

#offshore .r-technology .box.ai ul li {
  margin-bottom: -9px;
}

#offshore .r-technology .box h3 {
  font-size: 32px;
  font-weight: 700;
  color: #0063aa;
  margin-bottom: 17px;
  letter-spacing: -0.02em;
}

#offshore .r-technology .box ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#offshore .r-technology .box ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  text-align: center;
  margin-bottom: 5px;
}

#offshore .r-technology .box ul li .icon {
  height: 61px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 7px;
}

#offshore .r-technology .box ul li .name {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  line-height: 19px;
}

#offshore .r-cooperate {
  padding: 48px 0 145px;
}

#offshore .r-cooperate .title-left {
  margin-bottom: 76px;
}

#offshore .r-cooperate .step.step-1 {
  padding-bottom: 133px;
}

#offshore .r-cooperate .step.step-1 .inner .number {
  margin-left: 13px;
  margin-top: 59px;
}

#offshore .r-cooperate .step.step-1 .inner .number::after {
  content: '';
  width: 1px;
  height: 440px;
  background-color: #0063aa;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}

#offshore .r-cooperate .step.step-1 .inner .content {
  width: 358px;
  max-width: 100%;
  margin-top: 47px;
  margin-left: 10px;
}

#offshore .r-cooperate .step.step-2 .inner {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

#offshore .r-cooperate .step.step-2 .inner .number {
  margin-left: 13px;
}

#offshore .r-cooperate .step.step-2 .inner .number::after {
  content: '';
  width: 1px;
  height: 290px;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(#0063aa),
      to(rgba(0, 99, 170, 0)));
  background-image: linear-gradient(to bottom, #0063aa, rgba(0, 99, 170, 0));
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}

#offshore .r-cooperate .step.step-2 .inner .content {
  margin-top: -8px;
  margin-left: 39px;
  margin-bottom: 39px;
}

#offshore .r-cooperate .step.step-2 .inner .content p {
  width: 358px;
  max-width: 100%;
}

#offshore .r-cooperate .step.step-2 .inner .image {
  margin-left: 93px;
}

#offshore .r-cooperate .step.step-2 .inner .image .box {
  background: #ededed;
  border-radius: 16px;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#offshore .r-cooperate .step.step-2 .inner .image .box .top {
  padding: 23px 32px 0;
  margin-bottom: -7px;
}

#offshore .r-cooperate .step.step-2 .inner .image .box .img_customer_tcom {
  width: 78%;
  margin-left: 7%;
  margin-bottom: 8%;
  margin-top: 5%;
}

@media (max-width:991px) {
  #offshore .r-cooperate .step.step-2 .inner .image .box .img_customer_tcom {
    margin-left: 11%;
  }
}

#offshore .r-cooperate .step.step-2 .inner .image .box .top h5 {
  font-size: 24px;
  font-weight: 700;
  color: #494949;
  margin-bottom: 6px;
}

#offshore .r-cooperate .step.step-2 .inner .image .box .top p {
  font-size: 15px;
  color: #494949;
}

#offshore .r-cooperate .step .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#offshore .r-cooperate .step .inner .number {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #0063aa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  position: relative;
  z-index: 2;
}

#offshore .r-cooperate .step .inner .content h3 {
  font-size: 48px;
  font-weight: 700;
  color: #121212;
  margin-bottom: 16px;
}

#offshore .r-cooperate .step .inner .content p {
  font-size: 18px;
  line-height: 32px;
  color: #434343;
}

#offshore .r-reason {
  background: url('/assets/images/offshore/reason-bg.webp') no-repeat center;
  background-size: cover;
  color: #fff;
  padding: 152px 0 53px;
  position: relative;
}

#offshore .r-reason .label {
  position: absolute;
  right: calc(100% + 88px);
  top: calc(50% + -13px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#offshore .r-reason h1 {
  font-size: 64px;
  font-weight: 900;
  margin-bottom: 62px;
}

#offshore .r-reason .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 114px;
}

#offshore .r-reason .box .number {
  margin-right: 19px;
  color: transparent;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: #fff;
  font-size: 76px;
  font-weight: 900;
  line-height: 1;
}

#offshore .r-reason .box h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0;
}

#offshore .r-step {
  padding: 79px 0 227px;
  position: relative;
}

#offshore .r-step::before {
  content: url('/assets/images/offshore/step-bg.png');
  position: absolute;
  bottom: -14px;
  left: -8px;
  z-index: 2;
}

#offshore .r-step .title-center {
  margin-bottom: 125px;
}

#offshore .r-step .title-center .desc {
  font-size: 18px;
  color: #494949;
}

#offshore .r-step .slick-slider {
  padding-right: 65px;
}

#offshore .r-step .slick-slider::before {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  width: 10000%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
}

#offshore .r-step .slick-slider .owl-stage-outer {
  overflow: visible;
}

#offshore .r-step .slick-slider .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#offshore .r-step .slick-slider .item {
  padding: 31px 15px 12px 24px;
  color: #fff;
  background: #0063aa;
  -webkit-box-shadow: 0px 4px 9px rgba(189, 189, 189, 0.45);
  box-shadow: 0px 4px 9px rgba(189, 189, 189, 0.45);
  border-radius: 10px;
  height: 100%;
}

#offshore .r-step .slick-slider:hover .slick-arrow {
  opacity: 1;
}

#offshore .r-step .slick-arrow {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  opacity: 0;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
  z-index: 10;
}

#offshore .r-step .slick-arrow.slick-disabled {
  opacity: 0 !important;
}

#offshore .r-step .slick-arrow.slick-next {
  right: -226px;
}

#offshore .r-step .slick-arrow.slick-prev {
  left: -141px;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

#offshore .r-step .slick-slider .item .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 55px;
}

#offshore .r-step .slick-slider .item .top .number {
  font-size: 48px;
  font-weight: 900;
  line-height: 1;
}

#offshore .r-step .slick-slider .item .top .text {
  font-size: 48px;
  font-weight: 900;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  text-transform: uppercase;
  line-height: 1;
}

#offshore .r-step .slick-slider .item h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 14px;
}

#offshore .r-step .slick-slider .item p {
  font-size: 14px;
  line-height: 16px;
  color: #e2e2e2;
}

@media (max-width: 1800px) {
  #offshore .r-step::before {
    display: none;
  }

  #offshore .r-service::before {
    display: none;
  }
}

@media (max-width: 1199px) {
  #offshore .r-cooperate .step.step-1 .inner {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  #offshore .r-cooperate .step.step-1 .inner .content {
    margin-left: 39px;
    margin-bottom: 39px;
  }

  #offshore .r-cooperate .step.step-1 .inner .image {
    margin-left: 93px;
  }

  #offshore .r-cooperate .step.step-1 .inner .number::after,
  #offshore .r-cooperate .step.step-2 .inner .number::after {
    display: none;
  }
}

@media (max-width: 991px) {

  #offshore .title-left h3,
  #offshore .title-center h3 {
    font-size: 30px;
  }

  #offshore .r-big-banner .box .inner h3 {
    font-size: 20px;
    line-height: 30px;
    padding-right: 0;
  }

  #offshore .r-service .box .icon svg {
    width: 30px;
  }

  #offshore .r-technology .box h3 {
    font-size: 20px;
  }

  #offshore .r-technology .box ul li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  #offshore .r-cooperate .step .inner .content h3 {
    font-size: 30px;
  }

  #offshore .r-reason h1 {
    font-size: 40px;
  }

  #offshore .r-reason .box h3 {
    font-size: 20px;
  }

  #offshore .r-reason .box .number {
    font-size: 50px;
  }

  #offshore .r-reason .box {
    margin-bottom: 50px;
  }

  #offshore .r-step .slick-slider .item .top .text,
  #offshore .r-step .slick-slider .item .top .number {
    font-size: 30px;
  }

  #offshore .r-step {
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {

  #offshore .title-left h3,
  #offshore .title-center h3 {
    font-size: 18px;
  }

  #offshore .title-left .desc,
  #offshore .title-center .desc {
    font-size: 14px;
  }

  #offshore .r-big-banner .title-left {
    margin-bottom: 50px;
  }

  #offshore .r-big-banner .box .inner {
    padding: 20px;
  }

  #offshore .r-big-banner .box .row>div {
    margin-bottom: 20px;
  }

  #offshore .r-big-banner .box .inner .icon {
    height: auto;
  }

  #offshore .r-big-banner .box .inner .icon svg {
    width: 50px;
  }

  #offshore .r-big-banner .box .inner h3 {
    font-size: 14px;
    line-height: 24px;
  }

  #offshore .r-big-banner {
    padding: 110px 0 30px;
  }

  #offshore .r-service {
    padding: 50px 0 45px;
  }

  #offshore .r-service .row>div {
    margin-bottom: 15px;
  }

  #offshore .r-service .box h3 {
    font-size: 14px;
  }

  #offshore .r-technology {
    padding: 30px 0;
  }

  #offshore .r-cooperate {
    padding: 20px 0 30px;
  }

  #offshore .r-cooperate .title-left {
    margin-bottom: 20px;
  }

  #offshore .r-cooperate .step .inner .number {
    font-size: 16px;
    width: 30px;
    height: 30px;
  }

  #offshore .r-cooperate .step.step-1 .inner .number {
    margin-left: 0;
    margin-top: 0;
  }

  #offshore .r-cooperate .step.step-1 .inner .content {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 20px;
  }

  #offshore .r-cooperate .step .inner .content p {
    font-size: 14px;
    line-height: 24px;
  }

  #offshore .r-cooperate .step .inner .content h3 {
    font-size: 18px;
  }

  #offshore .r-cooperate .step.step-1 .inner .image {
    margin-left: 0;
  }

  #offshore .r-cooperate .step.step-1 {
    padding-bottom: 40px;
  }

  #offshore .r-cooperate .step.step-2 .inner .number {
    margin-left: 0;
  }

  #offshore .r-cooperate .step.step-2 .inner .content {
    margin-top: 20px;
    margin-left: 0;
    margin-bottom: 0;
  }

  #offshore .r-cooperate .step.step-2 .inner .image {
    margin-left: 0;
  }

  #offshore .r-cooperate .step.step-2 .inner .image .box .top h5 {
    font-size: 16px;
  }

  #offshore .r-cooperate .step.step-2 .inner .image .box .top p {
    font-size: 14px;
  }

  #offshore .r-cooperate .step.step-2 .inner .image .col-12 {
    margin-bottom: 15px;
  }

  #offshore .r-reason h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  #offshore .r-reason .box h3 {
    font-size: 14px;
    line-height: 24px;
  }

  #offshore .r-reason .box .number {
    font-size: 30px;
    margin-right: 10px;
  }

  #offshore .r-reason .box {
    margin-bottom: 20px;
  }

  #offshore .r-reason .row>div:nth-child(1) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  #offshore .r-reason .row>div:nth-child(2) {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  #offshore .r-reason .row>div:nth-child(3) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  #offshore .r-reason .row>div:nth-child(4) {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  #offshore .r-reason .row>div:nth-child(5) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  #offshore .r-reason .row>div:nth-child(6) {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  #offshore .r-reason {
    padding: 60px 0 40px;
  }

  #offshore .r-step .slick-slider {
    padding-right: 0;
  }

  #offshore .r-step {
    padding: 50px 0 227px;
  }

  #offshore .r-step .title-center {
    margin-bottom: 40px;
  }

  #offshore .r-step .title-center .desc {
    font-size: 14px;
  }

  #offshore .r-step .slick-slider .item h3 {
    font-size: 18px;
    line-height: 28px;
  }

  #offshore .r-step {
    padding-bottom: 40px;
  }

  #offshore .r-step .slick-slider .item .top {
    margin-bottom: 15px;
  }

  #offshore .r-step .slick-slider .item .top .text,
  #offshore .r-step .slick-slider .item .top .number {
    font-size: 18px;
  }
}

#project .row {
  margin-left: -15px;
  margin-right: -15px;
}

#project .row>div {
  padding-left: 15px;
  padding-right: 15px;
}

#project .r-top {
  margin-top: 66px;
}

#project .r-top .title-left h3 {
  font-size: 48px;
  font-weight: 900;
  color: #0063aa;
  position: relative;
  margin-bottom: 40px;
}

#project .r-top .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#project .r-top .title-left .desc {
  font-size: 18px;
  color: #505050;
}

@media (max-width: 767px) {
  #project .r-top .title-left .desc {
    font-size: 16px;
  }

  #project .r-top .title-left .desc br {
    display: none;
  }
}

#project .r-top .search {
  position: relative;
  text-align: right;
}

#project .r-top .search input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 29px;
  width: 445px;
  height: 56px;
  padding: 0 23px;
  max-width: 100%;
  font-size: 16px;
  color: #434343;
}

#project .r-top .search input:focus {
  outline: none;
}

#project .r-top .search input::-webkit-input-placeholder {
  color: inherit;
}

#project .r-top .search input::-moz-placeholder {
  color: inherit;
}

#project .r-top .search input:-ms-input-placeholder {
  color: inherit;
}

#project .r-top .search input::-ms-input-placeholder {
  color: inherit;
}

#project .r-top .search input::placeholder {
  color: #a7a7a7;
}

#project .r-top .search button {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  width: 49px;
  height: 56px;
  z-index: 1;
}

#project .r-top .search button:hover path {
  stroke: #0063aa;
}

#project .r-tag {
  margin-top: 32px;
}

#project .r-tag h3 {
  font-size: 24px;
  font-weight: 700;
  color: #121212;
  margin-bottom: 16px;
}

#project .r-tag ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-right: 74px;
}

#project .r-tag ul li {
  margin-right: 8px;
  margin-bottom: 8px;
}

#project .r-tag ul li.active a,
#project .r-tag ul li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#project .r-tag ul li a {
  font-size: 14px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 5px 24px 3px;
  display: inline-block;
}

#project .r-project-list {
  margin-top: 40px;
  margin-bottom: 94px;
}

#project .r-project-list .col-12 {
  margin-bottom: 30px;
}

#project .r-project-list .image {
  border-radius: 18px;
  display: block;
  overflow: hidden;
  height: 350px;
}

#project .r-project-list .image:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#project .r-project-list .image img {
  height: 350px;
  object-fit: cover;
  width: 100%;
  border-radius: 18px;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
  border: 1px solid #f3f3f3;
}

#project .r-project-list .content {
  margin-top: 25px;
}

#project .r-project-list .content .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #121212;
  display: block;
  margin-bottom: 7px;
}

#project .r-project-list .content .title:hover {
  color: #0063aa;
}

#project .r-project-list .content .tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

#project .r-project-list .content .tag li {
  margin-right: 8px;
  margin-bottom: 8px;
}

#project .r-project-list .content .tag li.active a,
#project .r-project-list .content .tag li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#project .r-project-list .content .tag li a {
  font-size: 14px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 5px 24px 3px;
  display: inline-block;
}

#project .r-project-list .content .desc {
  font-size: 16px;
  color: #313131;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media (max-width: 767px) {
  #project .r-project-list .content .desc {
    font-size: 14px;
  }
}

#project .r-project-list .content .readmore {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #0063aa;
}

@media (max-width: 767px) {
  #project .r-project-list .content .readmore {
    font-size: 16px;
  }
}

#project .r-project-list .content .readmore:hover svg {
  margin-left: 28px;
}

#project .r-project-list .content .readmore svg {
  margin-left: 18px;
}

#project .r-project-list .see-more {
  display: inline-block;
  background: #0063aa;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  padding: 16px 33px;
  margin-top: 47px;
}

#project .r-project-list .see-more:hover {
  background: #06436f;
}

@media (max-width: 1199px) {
  #project .r-tag ul {
    padding-right: 0;
  }

  #project .r-top .title-left h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  #project .r-top .search input {
    width: 100%;
  }

  #project .r-tag .col-12 {
    margin-bottom: 30px;
  }

  #project .r-project-list {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  #project .r-project-list .see-more {
    margin-top: 10px;
  }
}

#news .r-top {
  margin-top: 54px;
}

#news .r-top .title-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#news .r-top .title-left h3 {
  font-size: 48px;
  font-weight: 900;
  color: #121212;
  position: relative;
  margin-bottom: 0;
}

#news .r-top .title-left .handleBtnClose {
  margin-left: 50px;
  margin-top: 12px;
}

#news .r-top .title-left .handleBtnClose a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0063aa;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 4px 24px;
  position: relative;
  pointer-events: none;
}

#news .r-top .title-left .handleBtnClose a:hover {
  background: #0063aa;
  color: #fff;
  transition: 0.4s all;
}

#news .r-top .title-left .handleBtnClose a::before {
  content: url('/assets/images/news/bi_x-circle-fill.svg');
  position: absolute;
  top: -4px;
  right: -11px;
  pointer-events: initial;
}

#news .r-top .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#news .r-top .title-left .handleBtnClose {
  position: relative;
}

#news .r-top .title-left .handleBtnClose::before {
  content: '';
  position: absolute;
  height: 25px;
  width: 1px;
  left: -25px;
  background: #a7a7a7;
  transform: translateY(-50%);
  top: 50%;
}

#news .r-top .title-left .category {
  position: relative;
  margin-left: 54px;
  padding-top: 10px;
}

#news .r-top .title-left .category::before {
  content: '';
  width: 1px;
  height: 24px;
  background: #a7a7a7;
  position: absolute;
  left: -25px;
  top: 9px;
}

#news .r-top .title-left .category li a {
  font-size: 14px;
  color: #0063aa;
}

#news .r-top .search {
  position: relative;
  text-align: right;
}

#news .r-top .search input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 29px;
  width: 445px;
  height: 56px;
  padding: 0 23px;
  padding-right: 40px !important;
  max-width: 100%;
  font-size: 16px;
  /* color: #868686; */
}

#news .r-top .search input:focus {
  outline: none;
}

#news .r-top .search input::-webkit-input-placeholder {
  color: inherit;
}

#news .r-top .search input::-moz-placeholder {
  color: inherit;
}

#news .r-top .search input:-ms-input-placeholder {
  color: inherit;
}

#news .r-top .search input::-ms-input-placeholder {
  color: inherit;
}

#news .r-top .search input::placeholder {
  color: inherit;
}

#news .r-top .search button {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  width: 49px;
  height: 56px;
  z-index: 1;
}

#news .r-top .search button:hover path {
  stroke: #0063aa;
}

#news .r-news-list {
  margin: 42px 0 61px;
  position: relative;
}

#news .r-news-list::before {
  content: url('/assets/images/news/news-list-bg-1.png');
  position: absolute;
  bottom: -70px;
  left: -9px;
  z-index: -1;
}

#news .r-news-list::after {
  content: url('/assets/images/news/news-list-bg-2.png');
  position: absolute;
  top: -118px;
  right: -6px;
  z-index: -1;
}

#news .r-news-list .news-item {
  margin-bottom: 40px;
}

#news .r-news-list .news-item .image {
  height: 263px;
  border-radius: 15px;
  overflow: hidden;
}

#news .r-news-list #not-found .inner {
  padding: 0;
}

#news .r-news-list #not-found .image img {
  max-width: 200px;
  object-fit: cover;
  width: 100% !important;
}

#news .r-news-list .image img #news .r-news-list .content {
  padding-left: 6px;
  margin-top: -3px;
}

#news .r-news-list .content .category {
  font-size: 16px;
  color: #0063aa;
}

#home .r-news .news-item .content .title,
#news .r-news-list .content .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #121212;
  display: block;
  margin-bottom: 0;
}

#news .r-news-list .content .title:hover {
  color: #0063aa;
}

#news .r-news-list .content .date {
  margin-bottom: 12px;
  color: #a7a7a7;
  font-size: 14px;
}

#news .r-news-list .content .tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

#news .r-news-list .content .tag li {
  margin-right: 8px;
  margin-bottom: 8px;
}

#news .r-news-list .content .tag li.active a,
#news .r-news-list .content .tag li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#news .r-news-list .content .tag li a {
  font-size: 14px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 5px 24px 3px;
  display: inline-block;
}

#news .r-news-list .content .desc {
  font-size: 16px;
  color: #313131;
  padding-right: 140px;
  margin-bottom: 48px;
}

#news .r-news-list .see-more {
  display: inline-block;
  background: #0063aa;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  padding: 16px 33px;
  margin-top: 47px;
}

#news .r-news-list .see-more:hover {
  background: #06436f;
}

@media (max-width: 1199px) {

  #news .r-news-list::before,
  #news .r-news-list::after {
    display: none;
  }

  #news .r-news-list .content .desc {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  #news .r-news-list .content .desc {
    margin-bottom: 16px;
  }

  #news .r-top .title-left h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  #news .r-news-list .news-item .image {
    height: 220px;
  }

  #news .r-news-list .image img {
    height: 220px;
  }

  #news .r-top .search input {
    width: 100%;
  }

  #news .r-top .search {
    margin-top: 30px;
  }

  #news .r-news-list .news-item .col-md-5 {
    margin-bottom: 25px;
  }

  #news .r-news-list .see-more {
    margin-top: 0;
  }

  #news .r-news-list .content .desc {
    font-size: 14px;
  }
}

#contact .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#contact .title-center h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
}

#contact .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#contact .title-center .desc {
  font-size: 20px;
  color: #686868;
}

#contact .r-contact-form {
  margin: 71px 0;
  position: relative;
}

#contact .r-contact-form::before {
  content: url('/assets/images/contact/contact-form-bg-1.png');
  position: absolute;
  bottom: -81px;
  left: -7px;
  z-index: -1;
}

#contact .r-contact-form::after {
  content: url('/assets/images/contact/contact-form-bg-2.png');
  position: absolute;
  top: 27px;
  right: -10px;
  z-index: -1;
}

#contact .r-contact-form .info h1 {
  font-size: 48px;
  font-weight: 900;
  color: #121212;
  position: relative;
  margin-bottom: 32px;
}

#contact .r-contact-form .info h1::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

.custom-mb-r-process {
  margin-bottom: 150px;
}

#contact .r-contact-form .info p {
  color: #434343;
  line-height: 20px;
  margin-bottom: 29px;
  font-size: 16px;
}

#contact .r-contact-form .info .custom-spam {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #434343;
}

#contact .r-contact-form .info .custom-spam-jp {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #434343;
}

#contact .r-contact-form .info .phone-jp .title-contact {
  margin-top: -20px;
  margin-bottom: 11px;
}

#contact .r-contact-form .info .phone-vn .title-contact {
  margin-top: -17px;
}

#contact .r-contact-form .info .email-vn .title-contact {
  /* margin-top: -11px; */
}

#contact .r-contact-form .info .custom-spam-jp:nth-child(2) {
  margin-bottom: 20px;
}

#contact .r-contact-form .info .block+.block {
  border-top: 1px solid #e2e2e2;
  margin-top: 21px;
  padding-top: 24px;
}

#contact .r-contact-form .info .block h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #121212;
  margin-bottom: 32px;
}

#contact .r-contact-form .info .block h3 svg {
  margin-left: 48px;
}

#contact .r-contact-form .info .block ul {
  /* width: 360px; */
  width: 85%;
  max-width: 100%;
}

#contact .r-contact-form .info .block ul.link-list {
  margin-top: -6px;
}

#contact .r-contact-form .info .block ul.link-list li {
  margin-bottom: 15px;
}

#contact .r-contact-form .info .block ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #434343;
  margin-bottom: 8px;
  letter-spacing: 0.01em;
}

#contact .r-contact-form .info .block .content {
  /* white-space: nowrap; */
  margin-top: 15px;
  font-size: 16px;
  margin-bottom: 32px;
}

#contact .r-contact-form .title-contact span {
  color: #0063aa;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  position: relative;
  bottom: 2px;
}

/* dung_start */
#contact .r-contact-form .title-contact span::after {
  content: '';
  background: #0063aa;
  position: absolute;
  width: 30px;
  height: 1px;
  bottom: -1px;
  left: 0;
  top: 21px;
}

/* dung_end */

.customMTt {
  margin-top: -14px;
}

#contact .r-contact-form .title-contact svg {
  margin-right: 16px;
}

#contact .r-contact-form .info .block ul li .icon {
  margin-right: 17px;
}

#contact .r-contact-form .info .social {
  margin-top: -5px;
}

#contact .r-contact-form .info .social h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #121212;
  margin-top: 42px;
  margin-bottom: 14px;
}

#web-development.Realtime.Solutions .r-big-banner .feature,
#web-development.Reseach .r-big-banner .feature,
#web-development .r-big-banner .feature {
  -webkit-columns: 2;
  max-width: 490px;
}

#web-development .r-big-banner .customColumn {
  -webkit-columns: auto;
  max-width: 490px;
}

#contact .r-contact-form .info .social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#contact .r-contact-form .info .social ul li {
  margin-right: 17px;
  margin-bottom: 17px;
}

#contact .r-contact-form .info .social ul li a {
  width: 33px;
  height: 33px;
  background: #a7a7a7;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#contact .r-contact-form .info .social ul li a:hover {
  background-color: #0063aa;
}

#contact .r-contact-form .form {
  background: #ffffff;
  -webkit-box-shadow: 0px 26px 56px rgba(189, 189, 189, 0.54);
  box-shadow: 0px 26px 56px rgba(189, 189, 189, 0.54);
  border-radius: 25px;
  padding: 43px 24px 29px;
  width: 445px;
  max-width: 100%;
  margin-left: auto;
}

#contact .r-contact-form .form .form-group {
  margin-bottom: 24px;
  position: relative;
}

#contact .r-contact-form .form .form-group label {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #121212;
  margin-bottom: 0;
  background: #fff;
  position: absolute;
  top: -11px;
  left: 11px;
  padding: 0 5px;
}

#contact .r-contact-form .form input,
#contact .r-contact-form .form select {
  font-size: 16px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  height: 44px;
  color: #a7a7a7;
  letter-spacing: -0.02em;
  padding: 0 15px;
}

#contact .r-contact-form .form input::-webkit-input-placeholder,
#contact .r-contact-form .form select::-webkit-input-placeholder {
  color: inherit;
}

#contact .r-contact-form .form input::-moz-placeholder,
#contact .r-contact-form .form select::-moz-placeholder {
  color: inherit;
}

#contact .r-contact-form .form input:-ms-input-placeholder,
#contact .r-contact-form .form select:-ms-input-placeholder {
  color: inherit;
}

#contact .r-contact-form .form input::-ms-input-placeholder,
#contact .r-contact-form .form select::-ms-input-placeholder {
  color: inherit;
}

#contact .r-contact-form .form input::placeholder,
#contact .r-contact-form .form select::placeholder {
  color: inherit;
}

#contact .r-contact-form .form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('/assets/images/icon/icon-down.png') no-repeat calc(100% - 7px) 11px;
}

#contact .r-contact-form .form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

#contact .r-contact-form .form textarea {
  font-size: 16px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  height: 140px;
  color: #a7a7a7;
  letter-spacing: -0.02em;
  padding: 15px;
}

#contact .r-contact-form .form textarea::-webkit-input-placeholder {
  color: inherit;
}

#contact .r-contact-form .form textarea::-moz-placeholder {
  color: inherit;
}

#contact .r-contact-form .form textarea:-ms-input-placeholder {
  color: inherit;
}

#contact .r-contact-form .form textarea::-ms-input-placeholder {
  color: inherit;
}

#contact .r-contact-form .form textarea::placeholder {
  color: inherit;
}

#contact .r-contact-form .form .custom-file {
  height: 44px;
}

#contact .r-contact-form .form .custom-file-label {
  text-align: right;
  height: 44px;
  border: 1px solid #e2e2e2;
}

#contact .r-contact-form .form .custom-file-label svg {
  margin-top: 5px;
}

#contact .r-contact-form .form .custom-file-label::after {
  content: attr(data-content);
  left: 0;
  right: auto;
  background: transparent;
  border: none;
  color: #a7a7a7;
  font-size: 16px;
  letter-spacing: -0.02em;
  padding: 0px 15px;
  line-height: 44px;
}

#contact .r-contact-form .form button {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  background-color: #0063aa;
  border-radius: 5px;
  padding: 16px 32px;
  border: none;
  margin-left: auto;
  margin-top: 24px;
}

#contact .r-contact-form .form button:hover {
  background-color: #06436f;
}

#contact .r-member {
  padding-top: 97px;
  padding-bottom: 277px;
  background: url('/assets/images/home/member-bg.png') no-repeat center;
}

#contact .r-member .label {
  position: absolute;
  left: calc(100% + 51px);
  top: calc(50% + 155px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#contact .r-member .row {
  margin-left: -8px;
  margin-right: -8px;
}

#contact .r-member .row .col-4 {
  padding-left: 8px;
  padding-right: 8px;
}

#contact .r-member .title-center {
  margin-bottom: 50px;
}

#contact .r-member .image-logo {
  margin-top: 0;
  margin-bottom: 52px;
}

#contact .r-member .box {
  border: 1px solid #e2e2e2;
  -webkit-box-shadow: 0px 11px 12px rgba(189, 189, 189, 0.25);
  box-shadow: 0px 11px 12px rgba(189, 189, 189, 0.25);
  border-radius: 15px;
  height: 100%;
  padding: 10px 15px;
  background-color: #fff;
}

#contact .r-member .box .logo {
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 19px;
}

#contact .r-member .box .logo img {
  width: auto;
}

#contact .r-member .box ul li {
  font-size: 16px;
  color: #656565;
  position: relative;
  padding-left: 13px;
  line-height: 20px;
}

#contact .r-member .box ul li::before {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #656565;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 8px;
}

@media (min-width: 1200px) {
  #contact .r-member .row .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (max-width: 1199px) {

  #contact .title-left h3,
  #contact .title-center h3 {
    font-size: 30px;
  }

  #contact #footer .main {
    padding-top: 0;
  }

  #contact .title-left .desc,
  #contact .title-center .desc {
    font-size: 14px;
  }

  #contact .r-contact-form::before,
  #contact .r-contact-form::after {
    display: none;
  }

  #contact .r-member .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  #contact .r-member .row .col-4 {
    margin-bottom: 16px;
  }

  #contact .r-member {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  #contact .r-contact-form .info h1 {
    font-size: 30px;
  }

  #contact .r-contact-form .info .block h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  #contact .r-contact-form {
    margin: 40px 0;
  }

  #contact .r-contact-form .form input,
  #contact .r-contact-form .form select,
  #contact .r-contact-form .form textarea,
  #contact .r-contact-form .form .custom-file-label::after {
    font-size: 14px;
  }

  #contact .r-contact-form .form button {
    font-size: 14px;
    padding: 10px 27px;
  }

  #contact .r-contact-form .form {
    margin-top: 30px;
  }

  #contact .r-contact-form .info h1 {
    font-size: 20px;
  }

  #contact .r-contact-form .info .block h3 {
    font-size: 16px;
  }

  #contact .title-left h3,
  #contact .title-center h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  #contact .title-left .desc,
  #contact .title-center .desc {
    font-size: 14px;
    line-height: 24px;
    color: #434343;
    /* padding-right: 40px; */
  }

  #contact .title-left .desc br,
  #contact .title-center .desc br {
    display: none;
  }

  #contact .title-left h3::after {
    bottom: -4px;
    width: 14px;
    height: 1px;
  }

  #contact .r-member .title-center {
    margin-bottom: 31px;
  }

  #contact .r-member .title-center h3 {
    width: 205px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 26px;
    font-size: 18px;
  }

  #contact .r-member {
    padding-top: 23px;
    padding-bottom: 35px;
  }

  #contact .r-member .image-logo {
    margin-top: 0;
    margin-bottom: 22px;
  }

  #contact .r-member .image-logo img {
    width: 126.5px;
  }

  #contact .r-member .box .logo {
    height: 33px;
    margin-bottom: 7px;
  }

  #contact .r-member .box .logo.tsoft img {
    width: 59px;
  }

  #contact .r-member .box .logo.t-japan img {
    width: 73px;
  }

  #contact .r-member .box .logo.eyefire img {
    width: 77px;
  }

  #contact .r-member .box .logo.sky-live img {
    width: 43px;
  }

  #contact .r-member .box .logo.blockchain img {
    width: 75px;
  }

  #contact .r-member .box ul li {
    font-size: 8px;
    padding-left: 5px;
    line-height: 16px;
  }

  #contact .r-member .row {
    margin-left: -4px;
    margin-right: -4px;
  }

  #contact .r-member .row .col-4 {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 7px;
  }

  #contact .r-member .box ul li::before {
    width: 2px;
    height: 2px;
  }

  #contact .r-member .box {
    padding: 6px 7px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

#web-development .title-left {
  margin-bottom: 58px;
}

#web-development .title-left h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 35px;
}

#web-development .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#web-development .title-left .desc {
  font-size: 18px;
  color: #686868;
}

#web-development .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#web-development .title-center h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
  text-transform: uppercase;
}

#web-development .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#web-development .title-center .desc {
  font-size: 20px;
  color: #686868;
}

#web-development.Development .r-big-banner {
  padding: 117px 0 200px;
}

#web-development.Mobile .r-big-banner {
  padding: 117px 0 373px;
}

#web-development .r-big-banner .customColumn .box {
  display: flex;
  align-items: center;
}

#web-development .r-big-banner .customColumn {
  margin-top: 81px;
}

#web-development .r-big-banner .customColumn .box .icon {
  width: 36px;
  height: 36px;
  /* background: rgba(0, 99, 170, 0.05); */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#web-development .r-big-banner .customColumn .box+.box {
  margin-top: 39px;
}

#web-development .r-big-banner .customColumn .box p {
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  margin-bottom: 0;
  width: 234px;
  margin-left: 15px;
}

#web-development .r-big-banner {
  padding: 117px 0;
  background-image: url('/assets/images/service/bg-bigbaner.png');
  background-size: cover;
  margin-top: -75px;
  background-position: center;
  background-repeat: no-repeat;
}

#web-development .r-big-banner h1 {
  font-size: 48px;
  font-weight: 900;
  color: #121212;
  position: relative;
  margin-bottom: 37px;
}

#web-development .r-big-banner h1::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#web-development .r-big-banner .desc {
  color: #434343;
  line-height: 20px;
  margin-bottom: 27px;
  width: 448px;
  max-width: 100%;
  font-size: 16px;
}

#web-development .r-big-banner .feature {
  margin-top: 81px;
}

#web-development .r-big-banner .feature .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#web-development .r-big-banner .feature .box+.box {
  margin-top: 39px;
}

#web-development .r-big-banner .feature .box .icon {
  width: 36px;
  height: 36px;
  /* background: rgba(0, 99, 170, 0.05); */
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#web-development .r-big-banner .feature .box p {
  font-size: 14px;
  line-height: 20px;
  color: #121212;
  margin-bottom: 0;
  width: 234px;
  margin-left: 15px;
}

#web-development.Reseach .r-big-banner .feature .box p {
  min-height: 60px;
  display: flex;
  align-items: center;
}

#web-development.Reseach .r-big-banner {
  padding: 117px 0 230px;
}

#web-development .r-strengths {
  margin-top: 41px;
}

@media (max-width: 390px) {
  #web-development .r-strengths {
    margin-top: -40px;
  }
}

#web-development .r-strengths .title {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
  color: #0063aa;
  position: relative;
  padding-left: 22px;
  margin-top: 23px;
}

#web-development .r-strengths .title::before {
  content: '';
  width: 6px;
  height: calc(100% - 8px);
  position: absolute;
  left: 0;
  top: 2px;
  background-color: #0063aa;
}

#web-development .r-strengths .col-right .col-12 {
  margin-bottom: 21px;
}

#web-development .r-strengths .box {
  padding: 25px 26px;
  background: url('/assets/images/web-development/strength-box-bg.png') no-repeat center;
  background-size: cover;
  -webkit-box-shadow: 0px 8px 19px rgba(189, 189, 189, 0.25);
  box-shadow: 0px 8px 19px rgba(189, 189, 189, 0.25);
  border-radius: 15px;
  height: 100%;
  min-height: 177px;
}

#web-development .r-strengths .box p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
}

#web-development .r-technology {
  margin-top: 167px;
  position: relative;
}

@keyframes rightToLeft {
  0% {
    transform: translateX(77%);
  }

  100% {
    transform: translateX(-200%);
  }
}

@keyframes leftToRight {
  0% {
    transform: translateX(-110%);
  }

  100% {
    transform: translateX(200%);
  }
}

/* #web-development .r-technology::before {
  content: "frontend";
  font-weight: 900;
  font-size: 200px;
  line-height: 1;
  color: transparent;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: #E4E4E4;
  position: absolute;
  left: 10px;
  bottom: -110px;
  z-index: -1;
  text-transform: uppercase;
  -webkit-animation-name: leftToRight;
  animation-name: leftToRight;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
} */

/* #web-development .r-technology::after {
  content: "frontend";
  font-weight: 900;
  font-size: 200px;
  line-height: 1;
  color: transparent;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: #E4E4E4;
  position: absolute;
  right: -279px;
  top: 40px;
  z-index: -1;
  text-transform: uppercase;
  -webkit-animation-name: rightToLeft;
  animation-name: rightToLeft;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
} */

#web-development .r-technology .container-fluid {
  width: 1740px;
  max-width: 100%;
}

#web-development .r-technology .title {
  font-weight: 700;
  font-size: 48px;
  line-height: 56.25px;
  text-align: center;
  text-transform: uppercase;
  color: #0063aa;
  margin-bottom: 98px;
}

#web-development .r-technology ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

#web-development .r-technology ul li {
  padding: 20px 38px;
  margin-bottom: 27px;
}

#web-development .r-technology ul li .icon {
  height: 61px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#web-development .r-technology ul li .name {
  font-weight: 700;
  font-size: 18.75px;
  line-height: 20px;
  color: #000000;
  margin-top: 10px;
}

@media (max-width: 390px) {
  #web-development .r-technology ul li .name {
    font-size: 14px;
  }
}

#web-development .r-step {
  margin-top: 183px;
  padding: 79px 0 227px;
  position: relative;
}

#web-development .r-step::before {
  content: url('/assets/images/offshore/step-bg.png');
  position: absolute;
  bottom: -14px;
  left: -8px;
  z-index: 2;
}

#web-development .r-step .title-center {
  margin-bottom: 125px;
}

#web-development .r-step .title-center h3 {
  margin-bottom: 23px;
}

#web-development .r-step .title-center .desc {
  font-size: 18px;
  color: #494949;
}

#web-development .r-step .slick-slider {
  padding-right: 65px;
}

#web-development .r-step .slick-slider::before {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  width: 10000%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
}

#web-development .r-step .slick-slider .owl-stage-outer {
  overflow: visible;
}

#web-development .r-step .slick-slider .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#web-development .r-step .slick-slider .item {
  padding: 31px 15px 12px 24px;
  color: #fff;
  background: #0063aa;
  -webkit-box-shadow: 0px 4px 9px rgba(189, 189, 189, 0.45);
  box-shadow: 0px 4px 9px rgba(189, 189, 189, 0.45);
  border-radius: 10px;
  height: 100%;
}

#web-development .r-step .slick-slider .item .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 55px;
}

#web-development .r-step .slick-slider .item .top .number {
  font-size: 48px;
  font-weight: 900;
  line-height: 1;
}

#web-development .r-step .slick-slider .item .top .text {
  font-size: 48px;
  font-weight: 900;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  text-transform: uppercase;
  line-height: 1;
}

#web-development .r-step .slick-slider .item h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 14px;
}

#web-development .r-step .slick-slider .item p {
  font-size: 14px;
  line-height: 16px;
  color: #e2e2e2;
}

#web-development .r-fields {
  margin-top: 96px;
  margin-bottom: 97px;
  position: relative;
}

/* #web-development .r-fields::after {
  content: url("/assets/images/home/fields-img-2.png");
  position: absolute;
  right: 100px;
  top: -92px;
  z-index: 1;
} */

#web-development .r-fields .container::before {
  content: url('/assets/images/home/fields-img-4.png');
  position: absolute;
  right: calc(100% + 23px);
  top: 74px;
  z-index: 2;
}

#web-development .r-fields .col-left .title-left h3 {
  margin-bottom: 19px;
}

#web-development .r-fields .col-left .title-left .desc {
  margin-right: -1px;
  color: #676767;
  font-size: 20px;
}

#web-development .r-fields .col-left img {
  display: block;
  margin: -4px auto 0;
}

#web-development .r-fields .col-right {
  margin-top: 11px;
}

#web-development .r-fields .field-item {
  margin-bottom: 19px;
  padding-left: 62px;
}

#web-development .r-fields .field-item .icon {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#web-development .r-fields .field-item h3 {
  color: #646464;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  min-height: 40px;
}

@media (min-width: 1200px) {
  .r-strengths .col-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 38.35%;
    flex: 0 0 38.35%;
    max-width: 38.35%;
  }

  .r-strengths .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 61.65%;
    flex: 0 0 61.65%;
    max-width: 61.65%;
  }

  #web-development .r-fields .col-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 27%;
    flex: 0 0 27%;
    max-width: 27%;
  }

  #web-development .r-fields .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 73%;
    flex: 0 0 73%;
    max-width: 73%;
  }
}

@media (max-width: 1800px) {
  #web-development .r-step::before {
    display: none;
  }
}

@media (max-width: 1199px) {
  #web-development .r-big-banner {
    margin-top: 0;
  }

  #contact .r-contact-form .info .block .content {
    white-space: unset;
  }

  #web-development .r-fields::before,
  #web-development .r-fields::after {
    display: none;
  }

  #web-development .r-fields .field-item {
    padding-left: 0;
  }

  #web-development .r-fields .col-left img {
    display: none;
  }
}

@media (max-width: 991px) {
  #web-development .r-strengths .title {
    font-size: 30px;
    line-height: 38px;
  }

  #web-development .r-big-banner {
    padding: 64px 0 100px;
  }

  #web-development .r-technology {
    margin-top: 50px;
  }

  #web-development .r-technology ul li {
    padding: 10px 30px;
    margin-bottom: 20px;
  }

  #web-development .r-technology::before,
  #web-development .r-technology::after {
    display: none;
  }

  #web-development .r-technology .title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  #web-development .r-big-banner h1 {
    font-size: 30px;
  }

  #web-development .title-left h3,
  #web-development .title-center h3 {
    font-size: 30px;
  }

  #web-development .r-step .slick-slider .item .top .text,
  #web-development .r-step .slick-slider .item .top .number {
    font-size: 30px;
  }

  #web-development .r-step {
    padding-bottom: 70px;
    margin-top: 0;
  }

  #web-development .r-step .title-center {
    margin-bottom: 40px;
  }

  #web-development .r-fields {
    margin-top: 60px;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  #web-development .r-technology ul li {
    padding: 10px 18px;
    margin-bottom: 15px;
  }

  #web-development .r-technology .title {
    font-size: 18px;
  }

  #web-development .r-strengths .box {
    min-height: auto;
  }

  #web-development .r-strengths .title {
    font-size: 18px;
    line-height: 28px;
  }

  #web-development .r-big-banner {
    padding: 40px 0 40px;
  }

  #web-development .r-big-banner {
    background: transparent;
    background-image: none !important;
  }

  #web-development .r-big-banner .feature {
    margin-top: 35px;
  }

  #web-development.Development .r-big-banner {
    padding: 40px 0 40px;
  }

  #web-development .r-big-banner h1 {
    font-size: 20px;
    color: #0063aa;
    margin-bottom: 21px;
  }

  #web-development .r-fields {
    margin-top: 30px;
  }

  #web-development .r-fields .col-left .title-left h3 {
    margin-bottom: 13px;
  }

  #web-development .r-fields .col-left .title-left .desc {
    padding-right: 0;
    font-size: 14px;
  }

  #web-development .r-fields .field-item {
    margin-bottom: 3px;
  }

  #web-development .r-fields .field-item>svg {
    width: 79px;
    margin-top: -30px;
  }

  #web-development .r-fields .field-item>svg rect {
    height: 1.62px;
  }

  #web-development .r-fields .field-item .icon img {
    width: 40px !important;
  }

  #web-development .r-fields .field-item .icon svg {
    width: 40px;
  }

  #web-development .r-fields .field-item h3 {
    font-size: 14px;
  }

  #web-development .r-fields .title-left {
    margin-bottom: -8px;
  }

  #web-development .r-fields .read-more {
    margin-top: 3px;
    margin-right: -6px;
  }

  #web-development .title-left h3,
  #web-development .title-center h3 {
    font-size: 18px;
  }

  #web-development .title-left .desc,
  #web-development .title-center .desc {
    font-size: 14px;
  }

  #web-development .r-step .slick-slider {
    padding-right: 0;
  }

  #web-development .r-step {
    padding: 50px 0 227px;
  }

  #web-development .r-step .title-center {
    margin-bottom: 40px;
  }

  #web-development .r-step .title-center .desc {
    font-size: 14px;
  }

  #web-development .r-step .slick-slider .item h3 {
    font-size: 18px;
    line-height: 28px;
  }

  #web-development .r-step {
    padding-bottom: 40px;
  }

  #web-development .r-step .slick-slider .item .top {
    margin-bottom: 15px;
  }

  #web-development .r-step .slick-slider .item .top .text,
  #web-development .r-step .slick-slider .item .top .number {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  #web-development .r-technology ul li {
    width: 33.33333%;
  }
}

#solution .r-featured {
  padding: 71px 0;
}

#solution .r-featured h3 {
  font-size: 48px;
  font-weight: 900;
  color: #121212;
  position: relative;
  margin-bottom: 35px;
}

#solution .r-featured h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#solution .r-featured .desc {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: #606060;
}

#solution .r-featured .list {
  margin-top: 100px;
}

#solution .r-featured .list .row {
  margin-left: -17px;
  margin-right: -17px;
}

#solution .r-featured .list .row>div {
  padding-left: 17px;
  padding-right: 17px;
}

#solution .r-featured .list .col-6 {
  margin-bottom: 48px;
}

#solution .r-featured .list .icon {
  height: 94px;
}

#solution .r-featured .list .name {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #2f2f2f;
  margin-top: 9px;
}

#solution .r-solution-list {
  margin-top: 57px;
  padding-bottom: 186px;
  position: relative;
}

#solution .r-solution-list .circle-1 {
  position: absolute;
  left: -10px;
  top: 82px;
  z-index: -1;
}

#solution .r-solution-list .circle-2 {
  position: absolute;
  right: 19px;
  top: 948px;
  z-index: -1;
}

#solution .r-solution-list .circle-3 {
  position: absolute;
  left: -10px;
  top: 2263px;
  z-index: -1;
}

#solution .r-solution-list .circle-4 {
  position: absolute;
  right: -8px;
  top: 3560px;
  z-index: -1;
}

#solution .r-solution-list .circle-5 {
  position: absolute;
  left: -8px;
  bottom: -124px;
  z-index: -1;
}

#solution .r-solution-list ul li {
  position: relative;
  padding: 63px 0;
  background-size: cover;
}

#solution .r-solution-list ul li+li {
  margin-top: 95px;
}

#solution .r-solution-list ul li:nth-child(1) {
  background: url('/assets/images/solution/img1.jpg') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(1)::before {
  content: '01';
}

#solution .r-solution-list ul li:nth-child(2) {
  background: url('/assets/images/solution/img2_update.png') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(2)::before {
  content: '02';
}

#solution .r-solution-list ul li:nth-child(3) {
  background: url('/assets/images/solution/img3.jpg') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(3)::before {
  content: '03';
}

#solution .r-solution-list ul li:nth-child(4) {
  background: url('/assets/images/solution/img4.jpg') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(4)::before {
  content: '04';
}

#solution .r-solution-list ul li:nth-child(5) {
  background: url('/assets/images/solution/img5.jpg') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(5)::before {
  content: '05';
}

#solution .r-solution-list ul li:nth-child(6) {
  background: url('/assets/images/solution/img6.jpg') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(6)::before {
  content: '06';
}

#solution .r-solution-list ul li:nth-child(7) {
  background: url('/assets/images/solution/img7.jpg') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(7)::before {
  content: '07';
}

#solution .r-solution-list ul li:nth-child(8) {
  background: url('/assets/images/solution/img8.jpg') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(8)::before {
  content: '08';
}

#solution .r-solution-list ul li:nth-child(9) {
  background: url('/assets/images/solution/img9.jpg') no-repeat center;
}

#solution .r-solution-list ul li:nth-child(9)::before {
  content: '09';
}

#solution .r-solution-list ul li::before {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0063aa;
  font-weight: 900;
  font-size: 152.5px;
  line-height: 1;
  position: absolute;
}

#solution .r-solution-list ul li:nth-child(odd)::before {
  top: -92px;
  left: -66px;
}

#solution .r-solution-list ul li:nth-child(even)::before {
  top: -92px;
  right: -103px;
}

#solution .r-solution-list ul li:nth-child(even) .box {
  margin-left: auto;
  padding-left: 31px;
}

#solution .r-solution-list ul li:nth-child(even) .box .readmore {
  left: 31px;
}

#solution .r-solution-list ul li .box {
  background: rgba(255, 255, 255, 0.9);
  width: calc(50% - 3px);
  padding: 38px 17px 60px;
  position: relative;
  min-height: 400px;
}

#solution .r-solution-list ul li .box h5 {
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #0063aa;
  margin-bottom: 24px;
}

#solution .r-solution-list ul li .box p {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #525252;
}

#solution .r-solution-list ul li .box .readmore {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0063aa;
  position: absolute;
  bottom: 31px;
  left: 21px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#solution .r-solution-list ul li .box .readmore:hover svg {
  margin-left: 34px;
}

#solution .r-solution-list ul li .box .readmore svg {
  margin-left: 24px;
}

@media (max-width: 1199px) {
  #solution .r-solution-list .circle {
    display: none;
  }

  #solution .r-solution-list ul li:nth-child(odd)::before {
    top: -66px;
    left: 0;
  }

  #solution .r-solution-list ul li:nth-child(even)::before {
    top: -66px;
    right: 0;
  }
}

@media (max-width: 991px) {
  #solution .r-featured h3 {
    font-size: 30px;
  }

  #solution .r-solution-list ul li::before {
    font-size: 100.5px;
  }

  #solution .r-featured .desc {
    font-size: 14px;
    line-height: 24px;
  }

  #solution .r-featured .list .name {
    font-size: 14px;
    line-height: 24px;
  }

  #solution .r-solution-list ul li .box h5 {
    font-size: 30px;
    line-height: 34px;
  }

  #solution .r-solution-list ul li .box p {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 767px) {
  #solution .r-featured {
    padding: 30px 0;
  }

  #solution .r-featured .list {
    margin-top: 50px;
  }

  #solution .r-featured .list .icon {
    height: 60px;
  }

  #solution .r-featured .list .icon img {
    width: 50px;
  }

  #solution .r-featured .list .col-6 {
    margin-bottom: 20px;
  }

  #solution .r-solution-list ul li .box {
    width: calc(100% - 20px);
    padding: 38px 17px 60px;
    min-height: 350px;
  }

  #solution .r-solution-list ul li .box h5 {
    font-size: 18px;
    line-height: 28px;
  }

  #solution .r-solution-list ul li .box .readmore {
    font-size: 14px;
  }

  #solution .r-solution-list ul li {
    padding: 35px 0;
  }

  #solution .r-solution-list ul li:nth-child(odd)::before {
    top: -23px;
    left: 0;
  }

  #solution .r-solution-list ul li:nth-child(even)::before {
    top: -23px;
    right: 0;
  }

  #solution .r-solution-list ul li::before {
    font-size: 50.5px;
  }

  #solution .r-solution-list {
    margin-top: 30px;
    padding-bottom: 50px;
  }

  #solution .r-solution-list ul li+li {
    margin-top: 50px;
  }
}

#service .sidebar {
  position: fixed;
  width: calc((100% - 1110px) / 2);
  top: 0;
  left: 0;
  z-index: 10;
  padding: 290px 20px 20px 122px;
}

#service .sidebar.off-fixed {
  opacity: 0;
  visibility: hidden;
}

#service .sidebar ul {
  position: relative;
}

#service .sidebar ul::before {
  content: '';
  width: 1px;
  height: 100%;
  background-color: #e3e3e3;
  position: absolute;
  left: -13px;
  top: 0;
  z-index: 1;
}

#service .sidebar ul li {
  margin-bottom: 11px;
  position: relative;
}

#service .sidebar ul li::before {
  content: '';
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  background-color: #0063aa;
  position: absolute;
  left: -16px;
  top: 5px;
  opacity: 0;
  z-index: 2;
}

#service .sidebar ul li.active::before {
  opacity: 1;
}

#service .sidebar ul li.active a {
  font-size: 18px;
  font-weight: 700;
  color: #0063aa;
}

#service .sidebar ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d0d0d0;
  display: inline-block;
}

@media (min-width: 768px) {
  #service .sidebar ul li a:hover {
    color: #0063aa;
  }
}

#service .main-content {
  margin-bottom: 44px;
  overflow: hidden;
}

#service .page-title {
  font-size: 48px;
  font-weight: 900;
  line-height: 56px;
  color: #121212;
  position: relative;
  margin-bottom: 38px;
  margin-top: 71px;
}

#service .page-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -21px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#service .r-service-box {
  overflow: hidden;
}

#service .r-service-box .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 260px;
  position: relative;
}

#service .r-service-box .box::before {
  content: '';
  height: calc(100% - 0px);
  width: 1px;
  background-color: #0063aa;
  position: absolute;
  top: 60px;
  left: 33px;
  z-index: -1;
}

#service .r-service-box .box .col-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
  width: calc(100% - 570px);
  padding-top: 47px;
}

#service .r-service-box .box .col-info .icon {
  margin-right: 24px;
  margin-top: 13px;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #0063aa;
  min-width: 68px;
  border-radius: 50%;
  background-color: #fff;
}

#service .r-service-box .box .col-info .content h3 {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  color: #0063aa;
  position: relative;
  padding-left: 23px;
  min-height: 94px;
  max-width: 276px;
  display: flex;
  align-items: center;
}

#service .r-service-box .box .col-info .content h3::before {
  content: '';
  width: 7px;
  height: 77px;
  background-color: #0063aa;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#service .r-service-box .box .col-info .content p {
  font-size: 24px;
  line-height: 32px;
  color: #0063aa;
  padding-left: 28px;
}

#service .r-service-box .box .col-info .content .readmore {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #0063aa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-left: 30px;
  margin-top: 25px;
}

#service .r-service-box .box .col-info .content .readmore svg {
  margin-left: 19px;
}

#service .r-service-box .box .col-image {
  width: 540px;
  cursor: pointer;
}

#service .r-service-box .box .col-image img {
  width: 100%;
}

@media (max-width: 1800px) {
  #service .sidebar {
    padding-left: 50px;
  }
}

/* @media (max-width: 1440px) {
  #service .sidebar {
    display: none;
  }
} */

@media (max-width: 1199px) {
  #service .r-service-box .box .col-image {
    width: 440px;
  }

  #service .r-service-box .box .col-info {
    padding-top: 0;
    width: calc(100% - 470px);
  }

  #service .r-service-box .box {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  #service .r-service-box .box .col-info .content p {
    font-size: 16px;
    line-height: 24px;
  }

  #service .r-service-box .box .col-info .content h3 {
    font-size: 28px;
  }

  #service .page-title {
    font-size: 30px;
  }

  #service .page-title br {
    display: none;
  }

  #service .r-service-box .box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  #service .r-service-box .box .col-info {
    width: 100%;
  }

  #service .r-service-box .box .col-image {
    width: 100%;
    padding-left: 120px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  #home .main-content {
    overflow-x: hidden;
  }

  #service .page-title {
    font-size: 18px;
    line-height: 28px;
    margin-top: 40px;
  }

  #service .page-title::after {
    bottom: -6px;
    width: 27px;
    height: 1px;
  }

  #service .r-service-box .box .col-info .content h3 {
    font-size: 18px;
    line-height: 28px;
    padding-left: 15px;
  }

  #service .r-service-box .box .col-info .content h3::before {
    width: 3px;
    height: 50px;
  }

  #service .r-service-box .box .col-info .icon {
    margin-right: 15px;
    margin-top: 15px;
  }

  #service .r-service-box .box .col-info .content p {
    font-size: 14px;
    padding-left: 15px;
  }

  #service .r-service-box .box .col-info .content .readmore {
    font-size: 14px;
    line-height: 24px;
    margin-left: 15px;
    margin-top: 0;
  }

  #service .r-service-box .box .col-image {
    padding-left: 100px;
  }

  #service .r-service-box .box {
    padding-bottom: 50px;
  }
}

#news-details .main-content .page-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #474747;
  margin-bottom: 15px;
  margin-top: 28px;
}

#news-details .post figcaption {
  max-width: 666px !important;
}

#news-details .post img {
  width: 100%;
  max-width: 666px !important;
}

#news-details .post figure {
  display: table;
  margin: 1rem auto;
}

#news-details .post figure figcaption {
  font-style: italic;
  color: #666;
  text-align: center;
  margin: 8px 0 0;
  padding: 0 30px;
  font-size: 14px;
  line-height: 22px;
}

#news-details .handleBtnBackNews {
  margin-top: 77px;
}

#news-details .handleBtnBackNews a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #3e3c3c;
}

#news-details .handleBtnBackNews a svg {
  margin-right: 15px;
}

#news-details .handleBtnBackNews a:hover svg {
  margin-left: -10px;
  transition: 0.4s all;
}

#news-details .main-content .meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 31px;
}

#news-details .main-content .meta .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
  color: #353535;
  margin-right: 29px;
  position: relative;
}

#news-details .main-content .meta .date::after {
  content: '';
  width: 2px;
  height: 22px;
  background-color: #525252;
  position: absolute;
  right: -15px;
  top: 10px;
}

#news-details .main-content .meta .date img {
  margin-right: 10px;
}

#news-details .main-content .meta .share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#news-details .main-content .meta .share h3 {
  font-size: 24px;
  font-weight: 400;
  color: #353535;
  margin-bottom: 0;
  margin-right: 14px;
}

#news-details .main-content .meta .share ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
}

#news-details .main-content .meta .share ul li {
  margin-right: 16px;
  margin-right: 23px;
}

#news-details .main-content .post p {
  font-size: 16px;
  line-height: 24px;
  color: #313131;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* #news-details .main-content .post .image {
    margin-top: 33px;
    margin-bottom: 34px;
} */

#news-details .main-content .post .author {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid #a4a4a4;
  padding-bottom: 27px;
  margin-bottom: 60px;
  margin-top: 44px;
}

#news-details .r-news-list {
  margin: 42px 0 179px;
  position: relative;
}

#news-details .r-news-list .news-item {
  margin-bottom: 40px;
}

#news-details .r-news-list .image {
  padding-right: 10px;
}

#news-details .r-news-list .image img {
  width: 100%;
  border-radius: 15px;
  height: 255px;
  object-fit: cover;
  max-height: 100%;
}

#news-details .r-news-list .content {
  padding-left: 6px;
  margin-top: -3px;
}

#news-details .r-news-list .content .category {
  /* font-size: 16px; */
  color: #0063aa;
}

#news-details .r-news-list .content .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #121212;
  display: block;
  margin-bottom: 0;
}

#news-details .r-news-list .content .title:hover {
  color: #0063aa;
}

#news-details .r-news-list .content .date {
  margin-bottom: 12px;
  /* font-size: 16px; */
}

#news-details .r-news-list .content .tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

#news-details .r-news-list .content .tag li {
  margin-right: 8px;
  margin-bottom: 8px;
}

#news-details .r-news-list .content .tag li.active a,
#news-details .r-news-list .content .tag li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#news-details .r-news-list .content .tag li a {
  font-size: 16px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 5px 24px 3px;
  display: inline-block;
}

#news-details .r-news-list .content .desc {
  font-size: 16px;
  color: #313131;
  padding-right: 140px;
  margin-bottom: 48px;
}

#news-details .r-news-list .see-more {
  display: inline-block;
  background: #0063aa;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  padding: 16px 33px;
  margin-top: 28px;
}

#news-details .r-news-list .see-more:hover {
  background: #06436f;
}

@media (max-width: 1199px) {
  #news-details .r-news-list .content .desc {
    padding-right: 0;
  }

  #news-details .main-content .page-title {
    font-size: 30px;
    margin-top: 50px;
  }

  #news-details .main-content .meta .date,
  #news-details .main-content .meta .share h3 {
    font-size: 18px;
  }

  #news-details .r-news-list::before,
  #news-details .r-news-list::after {
    display: none;
  }

  #news-details .r-news-list .content .desc {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  #news-details .r-news-list .content .desc {
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  #news-details .main-content .meta .date img {
    width: 25px;
  }

  #news-details .main-content .post p {
    font-size: 14px;
  }

  #news-details .main-content .meta .share ul li svg {
    width: 30px;
  }

  #news-details .main-content .meta .date {
    margin-right: 0;
    margin-bottom: 15px;
  }

  #news-details .main-content .meta .date::after {
    display: none;
  }

  #news-details .main-content .meta .date,
  #news-details .main-content .meta .share h3 {
    font-size: 14px;
  }

  #news-details .main-content .meta .share ul li {
    margin-right: 10px;
  }

  #news-details .main-content .meta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  #news-details .main-content .page-title {
    font-size: 18px;
    margin-top: 30px;
    line-height: 25px;
  }

  #news-details .r-news-list .news-item .col-md-5 {
    margin-bottom: 25px;
  }

  #news-details .r-news-list .see-more {
    margin-top: 0;
  }

  #news-details .r-news-list .content .desc {
    font-size: 14px;
  }

  #news-details .r-news-list {
    margin-bottom: 70px;
  }
}

#project-details .r-treasure-slider .owl-dots.disabled {
  content: '';
  width: 92%;
  height: 1px;
  background-color: #0063aa;
  position: absolute;
  left: 15px;
  bottom: 17px;
  z-index: 0;
}

.owl-nav>button {
  border: none;
}

.r-featured-project .slick-initialized .slick-slide {
  /* padding: 20px; */
  padding: 0px;
}

/* .r-recruit .slick-slider .slick-list {
  margin: 0 -60px;
  overflow: visible;
} */

.r-recruit .slick-slider .slick-slide {
  opacity: 0;
  /* visibility: hidden; */
}

.r-recruit .slick-slider .slick-slide.slick-active {
  opacity: 1;
  visibility: visible;
}

/* .r-recruit .job {
  padding: 0 40px;
} */

.r-recruit .slick-slider button::before {
  display: none;
}

.r-recruit .slick-prev {
  left: -80px;
  z-index: 10;
  position: absolute;
  top: calc(50% - 18px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.r-recruit .slick-next {
  right: -80px;
  z-index: 10;
  position: absolute;
  top: calc(50% - 18px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

@media screen and (max-width: 767px) {
  .r-recruit .slick-arrow {
    display: none !important;
  }
}

#not-found .main-content .inner {
  text-align: center;
  padding: 131px 0;
}

#not-found .main-content .inner .image {
  margin-bottom: 32px;
}

#not-found .main-content .inner h3 {
  font-size: 24px;
  font-weight: 400;
  color: #0063aa;
  margin-bottom: 60px;
}

#not-found .main-content .inner a {
  border-radius: 5px;
  color: #fff;
  background-color: #0063aa;
  padding: 16px 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 242px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

/* #home .r-big-banner .container-fluid > img {
  height: 100%;
} */

@media (max-width: 767px) {
  #home .r-big-banner .container-fluid>img {
    height: calc(100vh - 100px);
  }
}

#home .r-slider .slick-dots {
  display: none;
}

#home .r-slider .slick-slider .slick-list {
  overflow: visible;
}

#home .r-featured-project .owl-nav button {
  background-color: transparent;
}

#about .slick-slider.about.slick-initialized {
  margin-left: calc((100% - 1110px) / 2);
}

#about .r-feedback>div>div.feedback-carousel .slick-list {
  padding-top: 35px;
}

#offshore .r-step .slick-slider .slick-list .slick-track {
  display: flex;
}

#offshore .r-step .slick-slider .slick-list {
  margin: 0 -16px;
  overflow: visible;
}

#offshore .r-step .slick-slider .slick-slide {
  padding: 0 16px;
  height: auto;
}

#offshore .r-step .slick-slider .slick-slide>div {
  height: 100%;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}

.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '\2022';
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul.slick-dots li button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
}

#about .r-feedback .slick-dots li.slick-active button {
  background-color: #0063aa;
  width: 29px;
}

#nprogress .bar {
  z-index: 9999999 !important;
  height: 3px !important;
}

#offshore .r-step .slick-slider:before {
  height: 100%;
  top: 0;
}

#offshore .r-step .slick-slider:before {
  height: calc(100% + 20px);
  top: -10px;
}

#toast-container {
  z-index: 9999;
  top: 120px;
}

.slick-dots li button:before {
  display: none;
}

@media (max-width: 1440px) {

  #home .r-office-tcom .circle,
  #about .r-office-tcom .circle {
    display: none;
  }

  #home .r-office-tcom .col-right .tcom-image .slick-active,
  #about .r-office-tcom .col-right .tcom-image .slick-active {
    margin-right: -45px;
  }
}

@media (max-width: 767px) {
  #home .r-featured-news .container-custom .image img {
    max-height: 200px !important;
  }

  #about .r-feedback .slick-dots {
    bottom: 48px;
  }

  #not-found .main-content .inner .image img {
    width: 200px;
  }

  #not-found .main-content .inner h3 {
    font-size: 18px;
    margin-bottom: 40px;
  }

  #not-found .main-content .inner a {
    font-size: 12px;
    padding: 7.5px 17px;
  }

  #not-found .main-content .inner {
    padding: 70px 0;
  }
}

/** ant design modal**/

.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}

.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-wrap {
  z-index: 1000;
}

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

.ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;
}

.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}

.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}

.ant-modal-confirm .ant-modal-header {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}

.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-modal-confirm-body>.anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
  margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
  color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
  color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
  color: #52c41a;
}

.ant-modal-wrap-rtl {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn+.ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body>.anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn+.ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}

.ant-form-item-explain-error {
  color: red;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  z-index: 100;
}

/* #home #header.sticky,
#offshore #header.sticky {
  background: rgba(0, 0, 0, 0.8);
} */

.c-lightbox-video {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  visibility: visible;
  width: 100%;
  z-index: 10000;
}

.c-lightbox-video__transaction {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.c-lightbox-video__inner {
  background-color: #fff;
  -webkit-box-shadow: 0 0 1.5em rgb(0 0 0 / 35%);
  box-shadow: 0 0 1.5em rgb(0 0 0 / 35%);
  height: 570px;
  left: 50%;
  max-width: calc(100% - 30px);
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 1055px;
  z-index: 9999;
}

#home .r-featured-news .container-custom .image img {
  max-height: 400px;
  object-fit: cover;
  width: 100%;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

#web-development.Realtime.Solutions .r-big-banner .feature,
#web-development.Reseach .r-big-banner .feature {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  max-width: 490px;
}

#web-development.Realtime.Solutions .r-big-banner {
  padding: 117px 0 325px;
}

#web-development.Testing .r-big-banner,
#web-development.Design .r-big-banner,
#web-development.Video.Solutions .r-big-banner {
  padding: 117px 0;
}

#web-development.Blockchain .r-big-banner {
  padding: 117px 0 208px;
}

@media (max-width: 991px) {
  #project .r-project-list .image img {
    height: 235px;
  }

  #project .r-project-list .image {
    height: 235px;
  }

  #web-development.Testing .r-big-banner,
  #web-development.Design .r-big-banner,
  #web-development.Video.Solutions .r-big-banner {
    padding: 64px 0 100px;
  }
}

@media (max-width: 767px) {

  #web-development.Testing .r-big-banner,
  #web-development.Design .r-big-banner,
  #web-development.Video.Solutions .r-big-banner,
  #web-development.Realtime.Solutions .r-big-banner,
  #web-development.Reseach .r-big-banner,
  #web-development.Blockchain .r-big-banner {
    padding: 40px 0 40px;
  }

  #home .r-featured-news .container-custom .image {
    max-height: 200px;
  }

  #footer .main h3.title+p {
    font-size: 14px !important;
    padding-left: 26px !important;
    margin-bottom: 5px;
  }

  #offshore .r-step .slick-slider .slick-slide {
    padding: 0 5px;
  }

  #offshore .r-step .slick-slider .slick-list {
    margin: 0 -5px;
    margin-right: 40px;
  }

  #project .r-project-list .image img {
    height: 230px;
  }

  #project .r-project-list .image {
    height: 230px;
  }

  #project-details .r-treasure-slider .slick-track {
    margin: 0 -5px;
  }

  #project-details .r-treasure-slider .slick-slider {
    margin-right: 85px;
  }

  #project-details .r-treasure-slider .slick-slide {
    height: 165px;
    padding: 0 5px;
  }

  #project-details .r-treasure-slider .slick-slide.slick-active img {
    height: 165px;
  }

  #project-details .r-treasure-slider .slick-slide img {
    height: 135px;
  }

  #project-details .r-treasure-slider .owl-dots.disabled {
    left: 24px;
  }
}

@media (max-width: 575px) {

  #web-development.Realtime.Solutions .r-big-banner .feature,
  #web-development.Reseach .r-big-banner .feature {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}

#service .sidebar h3.title,
#service .sidebar .js-open-sidebar {
  display: none;
}

@media (max-width: 1800px) {
  #service .sidebar {
    padding-left: 50px;
    padding-top: 0;
    padding-bottom: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media (max-width: 1440px) {
  #service .sidebar {
    padding: 0;
    -webkit-transform: none;
    transform: none;
    width: auto;
  }

  #service .sidebar .js-open-sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    color: #0063aa;
    background: #ffffff;
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 16.4px 20px;
    width: 171px;
  }

  #service .sidebar .js-open-sidebar svg {
    margin-right: 9px;
  }

  #service .sidebar h3.title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  #service .sidebar .box.open {
    display: block;
  }

  #service .sidebar .box {
    display: none;
    position: absolute;
    left: 100%;
    background: #fff;
    width: 283px;
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 16px 39px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  #service .sidebar .box h3.title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #0063aa;
    display: block;
    margin-bottom: 27px;
  }
}

@media (max-width: 767px) {
  #service .sidebar .js-open-sidebar svg {
    margin-right: 0;
  }

  #service .sidebar .js-open-sidebar {
    font-size: 0;
    width: auto;
  }
}

#home .r-our-service .service-box .inner h3 {
  color: #ffffff;
}

#home .r-featured-project #counter {
  top: 35px;
  right: 35px;
}

#footer .main ul li span {
  /* display: contents; */
  position: relative;
}

#news .r-top .search input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 29px;
  width: 445px;
  height: 56px;
  padding: 0 23px;
  max-width: 100%;
  font-size: 16px;
  /* color: #868686; */
  color: #434343;
}

#contact .r-contact-form .form input,
#contact .r-contact-form .form select,
#contact .r-contact-form .form textarea {
  color: #121212;
}

#contact .r-contact-form .form input::placeholder,
#contact .r-contact-form .form select::placeholder,
#contact .r-contact-form .form textarea::placeholder {
  color: #a7a7a7;
}

.category,
.title,
.tag,
.see-more,
.url-hover {
  cursor: pointer;
}

.url-hover-detail-project {
  white-space: nowrap;
}

@media (min-width: 767.5px) {

  /* #news .r-top .search input:hover {
        background: rgb(231, 231, 231);
        border: 1px solid #bebebe;
    } */
  /* #project .r-top .search input:hover {
    background: rgb(231, 231, 231);
    border: 1px solid #bebebe;
  } */
  #customFile:hover,
  #contact .r-contact-form .form select:hover {
    cursor: pointer;
  }

  .form-group-title {
    display: none;
  }

  #contact .r-contact-form .form input:hover,
  #contact .r-contact-form .form select:hover,
  #contact .r-contact-form .form textarea:hover {
    background: rgb(231, 231, 231);
  }

  .url-hover:hover {
    text-decoration: underline;
  }
}

@media (min-width: 767.5px) {}

.content-box .content-box-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

#not-found .main-content .inner a:hover {
  background-color: #0099ff;
}

.container-fluid .container-fluid-background-right {
  position: absolute;
  right: -15%;
  top: 15%;
  z-index: -1;
}

.container-fluid .container-fluid-background-left {
  position: absolute;
  left: 2%;
  bottom: 10%;
  z-index: -1;
}

.content-news {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  font-size: 16px;
}

.form-control input ::placeholder {
  color: #434343;
}

#news .r-top .search input::placeholder {
  color: #a7a7a7;
}

.owl-prev svg,
.owl-next svg {
  width: 21px;
  height: 14px;
}

@media (max-width: 767px) {
  #project-details .r-treasure-slider .slick-slide.slick-active img {
    height: 165px;
    width: auto;
  }

  .owl-prev svg,
  .owl-next svg {
    width: 21px;
    height: 14px;
  }
}

.js-open-sidebar,
.js-close-canvas,
.js-open-canvas,
.facebook,
.linkedin,
.message {
  cursor: pointer;
}

#project-details .content .url-hover {
  line-height: 35px;
}

#home .slick-slide .col-left img {
  height: 72px;
  border-radius: 13px;
}

#home .r-featured-project .image img {
  border-radius: 13px;
}

#news-details .post>figure {
  overflow: visible !important;
}

#news-details .post>figure figcaption {
  top: calc(100% + 5px) !important;
  z-index: 100;
  left: 0;
}

#news-details .post>figure+p {
  margin-top: 80px !important;
}

.r-process {
  margin-top: 169px;
  padding-bottom: 102px;
  background: #0063aa;
}

@media (max-width: 390px) {
  .r-process {
    margin-top: 70px;
  }
}

.r-process .col-left {
  padding-top: 91px;
  position: relative;
  height: calc(100% + 102px);
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-left {
    padding-top: 20px;
  }
}

.r-process .col-left h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-process .col-left h3 {
    font-size: 30px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-left h3 {
    font-size: 18px;
  }
}

.r-process .col-left h3::after {
  content: '';
  width: 48px;
  height: 4px;
  background: #ffffff;
  position: absolute;
  bottom: -19px;
  left: 0;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-left h3::after {
    width: 35px;
    bottom: 2px;
  }
}

.r-process .col-left p {
  padding-top: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.r-process .col-left .subtract-2 {
  position: absolute;
  top: 0;
  left: -327px;
}

@media screen and (min-width: 200px) and (max-width: 1200px) {
  .r-process .col-left .subtract-2 {
    display: none;
  }
}

.r-process .col-left .subtract {
  position: absolute;
  bottom: 0;
  left: -390px;
  /* top: 325px; */
}

.customWidthSubtract img {
  width: 85%;
}

@media screen and (min-width: 200px) and (max-width: 1200px) {
  .r-process .col-left .subtract {
    display: none;
  }
}

.r-process .col-right {
  padding-left: 45px;
  position: relative;
  padding-bottom: 1px;
  margin-bottom: -28px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right {
    padding-left: 15px;
  }
}

.r-process .col-right ul {
  padding-top: 70px;
}

.r-process .col-right ul::before {
  content: '';
  width: 1px;
  height: calc(100% + 73px);
  position: absolute;
  left: 76px;
  z-index: 1;
  top: 0;
  background: #fff;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul::before {
    left: 48px;
  }
}

.r-process .col-right ul li {
  color: #fff;
  cursor: pointer;
  display: flex;
  margin-bottom: calc(69px + 1rem);
  position: relative;
  transition: 0.5s;
}

.r-process .col-right ul li:last-of-type {
  margin-bottom: 0;
}

.r-process .col-right ul li::before {
  content: '';
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1400px 1991.68px 1991.68px 1400px;
  opacity: 0;
  width: 648px;
  height: 125px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-process .col-right ul li::before {
    width: 550px;
  }
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  .r-process .col-right ul li::before {
    width: calc(100% + 10px);
    height: 90px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li::before {
    width: calc(100% + 24px);
  }
}

@media (min-width: 768px) {
  .r-process .col-right ul li:hover::before {
    opacity: 1;
  }

  .r-process .col-right ul li:hover .icon {
    transform: scale(1.9);
    margin-top: 0;
  }
}

/* @media screen and (min-width: 200px) and (max-width: 390px) {
  .r-process .col-right ul li:hover {
    opacity: 0;
  }
  .r-process .col-right ul li:hover::before {
    opacity: 0;
  }
} */

@media screen and (min-width: 768px) and (max-width: 991px) {
  .r-process .col-right ul li:hover .icon {
    transform: scale(1.3);
  }
}

@media (min-width: 768px) {
  .r-process .col-right ul li:hover .content {
    padding-left: 50px;
  }
}

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li:hover .content {
    padding-left: 19px;
  }
} */

@media (min-width: 768px) {
  .r-process .col-right ul li:hover .content p {
    padding-right: 52px;
    font-size: 87px;
    transition: 0.4s;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .r-process .col-right ul li:hover .content p {
    font-size: 70px;
  }
}

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li:hover .content p {
    font-size: 40px;
    padding-right: 32px;
  }
} */

/* @media screen and (min-width: 200px) and (max-width: 390px) {
  .r-process .col-right ul li:hover .content p {
    font-size: 35px;
    padding-right: 21px;
  }
} */

@media (min-width: 768px) {
  .r-process .col-right ul li:hover .content .text strong {
    transition: 0.5s;
    font-size: 32px;
    margin-bottom: 7px;
    line-height: 25px;
    white-space: nowrap;
  }
}

/* @media screen and (min-width: 200px) and (max-width: 767px) {
    .r-process .col-right ul li:hover .content .text strong {
        font-size: 16px;
        line-height: 20px;
    }
} */

@media (min-width: 768px) {
  .r-process .col-right ul li:hover .content .text span {
    transition: 0.5s;
    bottom: -4px;
    font-size: 16px;
    line-height: 19px;
  }
}

/* .r-process .col-right ul li:hover .content p::before {
    top: 1px;
} */

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li:hover .content .text span {
    font-size: 10px;
  }
  .r-process .col-right ul li:hover .content .text span {
    bottom: 5px;
  }
} */

.r-process .col-right ul li::after {
  content: '';
  width: 3px;
  height: 125px;
  background-color: #fff;
  position: absolute;
  left: -63px;
  opacity: 0;
  transition: 0.5s;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  .r-process .col-right ul li::after {
    height: 70px;
  }
}

.r-process .col-right ul li:hover::after {
  opacity: 1;
}

.r-process .col-right ul li .icon {
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: 0.5s;
  display: flex;
}

.r-process .col-right ul li .content {
  padding-left: 30px;
  display: flex;
  transition: 0.5s;
  margin-top: 1px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content {
    padding-left: 17px;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  .r-process .col-right ul li .content {
    padding-left: 5px;
  }
}

.r-process .col-right ul li .content p {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  padding-right: 39px;
  position: relative;
  transition: 0.5s;
  margin-bottom: 0;
  margin-top: 4px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content p {
    font-size: 30px;
    padding-right: 14px;
  }
}

.r-process .col-right ul li .content p::before {
  /* content: "";
    width: 3px;
    position: absolute;
    right: 11px;
    top: -3px;
    height: 65px;
    border-radius: 7px;
    background: #fff; */
  background: #fff;
  border-radius: 7px;
  content: '';
  height: 65px;
  position: absolute;
  right: 11px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 3px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content p::before {
    height: 60px;
    /* top: 0px; */
    right: 7px;
  }
}

.r-process .col-right ul li .content .text strong {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  transition: 0.5s;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content .text strong {
    font-size: 16px;
    line-height: 18px;
  }
}

.r-process .col-right ul li .content .text span {
  position: absolute;
  bottom: 1px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  transition: 0.5s;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content .text span {
    font-size: 13px;
  }

  .r-process .col-right ul li .content .text span {
    position: absolute;
    font-weight: 400;
    line-height: 14px;
    transition: 0.5s;
    bottom: -2px;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  /* .r-process .col-right ul li .content .text span {
        bottom: 13px;
    } */
}

/* duy 10/8 sua padding luong, nut slide trai phai, va hien thi cms ra man hinh */

#home #header .header-desktop {
  /* background-color: #000; */
}

@media (max-width: 767.5px) {
  .salary-mobile {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  /* #footer .main .customMarginBottom span::after {
        left: 34px;
    } */
}

pre {
  display: block;
  font-family: 'Roboto';
  white-space: break-spaces;
  margin: 1em 0;
}

#home .r-featured-project .owl-nav {
  top: 48px;
}

#home .r-featured-project #counter {
  top: 60px;
}

/* update ui 14/9 */

#news .r-featured-news {
  margin-top: 77px;
}

#news .r-featured-news .title h3 {
  font-size: 48px;
  font-weight: 900;
  color: #121212;
  position: relative;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  #news .r-featured-news .title h3 {
    font-size: 30px;
  }
}

#news .r-featured-news .title h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#news .r-featured-news .title p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #686868;
  margin-top: 28px;
}

@media (max-width: 991px) {
  #news .r-featured-news .title p {
    font-size: 16px;
  }
}

#news .r-featured-news .content {
  margin-top: 30px;
}

#news .r-featured-news .content img {
  border-radius: 15px;
  width: 100%;
  object-fit: cover;
  height: 100%;
  min-height: 230px;
  max-height: 730px;
  max-height: 482.54px;
}

@media (min-width: 776.5px) {
  #news .r-featured-news .content .col-left {
    position: relative;
    max-height: 476px;
  }
}

@media (max-width: 776.5px) {
  #news .r-featured-news .content .col-left {
    position: relative;
    max-height: 230px;
    max-width: 440px;
  }

  #news .r-featured-news .content .col-right .custom-padding {
    max-height: 230px;
    max-width: 440px;
  }
}

@media (max-width: 767px) {
  #news .r-featured-news .content .col-left {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 7px;
  }
}

#news .r-featured-news .content .col-left::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.85) 100%);
  border-radius: 0px 0px 15px 15px;
  width: calc(100% - 14px);
  height: 297px;
}

@media (max-width: 767px) {
  #news .r-featured-news .content .col-left::after {
    width: 100%;
    height: 192.29px;
  }
}

#news .r-featured-news .content .col-right .custom-padding {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 7px;
  position: relative;
}

#news .r-featured-news .content .col-right .custom-padding img {
  max-height: 236.84px;
  max-width: 372px;
}

#news .r-featured-news .content .col-right .custom-padding::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.85) 100%);
  border-radius: 0px 0px 15px 15px;
  width: calc(100%);
  height: 192.29px;
}

@media (min-width: 768px) and (max-width: 991px) {
  #news .r-featured-news .content .col-right .custom-padding::after {
    height: 152px;
  }
}

#news .r-featured-news .content .col-right .custom-padding:last-of-type {
  margin-bottom: 0;
}

#news .r-featured-news .content .desc-img {
  position: absolute;
  bottom: 30px;
  left: 42px;
  z-index: 10;
  right: 42px;
  color: #ffffff;
}

#news .r-featured-news .content .col-left .desc-img {
  font-weight: 900;
  font-size: 28px;
  line-height: 36px;
}

@media (max-width: 767px) {
  #news .r-featured-news .content .col-left .desc-img {
    font-size: 18px;
    line-height: 21px;
    left: 33px;
    right: 33px;
    bottom: 16px;
  }
}

#news .r-featured-news .content .col-right .desc-img {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  left: 33px;
  right: 33px;
  bottom: 16px;
}

#home .r-our-service .service-box .hover-content ul {
  padding: 33px 5px;
}

#home .r-our-service .service-box .hover-content h3 {
  padding: 10px 20px 10px;
}

#home .r-our-service .service-box .hover-content ul li {
  padding: 0 20px 0 30px;
}

#home .r-our-service .service-box .hover-content h3 {
  background-color: #0063aa;
  font-size: 30px;
  font-weight: 900;
  line-height: 40px;
  padding: 16px 20px 16px;
  margin-bottom: 0;
}

@media (max-width: 1199.5px) and (min-width: 991.5px) {
  #home .r-our-service .service-box .hover-content h3 {
    background-color: #0063aa;
    font-weight: 900;
    padding: 8px 20px 8px;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 30px;
  }

  #home .r-our-service .service-box .hover-content ul {
    padding: 20px 5px;
  }

  #home .r-our-service .service-box .hover-content ul li {
    font-size: 17px;
    font-weight: 500;
    line-height: 35px;
    padding: 0 10px 0 30px;
    position: relative;
  }
}

@media (max-width: 991.5px) and (min-width: 776.5px) {
  #home .r-our-service .service-box .hover-content h3 {
    background-color: #0063aa;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
    padding: 10px 20px 10px;
    margin-bottom: 0;
  }

  #home .r-our-service .service-box .hover-content ul {
    padding: 20px 5px;
  }

  #home .r-our-service .service-box .hover-content ul li {
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    padding: 0px 5px 0 30px;
    position: relative;
  }
}

@media (max-width: 776.5px) {
  #home .r-our-service .service-box .hover-content h3 {
    background-color: #0063aa;
    font-size: 15px;
    font-weight: 900;
    line-height: 20px;
    padding: 5px 20px 5px;
    margin-bottom: 0;
  }
}

@media (min-width: 1199.5px) {
  #home .r-solution .box .inner p {
    max-height: 100px;
    font-size: 16px;
    padding-right: 5px;
  }
}

@media (max-width: 1199.5px) and (min-width: 991.5px) {
  #home .r-solution .box .inner p {
    max-height: 50px;
    overflow-y: scroll;
    font-size: 16px;
    padding-right: 5px;
  }
}

/* @media (max-width:991.5px) {
    #home .r-solution .box .inner p {
        overflow-y: hidden;
    }
} */

/* #home .title-left .desc {
    font-size: 18px;
    color: #434343;
} */

#home .r-fields .field-item h3 {
  color: #434343;
}

#home .r-recruit .job ul li .name:first-child {
  font-weight: 400;
}

#home .r-news .news-item .content .date {
  margin-top: 8px;
  font-size: 16px;
  color: #353535;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

/* .r-contact .contact-box {
    margin-bottom: 70px;
    margin-top: -90px;
} */

#footer .main .customMarginBottom span::after {
  bottom: 2px;
}

/* #footer .main .customTokyo span::after {
    bottom: 10px;
} */

@media screen and (min-width: 200px) and (max-width: 1199px) {
  /* #footer .main .customTokyo span::after {
        left: 36px;
    } */
}

@media (max-width: 767.5px) {
  /* #footer .main .customMarginBottom span::after,
    #footer .main .customTokyo span::after {
        left: 27px;
    } */
}

#about .r-progress .block-progress .item ul li .content {
  width: 65%;
}

#service .r-service-box .box .col-info .content p {
  color: #454545;
}

#web-development .r-big-banner .feature .box {
  display: flex;
  align-items: center;
}

#news .r-news-list .content .category {
  font-size: 14px;
  color: #0063aa;
}

#home .r-news .news-item .content .title,
#news .r-news-list .content .title {
  font-size: 18px;
}

#news .r-news-list .content .tag li a {
  font-size: 16px;
}

#news .r-news-list .see-more {
  margin-bottom: 80px;
}

#home .r-solution .box .inner .image {
  margin-bottom: 6px;
}

@media (min-width: 1199.5px) {
  #home .r-solution .box>img {
    width: 100%;
    height: 311px;
  }
}

#project-details .r-treatsure .col-right .block .content p {
  margin-bottom: -11px;
}

#project-details .r-treatsure .col-right .inner {
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .r-contact .contact-box .btn-contact {
    margin-top: 20px;
  }
}

#news .r-news-list {
  /* margin: 42px 0 150px 61px; */
  position: relative;
}

/* #contact .r-member .box ul li {
    font-size: 16px;
    color: #656565;
    position: relative;
    padding-left: 13px;
    line-height: 20px;
} */

#footer .main .customMarginBottom svg {
  margin-right: 11px;
  margin-left: 3px;
}

@media (min-width: 1200px) {
  #footer .main .block-address {
    max-width: 365px;
    margin-top: 55px;
  }
}

/* #footer .main .customMT86 {
    margin-top: 96px !important;
} */

/*
#footer .main .customMarginTop {
    margin-top: 80px;
} */

#project .r-project-list .content .tag li a {
  font-size: 16px !important;
}

@media (max-width: 767px) {
  #project .r-project-list .content .tag li a {
    font-size: 14px !important;
  }
}

/* dung_fixx _start thêm font size */
#project-details .r-treatsure .col-right .block .content h5 {
  margin-bottom: 20px;
  color: #121212;
  font-weight: 600;
  font-size: 20px;
}

@media (max-width: 767px) {
  #project-details .r-treatsure .col-right .block .content h5 {
    font-size: 18px;
  }
}

/* end */

.custom-mb-r-process {
  margin-bottom: 150px;
}

.r-process {
  margin-bottom: 140px;
  margin-top: 169px;
  padding-bottom: 102px;
  background: #0063aa;
}

#project-details .r-treasure-slider .customBtn svg {
  min-width: 21px !important;
}

@media (max-width: 1199px) {
  /* #header.sticky + div {
    padding-top: 75px !important;
  } */
}

#project-details .customPadingURL {
  padding-bottom: 6px;
}

#project-details .customPadingURL:last-of-type {
  padding-bottom: 0;
}

@media (max-width: 767.5px) {
  #about .r-progress .slick-next {
    display: none !important;
  }

  #contact .r-contact-form .info .block h3 svg {
    margin-left: 15px;
  }
}

@media (min-width: 767.5px) and (max-width: 991.5px) {
  #home .read-more {
    top: 106px;
  }
}

html {
  scroll-behavior: smooth !important;
}

#home .r-news .news-item .content .readmore:hover {
  text-decoration: none;
}

/* .col-image{
    overflow: hidden;
} */
/* #service .r-service-box .box .col-image img:hover {
    width: 100%;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    object-fit: cover;
    transition: 0.4s all;
} */
#news-details .post img {
  display: block;
  margin-block-end: 1em;
  margin-block-start: 1em;
  margin-inline-end: 0;
  margin-inline-start: 0;
  width: 100%;
}

.img-new {
  cursor: pointer;
}

.webdetail {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.webdetail a {
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 767.5px) {
  .webdetail a {
    font-size: 9px;
  }
}

@media (min-width: 767.5px) {
  .webdetail {
    opacity: 0;
  }

  #home .r-member .box:hover>.webdetail {
    opacity: 1;
  }

  #home .r-member .box:hover {
    border: 1px solid #1198fb;
  }

  #contact .r-member .box:hover>.webdetail {
    opacity: 1;
  }

  #contact .r-member .box:hover {
    border: 1px solid #1198fb;
  }

  #about .r-member .box:hover>.webdetail {
    opacity: 1;
  }

  #about .r-member .box:hover {
    border: 1px solid #1198fb;
  }
}

#footer .main .logo:after {
  bottom: -24px;
  height: 2px;
  left: 0px;
  width: 257px;
}

@media (min-width: 1200px) {
  #home .r-big-banner .content {
    padding-left: 0px;
  }

  #footer .main h3.corporation {
    line-height: 32px;
    margin-left: 4%;
  }

  #footer .main .logo {
    margin-bottom: 40px;
  }
}

#home .r-big-banner .follow-link {
  top: calc(50% - 30px);
}


.hidden #header {
  opacity: 0;
}

.hidden #header.sticky {
  opacity: 1;
}

@media (max-width:1199px) {
  .update_contact_block_all {
    margin-bottom: 50px !important;
  }

  /* #header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  } */
  #header+div {
    padding-top: 59px;
  }

  #home #header+div {
    padding-top: 0px;
  }
}


@media (min-width: 1199px) {


  /* #item_company {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  } */
  #system-company {
    justify-content: center;
  }
}

.update_contact_a {
  display: flex;
  align-items: center;
}

.update_contact_a>span {
  color: #F9F9F9;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-left: 12px;
}

.post iframe, .post img{
  max-width: 100%;
}
@media (max-width: 1199px){
  .post iframe{
    height: 50vw;
  }
}
.post p, .post p span{
  font-family: Roboto, sans-serif !important;
  color: #313131 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
@media (max-width: 767px){
  .post p, .post p span{
    font-size: 14px !important;
  }
}
#home .r-big-banner .content .main-content > div > img{
  max-width: 409px;
}
html[lang="en"] #home .r-big-banner .content .main-content > div > img{
  max-width: 280px;
}
html[lang="vi"] #home .r-big-banner .content .main-content > div > img.img-en{
  display: none;
}
html[lang="en"] #home .r-big-banner .content .main-content > div > img.img-vi{
  display: none;
}
